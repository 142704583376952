import React from 'react';
import { Collapse } from 'antd';
 // Import the Ant Design CSS


function Programs() {
    const { Panel } = Collapse;

    const panelData = [
        {
            key: '1',
            header: 'INNOVATIVE COMPETITIONS',
            content: 'National integration by strengthening social fabric amongst different sects within society with various activities, including promoting arts, games, sports and culture at the village level, is our prime motto. Engaging people through regular competitions and events in these areas will naturally bring out the indigenous talent and promote better human relations',
        },
        {
            key: '2',
            header: 'TRADITIONAL SPORTS & GAMES',
            content: 'National integration by strengthening social fabric amongst different sects within society with various activities, including promoting arts, games, sports and culture at the village level, is our prime motto. Engaging people through regular competitions and events in these areas will naturally bring out the indigenous talent and promote better human relations',
        },
        {
            key: '3',
            header: 'QUIZZES & FUN GAMES',
            content: 'National integration by strengthening social fabric amongst different sects within society with various activities, including promoting arts, games, sports and culture at the village level, is our prime motto. Engaging people through regular competitions and events in these areas will naturally bring out the indigenous talent and promote better human relations',
        },
        {
            key: '4',
            header: 'BRAND ACTIVATIONS',
            content: 'National integration by strengthening social fabric amongst different sects within society with various activities, including promoting arts, games, sports and culture at the village level, is our prime motto. Engaging people through regular competitions and events in these areas will naturally bring out the indigenous talent and promote better human relations',
        },
        {
            key: '5',
            header: 'IMW COMMUNITY CALENDAR',
            content: 'National integration by strengthening social fabric amongst different sects within society with various activities, including promoting arts, games, sports and culture at the village level, is our prime motto. Engaging people through regular competitions and events in these areas will naturally bring out the indigenous talent and promote better human relations',
        },
    ];

    return (
        <>         
            <section class="bg-body section-space-less30 innerpages">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <div class="innerpages_heading"><h1>Programs</h1></div>
                                <div class="position-relative mb-30 post_img_content"><img src="img/imw_img/programs_bg.png" alt="news" class="img-fluid" /></div>

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <h2>VILLAGE ENGAGEMENT SERVICES - THE CULTURAL SOCIETY :</h2>
                                <p>Culture plays a prominent role in building a bond among rural people. We conduct cultural activities to preserve and promote Great Indian Culture & Heritage.</p>

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-30">
                            <div class="news-details-layout1">
                                <h2>BOOK EVENTS :</h2>
                                <Collapse>
                                    {panelData.map((panel) => (
                                        <Panel header={panel.header} key={panel.key}>
                                            {panel.content}
                                        </Panel>
                                    ))}
                                </Collapse>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Programs
