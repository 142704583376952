import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import BaseUrl from "../utils/config";
const initialState = {
  data: [],
  status: "idle",
  error: "",
  livetvSelectedLang: {},
};

const LiveTvSlice = createSlice({
  name: "livetv",
  initialState,
  reducers: {
    selectedLivetvInterfaceAction: (state, action) => {
      state.livetvSelectedLang = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllLivetvData.pending, (state, action) => {
      state.data = [];
      state.status = "loading";
    });
    builder.addCase(getAllLivetvData.fulfilled, (state, action) => {
      state.data = action?.payload;
      state.status = "idle";
    });
    builder.addCase(getAllLivetvData.rejected, (state, action) => {
      state.data = [];
      state.status = "error";
    });
  },
});

export const getAllLivetvData = createAsyncThunk("livetv/get", async (payload) => {
  const data = await fetch(`${BaseUrl}/livetvManagement/web/live/listing`, {       //all 
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  const result = await data.json();
  return result;
});

export const { selectedLivetvInterfaceAction } = LiveTvSlice.actions;
export default LiveTvSlice.reducer;
