import React, { useEffect, useState } from 'react';
import Header from '../../../../src/Components/Header';
import Footer from '../../../../src/Components/Footer';
import { Link } from 'react-router-dom';
import { Card, Pagination } from 'antd';

import ReactWhatsapp from 'react-whatsapp';
import EmojiSelector from '../../Sections/Common/EmojiSelector';
import MorEellipsisIcon from '../../Sections/Common/MorEellipsisIcon';

import {    
    useGetAllTrendingMutation
} from '../../../Services/TrendingAPI';

function BusinessViewAll() {
    const [trendingData, setTrendingData] = useState([]);
   

    const [saveTrendingAPI] = useGetAllTrendingMutation();
    const getTrendingDataFunc = async () => {
        const payload = {
            news_feed_lang_ids: [
                60, 70, 80
            ]
        }
        if (payload) {
            try {
                await saveTrendingAPI(payload)
                    .unwrap()
                    .then((payload) => {
                        setTrendingData(payload)

                    })

            } catch (err) {
                console.log("Some thing went wrong");
            }
        }
    }
    console.log('TrendData', trendingData)
    useEffect(() => {
        getTrendingDataFunc()
    }, [])

    const newsData = trendingData?.data?.filter(record =>
        record.categories.some(category => category.name === 'Business')
    );
    console.log('newsData @@', newsData);



    return (       
            <>
                <section class="bg-body">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="politics foryou_page">
                                    <div class="heading_page">
                                        <h1>Business</h1>
                                    </div>
                                    <div class="breadcrumbs-area">
                                        <div class="breadcrumbs-content">
                                            <ul>
                                                <li><a href="/">Home </a>  /  </li>
                                                <li><a href="/"> Business</a> </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="bg-body">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-9 col-md-9 mt-20">
                                <div class="row">
                                    {newsData && newsData.map((record) => {
                                        const originalDate = new Date(record?.pubDate);
                                        const currentDate = new Date();
                                        const timeDifference = currentDate - originalDate;
                                        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
                                        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

                                        let displayTime;
                                        if (timeDifference < 24 * 60 * 60 * 1000) {
                                            // Less than 24 hours
                                            displayTime =
                                              hours > 0
                                                ? `${hours} hrs ago`
                                                : minutes > 0
                                                ? `${minutes} min ago`
                                                : "Less than a min ago";
                                        } else {
                                            // More than 24 hours
                                            displayTime = originalDate.toLocaleDateString('en-GB'); // Format the date as DD-MM-YYYY
                                        }
                                        return <>
                                            <div class="col-sm-4 col-12">
                                                <div class="position-relative mb-30 forpage_content">
                                                    <Link to={`/about?param1=${record?.id}`} class="img-opacity-hover">
                                                        {record?.image_urls?.[0] ? (
                                                            <img src={record?.image_urls?.[0]} alt="news" className="img-fluid width-100 mb-15 title_img" />
                                                        ) : (
                                                            <img src='../img/imw_img/no-image.png' alt="No-Image" className="img-fluid width-100 mb-15 title_img no-img" />
                                                        )}
                                                    </Link>

                                                    <Link to={`/about?param1=${record?.id}`} class="title-medium-dark size-lg mb-none">
                                                    {record?.title.replace(/<[^>]*>/g, "").replace(/&nbsp;|&emsp;/g, "").trim()}
                                                    </Link>

                                                    <p className='duration_slider'>{displayTime}</p>
                                                    <div className="post-date-light politics_post">
                                                        <ul>
                                                            <li className='user_data'>
                                                                <span><i className="fa fa-user"
                                                                    aria-hidden="true"></i></span>
                                                                <a href="/">{record?.author} </a>
                                                            </li>
                                                            <li className="float-right social_data">
                                                                <span className="social_icons">
                                                                    <EmojiSelector />
                                                                    <img src="img/imw_img/icon_headphones.png" alt="icon" className="icons_img" title='Coming Soon' />
                                                                    <img src="img/imw_img/icon_message.png" alt="icon" className="icons_img" title='Coming Soon' />
                                                                    <ReactWhatsapp number="1-212-736-5000" message="Hello" className="social_whatapp">
                                                                        <img src="img/imw_img/icon_whatsapp.png" alt="icon" className="icons_img" />
                                                                    </ReactWhatsapp>
                                                                    {/* <MorEellipsisIcon /> */}
                                                                </span>
                                                            </li>
                                                            <li>

                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    })}
                                </div>
                            </div>
                            <div class="ne-sidebar sidebar-break-md col-lg-3 col-md-12 mt-30">
                                <div class="sidebar-box">
                                    <div class="ne-banner-layout1 text-center">
                                        <a href="/">
                                            <img src="img/imw_img/post_adv.png" alt="adv" class="img-fluid" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr></hr>
                    </div>
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="ne-banner-layout1 text-center mt-10 mb-10">
                                    <a href="#"><img src="img/imw_img/foryou_adv.png" alt="ad" class="img-fluid" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
         
    )
}

export default BusinessViewAll
