import React, { useEffect, useState } from "react";
import { Tabs, Menu, Dropdown, Button, Select, Skeleton } from "antd";

// import { useGetAllActiveCategoryQuery } from "../../Services/CategoryAPI";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import PoliticsSection from "./PoliticsSection";
import TranslateWord from "../../sharedComponents/TranslateWord";
import { getAllPoliticsData, selectedPoliticsInterfaceAction } from "../../../Store/PoliticsSlice";

const PoliticsSectionDB = ({ sectionType }) => {
  const dispatch = useDispatch();
  const { data: allCategoriesList } = useSelector(
    (state) => state.categoriesList
  );
  const { data: interfaceLanguagesData, selectedInterfaceLanguage } =
    useSelector((state) => state.interfaceLanguageList);
  const { data: feedLanguageData } = useSelector(
    (state) => state.feedLanguageList
  );
  const {
    data: allPoliticsData,
    status,
    error,
    politicsSelectedLang,
  } = useSelector((state) => state.politicsList);
  console.log("politicsSelectedLang", politicsSelectedLang);
  
  let filterCategory =
    politicsSelectedLang?.name === "తెలుగు" ? "రాజకీయాలు" : "Politics";
  const ALLWORD =
    politicsSelectedLang?.name === "తెలుగు" ? "అన్ని" : "All";
  const { TabPane } = Tabs;
  const [activeKey, setActiveKey] = useState(ALLWORD);
  const [isShowMore, setisShowMore] = useState(false);
  const [allSubCategoriesList, setAllSubCategoriesList] = useState([]);

  
  const { Option } = Select;
  useEffect(() => {
    setActiveKey(ALLWORD)
    const selectedCategoriesList = allCategoriesList?.find(
      (item) => item.category_name === filterCategory
    );
    const subCategories = selectedCategoriesList?.str_sub_categories_names;
    if (subCategories?.length)
      setAllSubCategoriesList([ALLWORD, ...subCategories]);     
    
  }, [allCategoriesList,filterCategory]);

  const onChange = (key) => {
    setisShowMore(false);
    setActiveKey(key);
  };
 
  const handleChange = (value) => {
    const selectedIngerfaceLang = interfaceLanguagesData?.filter((lang) => {
      if (lang?.interface_lang_id == value) {
        return lang;
      }
    });

    const userSelectedFeedLanguages = feedLanguageData?.filter((feed) => {
      return feed?.name === selectedIngerfaceLang?.[0]?.name;
    });
    console.log("feedLa", userSelectedFeedLanguages);
    const feedLangPayload = userSelectedFeedLanguages?.map((feedL) => {
      return feedL?.newsfeed_lang_id;
    });
    console.log("feedLangPayload", feedLangPayload);
    dispatch(selectedPoliticsInterfaceAction(selectedIngerfaceLang?.[0])); 
  };
  console.log('ALLWORD',ALLWORD)
  console.log('ALLWORD',activeKey)
  return (
    <>
      <div
        style={{
          display: "flex",
          width: "85%",
          justifyContent: "space-between",
          marginTop: "30px",
        }}
      >
        <div
          className="heading headig_technology politics_heading"
          style={{ width: '200px'}}
        >
          <h1>
            <Link to={`/${filterCategory}`}>{sectionType}</Link>
          </h1>
        </div>      
        <div className="more-info-link-light">       
          {politicsSelectedLang && (
            <Select
             className="hide-on-small"
              size="small"
              defaultValue={
                politicsSelectedLang && politicsSelectedLang?.interface_lang_id
              }
              style={{
                width: 95,
                position: "relative",
                top: "-1px",                
              }}
              onChange={handleChange}
            >
              {interfaceLanguagesData?.map((lang) => {
                return (
                  <>
                    <Select.Option value={lang?.interface_lang_id}>
                      {lang?.name}
                    </Select.Option>
                  </>
                );
              })}
            </Select>
          )}

          <span style={{ position: "relative", left: "5px" }}>
            <Link to={`/${filterCategory}`}>
              {
                <TranslateWord
                  key={"VIEWALL"}
                  text={"VIEWALL"}
                  headingStyle={false}
                  lang={politicsSelectedLang}
                />
              }
            </Link>
          </span>
        </div>
      </div>
      <Tabs
        activeKey={activeKey}
        onChange={onChange}
        moreIcon={<span style={{ display: "flex" }}><TranslateWord
        key={"MORE"}
        text={"MORE"}
        headingStyle={false}
        lang={politicsSelectedLang}
      />&#11206;</span>}
        // style={{ maxHeight: "85vh" }}
        className="custom-tabs-container"
      >
        {allSubCategoriesList?.map((tabrecord, index) => {
          const tabKey = tabrecord;
          return (
            <>
              <TabPane
                tab={<span className={politicsSelectedLang?.name === "తెలుగు" ? "telugu-text" : "english-text"}>
                {tabrecord}
              </span>}
                key={tabKey}
                className="custom-width-tabpane"
                style={{ position: "relative", top: "-10vh" }}
              >
                <PoliticsSection
                  key={index + tabKey}
                  subCategoryName={activeKey}
                  categoryName={filterCategory}
                  sectionInterfaceLang={politicsSelectedLang}
                />
              </TabPane>
            </>
          );
        })}
      </Tabs>
    </>
  );
};

export default PoliticsSectionDB;
