import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import BaseUrl from "../utils/config";
const initialState = {
  data: [],
  status: "idle",
  error: "",
  videosSelectedLang: {},
};

const VideosSlice = createSlice({
  name: "videos",
  initialState,
  reducers: {
    selectedVideosInterfaceAction: (state, action) => {
      state.videosSelectedLang = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllVideosData.pending, (state, action) => {
      state.data = [];
      state.status = "loading";
    });
    builder.addCase(getAllVideosData.fulfilled, (state, action) => {
      state.data = action?.payload;
      state.status = "idle";
    });
    builder.addCase(getAllVideosData.rejected, (state, action) => {
      state.data = [];
      state.status = "error";
    });
  },
});

export const getAllVideosData = createAsyncThunk("videos/get", async (payload) => {
  const data = await fetch(`${BaseUrl}/livetvManagement/web/category/videos`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  const result = await data.json();
  return result;
});

export const { selectedVideosInterfaceAction } = VideosSlice.actions;
export default VideosSlice.reducer;
