import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import BaseUrl from "../utils/config";
const initialState = {
  data: [],
  status: "idle",
  error:'',  
};

const SubmissionGrievanceSlice = createSlice({
  name: "submissionGrievance",
  initialState,
  reducers: {
   
  },
  extraReducers: (builder) => {  
    builder.addCase(getAllSubmissionTabGrievance.pending, (state, action) => {   
      state.data = [];  
      state.status = "loading";
    });
    builder.addCase(getAllSubmissionTabGrievance.fulfilled, (state, action) => {
      state.data = action?.payload;
      state.status = "idle";
    });
    builder.addCase(getAllSubmissionTabGrievance.rejected, (state, action) => {  
      state.data = []
      state.status = "error";
    });
  },
});

export const getAllSubmissionTabGrievance = createAsyncThunk("submissionGrievance/get", async (payload) => {
  const data = await fetch(`${BaseUrl}/grievancedetails/all/previous/grievance`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
    
  });
  const result = await data.json();
  return result;
});

export const { selectedSportsInterfaceAction } = SubmissionGrievanceSlice.actions;
export default SubmissionGrievanceSlice.reducer;
