import { Button, Col, Form, Input, message, Modal, Row } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import TranslateWord from "../sharedComponents/TranslateWord";
import BaseUrl from "../../utils/config";

const { TextArea } = Input;
const PetitionUpdates = ({ openFlag, petitionData, handleModelFlag }) => {
  const [isModalOpen, setIsModalOpen] = useState(openFlag);
  const [petitionDescForm] = Form.useForm();
  const { selectedInterfaceLanguage } = useSelector((state) => state.interfaceLanguageList);

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    handleModelFlag();
  };

  const updatePetitionDesption = async (payload) => {
    if (payload) {
      const response = await fetch(`${BaseUrl}/petitiondetails/updatedpetitiondetails`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const res = await response.json();
      console.log("daaa", res.data);
      if (res?.data?.is_saved === true) {
        message.success({
          content: "Successfully Updated",
          duration: 5,
          style: {
            marginTop: "20px",
            marginBottom: "20px",
          },
        });
      }
    }
  };

  const submitingFormData = (values) => {
    const payload = {
      description: values?.petitionUpateDesc,
      petition_details_id: petitionData?.petition_id,
    };
    updatePetitionDesption(payload);
  };
  return (
    <div>
      <Modal
        width={500}
        footer={false}
        open={isModalOpen}
        onOk={handleOk}
        title={"Update Petition"}
        onCancel={handleCancel}>
        <div style={{ maxHeight: "400px", overflow: "auto" }}>
          <Row className="marginBottom_10">
            <Col span={24}>
              <div className="marginBottom_10">Petition ID : {petitionData?.petition_id}</div>

              <div className="marginBottom_10">
                <div>Title</div>
                <div className="inputBox_border">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: petitionData?.title
                        .replace(/<[^>]*>/g, "")
                        .replace(/&nbsp;|&emsp;/g, "")
                        .trim(),
                    }}></span>
                </div>
              </div>

              <div className="marginBottom_10">
                <div>Description</div>
                <div className="inputBox_border">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: petitionData?.description
                        .replace(/<[^>]*>/g, "")
                        .replace(/&nbsp;|&emsp;/g, "")
                        .trim(),
                    }}></span>
                </div>
              </div>

           {petitionData?.updates?.length > 0 && <div className="marginBottom_10">
              <div>Petition Updates</div>
                {petitionData?.updates?.map((update) => {
                  return (
                    <>
                      <div className="inputBox_border" style={{marginBottom:'10px'}}>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: update?.description
                              .replace(/<[^>]*>/g, "")
                              .replace(/&nbsp;|&emsp;/g, "")
                              .trim(),
                          }}></span>
                      </div>
                    </>
                  );
                })}
              </div> }   
           
            </Col>
          </Row>

          <Row>
            <Col Col span={24}>
              <Form
                layout="vertical"
                form={petitionDescForm}
                className="userFormclass"
                onFinish={submitingFormData}
                initialValues={{
                  remember: true,
                }}>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="petitionUpateDesc"
                      label={
                        selectedInterfaceLanguage && (
                          <TranslateWord key={"DESCRIPTION"} text={"DESCRIPTION"} lang={selectedInterfaceLanguage} />
                        )
                      }>
                      <TextArea rows={4} />
                    </Form.Item>
                  </Col>
                </Row>{" "}
                <Form.Item>
                  <Button type="primary" htmlType="submit" style={{ float: "right" }}>
                    {selectedInterfaceLanguage && (
                      <TranslateWord key={"UPDATE"} text={"UPDATE"} lang={selectedInterfaceLanguage} />
                    )}
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default PetitionUpdates;
