import { Badge, Col, Modal, Row, Skeleton, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import politikosLogo from "../../../imgaes/politikosLogo.svg";
import mobileLogo from "../../../imgaes/mobileLogo.svg";
import googleLogo from "../../../imgaes/googleLogo.svg";
import dummy1 from "../../../imgaes/dummy1.svg";
import { useGetLoggedInUsersListQuery } from "../../../Services/OtpAPI";
import LoginDetails from "../LoginDetails/LoginDetails";
import { useAuth0 } from "@auth0/auth0-react";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import { useDispatch, useSelector } from "react-redux";
import { getLogeedInUsers } from "../../../Store/LoginSlice";
import LoadingSpinner from "../../sharedComponents/LoadingSpinner";
import { Router } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";

const LoginPage = ({ openFlag, closeLoginModel, openingLoginDetailsModel }) => {
  const dispatch = useDispatch();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(openFlag);
  const { user, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  const clientId =
    "706815682566-r81qkqvjmdiegaqt05m32iticsgl02sk.apps.googleusercontent.com";
  console.log("googleuser", user);

  const { data: loggedInUsersData, status } = useSelector(
    (state) => state.LoginList
  );
  localStorage.setItem("googleLoggedInuserDetails", user);
  const handleOk = () => {
    setIsLoginModalOpen(false);
  };
  const handleCancel = () => {
    closeLoginModel(false);
  };

  useEffect(() => {
    dispatch(getLogeedInUsers());
  }, []);

  const navigateToLoginScreen = () => {
    setIsLoginModalOpen(false);
    closeLoginModel(false);
    openingLoginDetailsModel(true);
  };
  return (
    <>
      <Modal
        open={isLoginModalOpen} 
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={false}       
      >
        <Row style={{ marginBottom: "40px" }}>
          <Col span={12} offset={6}>
            <img src={politikosLogo} />
          </Col>
        </Row>
        {status === "loading" ? (
          <>
           <LoadingSpinner/>
          </>
        ) : (
          <>
            <Row style={{marginBottom:"10px", marginRight:"40px"}}> 
              <Col span={5} offset={9}>
                {loggedInUsersData?.loggedin_users?.map((user) => {
                  return <Badge count={Array.from(user.first_name)[0]} />;
                })}
              </Col>
              <Col  className="login-user-count ">{`& ${loggedInUsersData?.total_loggedin_users}`}</Col>
            </Row>
          </>
        )}
        <Row style={{ marginBottom: "15px" }}>
          <Col span={16} offset={7}>
            <h3> currently logged in</h3>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col span={16} offset={9}>
            <h3> Sign in with</h3>
          </Col>
        </Row>
        <Row style={{ marginBottom: "25px" }}>
          <Col span={12} offset={9}>
            <Row>
              <Col span={5}>
                <span
                  onClick={navigateToLoginScreen}
                  style={{ cursor: "pointer" }}
                >                
                  <img src={mobileLogo} />
                </span>
              </Col>
              <Col span={5}>
              <Tooltip title={'Coming soon'}>
              <img
                  src={googleLogo}
                  // onClick={() => loginWithRedirect()}
                  style={{ cursor: "pointer" }}
                />
              </Tooltip>
               
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px", marginLeft:"-10px" }}>
        {/* <Row className="login-user-text"> */}
          <Col span={20} offset={5}>
            For Staff/Volunteer sign in{" "}
            <span style={{ cursor: "pointer"}}>Click Here</span>
          </Col>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col span={24} offset={1}>
            By continuing you accept the
            <span style={{ cursor: "pointer" }}>
              Terms of Use and Privacy Policy
            </span>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col span={16} offset={11}>
            <span style={{ cursor: "pointer" }}>SKIP</span>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default LoginPage;
