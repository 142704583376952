import React, { Suspense, lazy, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoadingSpinner from "../sharedComponents/LoadingSpinner";
import CategoryNews from "../sharedComponents/CategoryNews";
import About from "../About";
import LiveTvDetails from "../Livetvdetails";
import VideoDetails from "../VideoDetails";
import BookMarkedStories from "../Sections/Common/BookMarkedStories";
import AboutUs from "../Sections/InnerPages/AboutUs";
import Policies from "../Sections/InnerPages/Policies";
import Policies_link2 from "../Sections/InnerPages/Policies_link2";
import Policies_link3 from "../Sections/InnerPages/Policies_link3";
import Policies_link4 from "../Sections/InnerPages/Policies_link4";
import PeopleCenter from "../Sections/InnerPages/PeopleCenter";
import AdvertiseWithUs from "../Sections/InnerPages/AdvertiseWithUs";
import Features from "../Sections/InnerPages/Features";
import Disclaimer from "../Sections/InnerPages/Disclaimer";
import PolitikosVolunteers from "../Sections/InnerPages/PolitikosVolunteers";
import Partnerwithus from "../Sections/InnerPages/Partnerwithus";
import CallforPilots from "../Sections/InnerPages/CallforPilots";
import Career from "../Sections/InnerPages/Career";
import GotoSource from "../Sections/GotoSource";
import SIIMW from "../Sections/InnerPages/SIIMW";
import Products from "../Sections/InnerPages/Products";
import OurBrands from "../Sections/InnerPages/OurBrands";
import PAffiliates from "../Sections/InnerPages/PAffiliates";
import Programs from "../Sections/InnerPages/Programs";
import InterfaceLanguage from "../Login/InterfaceLanguage";
import MobileLogin from "../Login/MobileLogin";
import GmailLogin from "../Login/GmailLogin";
import LoginLanding from "../Login/LoginLanding";
import OtpGenerate from "../Login/OtpGenerate";
import NewsFeedLanguage from "../Login/NewsFeedLanguage";
import EmojiSelector from "../Sections/Common/EmojiSelector";
import MorEellipsisIcon from "../Sections/Common/MorEellipsisIcon";
import WhatInterestsYou from "../Login/WhatInterestsYou";
import Popup from "../Login/Popup";
import Classifieds from "../Sections/InnerPages/Classifieds";
import InvestorRelations from "../Sections/InnerPages/InvestorRelations";
import BookTalent from "../Sections/InnerPages/BookTalent";
import SubmitCorrections from "../Sections/InnerPages/SubmitCorrections";
import Services from "../Sections/InnerPages/Services";
import CategoriesSectionDB from "../pages/CategoriesSectionDB";
import ProfileDB from "../Profile/ProfileDB";
import FAQs from "../Profile/FAQs";
import JoinVolunteer from "../Profile/JoinVolunteer";
const LazyForYou = lazy(() => import("../Sections/ForYou"));

function Routing() {
  return (
    <div>
      {/* <Router> */}
      <Routes>
        <Route path="/loading" element={<LoadingSpinner />} />
        <Route path="/" element={<CategoriesSectionDB />} />
        <Route
          path="/foryou"
          element={
            <Suspense
              fallback={
                <div>
                  {" "}
                  <LoadingSpinner />
                </div>
              }>
              <LazyForYou />
            </Suspense>
          }
        />

        <Route path="profile" element={<ProfileDB />} />
        <Route path="JoinVolunteer" element={<JoinVolunteer />} />
        {/* <Route path="/about/selectedStories/:type/:selectedType?" element={<SelectedTypeStories />} /> */}
        <Route path="/:category/:subcategory?" element={<CategoryNews />} />
        <Route path="/about" element={<About />} />
        <Route path="/livetvdetails" element={<LiveTvDetails />} />
        <Route path="/videodetails" element={<VideoDetails />} />
        <Route path="/faqs" element={<FAQs />} />
        <Route path="/bookmarked" element={<BookMarkedStories />} exact />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/policies" element={<Policies />} />
        <Route path="/peoplecenter" element={<PeopleCenter />} />
        <Route path="/AdvertiseWithUs" element={<AdvertiseWithUs />} />
        <Route path="/features" element={<Features />} />
        <Route path="/Disclaimer" element={<Disclaimer />} />
        <Route path="/PolitikosVolunteers" element={<PolitikosVolunteers />} />
        <Route path="/Partnerwithus" element={<Partnerwithus />} />
        <Route path="/CallforPilots" element={<CallforPilots />} />
        <Route path="/Career" element={<Career />} />
        <Route path="/gotosource" element={<GotoSource />} />
        <Route path="/policies_link2" element={<Policies_link2 />} />
        <Route path="/policies_link3" element={<Policies_link3 />} />
        <Route path="/policies_link4" element={<Policies_link4 />} />
        <Route path="/SIIMW" element={<SIIMW />} />
        <Route path="/products" element={<Products />} />
        <Route path="/services" element={<Services />} />
        <Route path="/ourbrands" element={<OurBrands />} />
        <Route path="/paffiliates" element={<PAffiliates />} />

        <Route path="/programs" element={<Programs />} />
        <Route path="/interfacelanguage" element={<InterfaceLanguage />} />
        <Route path="/mobilelogin" element={<MobileLogin />} />
        <Route path="/gmailLogin" element={<GmailLogin />} />
        <Route path="/loginLanding" element={<LoginLanding />} />
        <Route path="/otpgenerate" element={<OtpGenerate />} />
        <Route path="/newsfeedlanguage" element={<NewsFeedLanguage />} />
        <Route path="/emojiselector" element={<EmojiSelector />} />
        <Route path="/moreellipsisicon" element={<MorEellipsisIcon />} />
        <Route path="/whatinterestsyou" element={<WhatInterestsYou />} />
        <Route path="/popup" element={<Popup />} />
        <Route path="/Classifieds" element={<Classifieds />} />
        <Route path="/InvestorRelations" element={<InvestorRelations />} />
        <Route path="/BookTalent" element={<BookTalent />} />
        <Route path="/SubmitCorrections" element={<SubmitCorrections />} />
      </Routes>
      {/* </Router> */}
    </div>
  );
}

export default Routing;
