import React, { useState, useRef } from "react";
import spinner from "../../imgaes/spinner.gif";
import spinnerLogo from "../../imgaes/spinnerLogo.svg";
const LoadingSpinner = () => {
  return (
    <>
      <section class="bg-body">
        <div class="container">
          <div
            class="row"
            style={{
              width: "6%",
              margin: "auto",
            }}>
            <img alt="rotate" src={spinnerLogo} className="rotateLogo" />
          </div>
        </div>
      </section>
    </>
  );
};
export default LoadingSpinner;
