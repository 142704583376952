import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Provider, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Components/Home';
import About from './Components/About';
// import ForYou from './Components/Sections/ForYou';
import Politics from './Components/Sections/Politics';
import AboutUs from './Components/Sections/InnerPages/AboutUs';
import SIIMW from './Components/Sections/InnerPages/SIIMW';
import Products from './Components/Sections/InnerPages/Products';
import Services from './Components/Sections/InnerPages/Services';
import OurBrands from './Components/Sections/InnerPages/OurBrands';
import Features from './Components/Sections/InnerPages/Features';
import PeopleCenter from './Components/Sections/InnerPages/PeopleCenter';
import PAffiliates from './Components/Sections/InnerPages/PAffiliates';
import Programs from './Components/Sections/InnerPages/Programs';
import InterfaceLanguage from './Components/Login/InterfaceLanguage';
import MobileLogin from './Components/Login/MobileLogin';
import GmailLogin from './Components/Login/GmailLogin';
import LoginLanding from './Components/Login/LoginLanding';
import OtpGenerate from './Components/Login/OtpGenerate';
import NewsFeedLanguage from './Components/Login/NewsFeedLanguage';
import EmojiSelector from './Components/Sections/Common/EmojiSelector';
import MorEellipsisIcon from './Components/Sections/Common/MorEellipsisIcon';
import WhatInterestsYou from './Components/Login/WhatInterestsYou';
import GotoSource from './Components/Sections/GotoSource';
import Popup from './Components/Login/Popup';
import Policies from './Components/Sections/InnerPages/Policies';
import Policies_link2 from './Components/Sections/InnerPages/Policies_link2';
import Policies_link3 from './Components/Sections/InnerPages/Policies_link3';
import Policies_link4 from './Components/Sections/InnerPages/Policies_link4';

import Disclaimer from './Components/Sections/InnerPages/Disclaimer';
import PolitikosVolunteers from './Components/Sections/InnerPages/PolitikosVolunteers';
import Partnerwithus from './Components/Sections/InnerPages/Partnerwithus';
import CallforPilots from './Components/Sections/InnerPages/CallforPilots';
import Career from './Components/Sections/InnerPages/Career';
import AdvertiseWithUs from './Components/Sections/InnerPages/AdvertiseWithUs';
import Classifieds from './Components/Sections/InnerPages/Classifieds';
import InvestorRelations from './Components/Sections/InnerPages/InvestorRelations';
import BookTalent from './Components/Sections/InnerPages/BookTalent';
import SubmitCorrections from './Components/Sections/InnerPages/SubmitCorrections';
import PoliticsViewAll from './Components/Sections/InnerPages/PoliticsViewAll';
import SportsViewAll from './Components/Sections/InnerPages/SportsViewAll';
import TechnologyViewAll from './Components/Sections/InnerPages/TechnologyViewAll';
import EntertainmentViewAll from './Components/Sections/InnerPages/EntertainmentViewAll';
import BusinessViewAll from './Components/Sections/InnerPages/BusinessViewAll';
import CategoryNews from './Components/sharedComponents/CategoryNews';
// import {
//   useGetAllFeedLanguagesQuery,
//   useGetAllTrendingMutation,
//   useGetBookmarksListQuery,
// } from "./Services/TrendingAPI";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingSpinner from "./Components/sharedComponents/LoadingSpinner";
import BookMarkedStories from "./Components/Sections/Common/BookMarkedStories";
import { Skeleton } from "antd";
import HomeScreenDashBoard from "./Components/pages/HomeScreenDashBoard";
import { getAllInterfaceLanguages } from './Store/interfaceLanguageSlice';
import { getAllFeedLanguages } from './Store/feedLanguageSlice';
import { getAllCategories } from './Store/categoriesSlice';
import { getUserselectedNewsFeedLanguages } from './Store/ProfileSettingsSlice';

const LazyForYou = lazy(() => import("./Components/Sections/ForYou"));

// import EmojiPickerPopup from './Components/Sections/Common/EmojiPickerPopup';
// import whatsAppLink from './Components/Sections/Common/whatsAppLink';

function App() {
  const [trendingData, setTrendingData] = useState([]);
  const dispatch = useDispatch();
  let loggedInUserData = JSON.parse(localStorage.getItem('LoggedInUserDetails'))
  useEffect(() => {
    dispatch(getAllInterfaceLanguages());
    dispatch(getAllFeedLanguages())
      .then((action) => {
        if (getAllFeedLanguages.fulfilled.match(action)) {
          console.log('feedDataInLocalStroage',action)
          localStorage.setItem('feedDataInLocalStroage',JSON.stringify(action.payload?.data))
        }
      });
    dispatch(getAllCategories());
    if(loggedInUserData?.user_id) dispatch(getUserselectedNewsFeedLanguages(loggedInUserData?.user_id));
  }, []);

  // const [saveTrendingAPI] = useGetAllTrendingMutation();
  const userId = 16;
  // const { data: bookMarkStoriesRes } = useGetBookmarksListQuery({
  //   userId,
  // });
  // const { data: feedLangsRes } = useGetAllFeedLanguagesQuery();
  // localStorage.setItem(
  //   "feedLanguagesData",
  //   JSON.stringify(feedLangsRes?.data ?? [])
  // );
  // localStorage.setItem(
  //   "bookmarkedStoriesList",
  //   JSON.stringify(bookMarkStoriesRes?.data ?? [])
  // );
  const { user, isAuthenticated, loginWithRedirect, error } = useAuth0();
  const clientId =
    "706815682566-r81qkqvjmdiegaqt05m32iticsgl02sk.apps.googleusercontent.com";
  console.log("googleuser", user);
  console.log("google isAuthenticated", isAuthenticated);
  console.log("google error", error);
  // const getTrendingDataFunc = async () => {
  //   const feedLangId = localStorage.getItem("LanguageId");
  //   const payload = {
  //     news_feed_lang_ids: [+feedLangId],
  //     page: 0,
  //     pageSize: 500,
  //     login_user_id: 16,
  //   };
  //   if (payload) {
  //     try {
  //       await saveTrendingAPI(payload)
  //         .unwrap()
  //         .then((payload) => {
  //           setTrendingData(payload);
  //           const allStoryListRes = payload?.data ?? [];
  //           localStorage.setItem(
  //             "allStoriesList",
  //             JSON.stringify(allStoryListRes)
  //           );
  //         });
  //     } catch (err) {
  //       console.log("Some thing went wrong");
  //     }
  //   }
  // };

  const bookMarkedStoriesList = () => {};
  // useEffect(() => {
  //   getTrendingDataFunc();
  // }, []);
  localStorage.setItem("LanguageId", 60);
  localStorage.setItem("Language", "English");
  console.log("TrendData", trendingData);

  return (
    <>
      <div className="App">
        {/* <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/loading" element={<LoadingSpinner />} />
            <Route path="/foryou" element={<Suspense fallback={<div><Skeleton  active title={false} paragraph={{rows:15,width:[1500,1500]}} /></div>}>
                        <LazyForYou />
                        </Suspense>} />
            <Route path="SelectedTypeStories" element={<SelectedTypeStories />} />         
            <Route path="/:category/:subcategory?" element={<CategoryNews />} />            
            <Route path="/about" element={<About />} />
            <Route path="/bookmarked" element={<BookMarkedStories />} exact />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/SIIMW" element={<SIIMW />} />
            <Route path="/products" element={<Products />} />
            <Route path="/services" element={<Services />} />
            <Route path="/ourbrands" element={<OurBrands />} />
            <Route path="/features" element={<Features />} />
            <Route path="/peoplecenter" element={<PeopleCenter />} />
            <Route path="/paffiliates" element={<PAffiliates />} />
            <Route path="/programs" element={<Programs />} />
            <Route path="/interfacelanguage" element={<InterfaceLanguage />} />
            <Route path="/mobilelogin" element={<MobileLogin />} />
            <Route path="/gmailLogin" element={<GmailLogin />} />
            <Route path="/loginLanding" element={<LoginLanding />} />
            <Route path="/otpgenerate" element={<OtpGenerate />} />
            <Route path="/newsfeedlanguage" element={<NewsFeedLanguage />} />
            <Route path="/emojiselector" element={<EmojiSelector />} />
            <Route path="/moreellipsisicon" element={<MorEellipsisIcon />} />
            <Route path="/whatinterestsyou" element={<WhatInterestsYou />} />
            <Route path="/popup" element={<Popup />} />
            <Route path="/gotosource" element={<GotoSource />} />
            <Route path="/policies" element={<Policies />} />
            <Route path="/policies_link2" element={<Policies_link2 />} />
            <Route path="/policies_link3" element={<Policies_link3 />} />
            <Route path="/policies_link4" element={<Policies_link4 />} />
            <Route path="/BookTalent" element={<BookTalent />} />
            <Route path="/Disclaimer" element={< Disclaimer />} />
            <Route path="/PolitikosVolunteers" element={< PolitikosVolunteers />} />
            <Route path="/Partnerwithus" element={< Partnerwithus />} />
            <Route path="/CallforPilots" element={< CallforPilots />} />
            <Route path="/Career" element={< Career />} />
            <Route path="/AdvertiseWithUs" element={< AdvertiseWithUs />} />
            <Route path="/Classifieds" element={< Classifieds />} />
            <Route path="/InvestorRelations" element={< InvestorRelations />} />
            <Route path="/BookTalent" element={< BookTalent />} />
            <Route path="/SubmitCorrections" element={< SubmitCorrections />} />
            <Route path="/PoliticsViewAll" element={< PoliticsViewAll />} />
            <Route path="/SportsViewAll" element={< SportsViewAll />} />
            <Route path="/TechnologyViewAll" element={< TechnologyViewAll />} />
            <Route path="/EntertainmentViewAll" element={< EntertainmentViewAll />} />
            <Route path="/BusinessViewAll" element={< BusinessViewAll />} />

          </Routes>
        </Router> */}
        <HomeScreenDashBoard />
      </div>
    </>
  );
}

export default App;
