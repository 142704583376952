import { Modal } from 'antd'
import React, { useState } from 'react';
import closeCircleIcon from '../../imgaes/closeCircleIcon.svg'

const PetitionReason = ({openFlag,petition,reasonTitle,handleModelFlag}) => {
    const [isModalOpen, setIsModalOpen] = useState(openFlag);
    console.log('petition',petition)

    const handleOk = () => {
        setIsModalOpen(false);
      };
      const handleCancel = () => {
        setIsModalOpen(false);
        handleModelFlag()
      };
  return (
    <div>
          <Modal
      
        width={500}        
        footer={false}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
       
      >
        <div style={{marginTop:'5vh'}}>
            <div style={{textAlign:'center'}}><img src={closeCircleIcon} width={80} height={80}/></div>
            <div style={{textAlign:'center'}}> {reasonTitle}</div>
            <div><span>Petition Id : </span> {petition?.petition_id}</div>
            <div> {petition?.title}</div>
            <div> Reason :</div>
            <div> {petition?.rejectedReason}</div>
        </div>
      </Modal>
    </div>
  )
}

export default PetitionReason