import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import BaseUrl from "../utils/config";
const initialState = {
  data: [],
  tabsData: [],
  petitionTabData:[],
  lensTabData:[],
  status: "idle",
  gotoSourceUserstatus: "idle",
};

const GotoSourceSlice = createSlice({
  name: "gotoSource",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllGotoSourceUserDetails.pending, (state, action) => {
      state.data = [];
      state.gotoSourceUserstatus = "loading";
    });
    builder.addCase(getAllGotoSourceUserDetails.fulfilled, (state, action) => {
      state.data = action?.payload?.data;
      state.gotoSourceUserstatus = "idle";
    });
    builder.addCase(
      getAllTabWiseDataForGotoSourcePage.pending,
      (state, action) => {
        state.tabsData = [];
        state.status = "loading";
      }
    );
    builder.addCase(
      getAllTabWiseDataForGotoSourcePage.fulfilled,
      (state, action) => {
        state.tabsData = action?.payload;
        state.status = "idle";
      }
    );

    builder.addCase(
      getAllTabWisePetitionDataForGotoSourcePage.pending,
      (state, action) => {
        state.petitionTabData = [];
        state.status = "loading";
      }
    );
    builder.addCase(
      getAllTabWisePetitionDataForGotoSourcePage.fulfilled,
      (state, action) => {
        state.petitionTabData = action?.payload;
        state.status = "idle";
      }
    );

    builder.addCase(
      getAllTabWiseLensDataForGotoSourcePage.pending,
      (state, action) => {
        state.lensTabData = [];
        state.status = "loading";
      }
    );
    builder.addCase(
      getAllTabWiseLensDataForGotoSourcePage.fulfilled,
      (state, action) => {
        state.lensTabData = action?.payload;
        state.status = "idle";
      }
    );

    builder.addCase(updateUserFollowUP.pending, (state, action) => {
      state.gotoSourceUserstatus = "loading";
    });
    builder.addCase(updateUserFollowUP.fulfilled, (state, action) => {
      state.gotoSourceUserstatus = "idle";
    });
  },
});

export const getAllGotoSourceUserDetails = createAsyncThunk(
  "gotoSourceUser/get",
  async ({ userId, sourceUserId }) => {
    const data = await fetch(
      `${BaseUrl}/user/gotosource?login_user_id=${userId}&source_user_id=${sourceUserId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const result = await data.json();
    return result;
  }
);
export const getAllTabWiseDataForGotoSourcePage = createAsyncThunk(
  "gotoSourcetab/get",
  async (payload) => {
    const data = await fetch(
      `${BaseUrl}/user/gotosourcedetails`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    const result = await data.json();
    return result;
  }
);
export const getAllTabWisePetitionDataForGotoSourcePage = createAsyncThunk(
  "gotoSourcetabForPetition/post",
  async (payload) => {
    const data = await fetch(
      `${BaseUrl}/user/gotosourcedetails`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    const result = await data.json();
    return result;
  }
);
export const getAllTabWiseLensDataForGotoSourcePage = createAsyncThunk(
  "gotoSourcetabForLens/post",
  async (payload) => {
    const data = await fetch(
      `${BaseUrl}/user/gotosourcedetails`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    const result = await data.json();
    return result;
  }
);
export const updateUserFollowUP = createAsyncThunk(
  "updateUserFollowUP/post",
  async (payload) => {
    const data = await fetch(`${BaseUrl}/user/follow`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const result = await data.json();
    return result;
  }
);
// export const { selectedfeedAction } = GotoSourceSlice.actions;
export default GotoSourceSlice.reducer;
