import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip, Empty, Badge, Row, Col, Skeleton, Carousel, Grid } from "antd";
import smileGif from "../../../imgaes/smileGif.gif";
import cryIcon from "../../../imgaes/cryIcon.gif";
import heartIcon from "../../../imgaes/heartIcon.gif";
import angryIcon from "../../../imgaes/angryIcon.gif";
import likeIcon from "../../../imgaes/likeIcon.gif";
import wowIcon from "../../../imgaes/wowIcon.gif";
import noImg from "../../../imgaes/noImg.svg";
import noImgJPG from "../../../imgaes/noImgJPG.jpg";
import smileIcon from "../../../imgaes/smileIcon.svg";
import messageIcon from "../../../imgaes/messageIcon.svg";
import whatsappIcon from "../../../imgaes/whatsappIcon.svg";
import headphoneIcon from "../../../imgaes/headphoneIcon.svg";
import videoPlayIcon from "../../../imgaes/videoPlayIcon.svg";
import ReactPlayer from "react-player/lazy";


import MorEellipsisIcon from "../../Sections/Common/MorEellipsisIcon";
import Emojis from "../../sharedComponents/Emojis";
import { WhatsappShareButton } from "react-share";
import { shareStoryUrl } from "../../../utils/config";
import CommentsModal from "../../sharedComponents/CommentsModal";
import { useDispatch, useSelector } from "react-redux";
import { getAllPoliticsData } from "../../../Store/PoliticsSlice";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingSpinner from "../../sharedComponents/LoadingSpinner";

const { useBreakpoint } = Grid;
const PoliticsSection = ({ categoryName, subCategoryName, sectionInterfaceLang }) => { 
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const [splideAutoScroll, setSplideAutoScroll] = useState(true);
  const [showCommentsDrawer, setShowCommentsDrawer] = useState(false);
  const [storyRecord, setStoryRecord] = useState(null);
  const [isEllipsisVisible, setIsEllipsisVisible] = useState(false);
  const [isEllipsisVisibleSubSlice, setIsEllipsisVisibleSubSlice] = useState(false);

  let loggedInUserData = JSON.parse(localStorage.getItem("LoggedInUserDetails"));
  const { userSelectedNewsFeedData } = useSelector((state) => state.profileSettingsList);
  const { data: allPoliticsData, status, error, politicsSelectedLang } = useSelector((state) => state.politicsList);
  const { data: feedLanguageData, selectedfeedLanguage } = useSelector((state) => state.feedLanguageList);
  const { autoPlayStatus } = useSelector((state) => state.autoPlayList);
  console.log("allPoliticsData", allPoliticsData);
  console.log("allPoliticsData", status);
  const imageStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40px",
    height: "40px",
    zIndex: 666,
  };
  const options = {
    items: 1,
    loop: true,
    autoplay: true,
    autoplayTimeout: 4000,
    animatout: "slidOutUp",
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    margin: 0,
    responsive: {
      1100: {
        items: 1,
      },
      724: {
        items: 1,
      },
      500: {
        items: 1,
      },
      370: {
        items: 1,
        innerWidth: "100%",
        outerWidth: "100%",
      },
    },
  };

  const gettingData = () => {
    const userSelectedFeedLanguages = feedLanguageData?.filter((feed) => {
      return feed?.name === politicsSelectedLang?.name;
    });
    console.log("feedLa", userSelectedFeedLanguages);
    let feedLangPayload = [];
    if (loggedInUserData?.user_id) {
      feedLangPayload = userSelectedNewsFeedData?.filter((item) => item.selected)?.map((item) => item.newsfeed_lang_id);
    } else {
      feedLangPayload = userSelectedFeedLanguages?.map((feedL) => {
        return feedL?.newsfeed_lang_id;
      });
    }
    const payload = {
      category_name: politicsSelectedLang?.name === "తెలుగు" ? "రాజకీయాలు" : "Politics",
      news_feed_lang_ids: feedLangPayload,
      page: 0,
      pageSize: 9,
      sub_category_name: subCategoryName,
      login_user_id: loggedInUserData?.user_id,
    };
    if (!loggedInUserData?.user_id) {
      delete payload.login_user_id;
    }
    if (subCategoryName === "All" || subCategoryName === "అన్ని") {
      delete payload.sub_category_name;
    }
    if (payload?.news_feed_lang_ids?.length) dispatch(getAllPoliticsData(payload));
  };
  useEffect(() => {
    gettingData();
  }, [dispatch, politicsSelectedLang]);

  useEffect(() => {
    gettingData();
  }, [subCategoryName, selectedfeedLanguage]);

  if (status === "loading") {
    return (
      <div>
        {" "}
        <LoadingSpinner/>
        {/* <Skeleton active title={false} paragraph={{ rows: 3, width: [800, 800] }} /> */}
      </div>
    );
  }

  const handleMouseOver = () => {
    setIsEllipsisVisible(true);
  };

  const handleMouseLeave = () => {
    setIsEllipsisVisible(false);
  };
  const handleMouseOverSubSlice = () => {
    setIsEllipsisVisibleSubSlice(true);
  };

  const handleMouseLeaveSubSlice = () => {
    setIsEllipsisVisibleSubSlice(false);
  };

  const goToSourceWithId = (id) => {
    navigate(`/about?param1=${id}&category=${categoryName}&subcategory=${subCategoryName}`);
    window.scrollTo(0,0);
  };

  const goToSourceWitAuthorId = (input_user_id) => {
    navigate(`/GotoSource?param1=${input_user_id}&activeTab=${"Latest"}`);
    window.scrollTo(0,0);
  };
  const handleEmojiSelect = () => {
    setSplideAutoScroll(false);
  };
  const getLatestData = (data) => {
    gettingData();
  };
  const navigateTo = (rec) => {
    navigate(
      // `/about?param1=${rec.id}&category=${props.typeOfNews}&subcategory=${props.typeOfSubCategory}`
      `/about?param1=${rec.id}&category=${categoryName}&subcategory=${subCategoryName}`
    );
  };

  const hangleCommentsDrawer = (rec) => {
    setShowCommentsDrawer(true);
    setStoryRecord(rec);
  };

  const handleCloseDrawer = () => {
    setShowCommentsDrawer(false);
    setStoryRecord(null);
  };
  return (
    <>
      {status === "idle" && allPoliticsData?.data?.length === 0 ? (
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
            marginTop: "100px",
            marginBottom: "70px",
          }}>
          <Empty />
        </div>
      ) : (
        <>
          {status === "idle" && (
            <>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Carousel arrows infinite={false} dots={false} autoplay={autoPlayStatus}>
                    {allPoliticsData?.data?.map((record) => {
                      const originalDate = new Date(record?.pubDate);
                      const currentDate = new Date();
                      const timeDifference = currentDate - originalDate;
                      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
                      const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

                      let displayTime;
                      if (timeDifference < 24 * 60 * 60 * 1000) {
                        // Less than 24 hours
                        displayTime =
                          hours > 0
                            ? `${hours} hrs ago`
                            : minutes > 0
                            ? `${minutes} min ago`
                            : "Less than a min ago";
                      } else {
                        // More than 24 hours
                        displayTime = originalDate.toLocaleDateString("en-GB"); // Format the date as DD-MM-YYYY
                      }

                      return (
                        <>
                          <div className="item">
                            <div className="img-overlay-69 img-scale-animate main_slider_tabs  main_slider_all_politics">
                              <Link to={`/about?param1=${record?.id}`} onClick={() => { window.scrollTo(0,0)}}>
                                {record?.image_urls?.[0] ? (
                                  <img src={record?.image_urls?.[0]} alt="news"   style={{
                                    display: "block", 
                                    margin: "0 auto", 
                                    // maxWidth: "100%", 
                                    // maxHeight: "32vh",
                                    minWidth: "546px", 
                                    minHeight: "307px", 
                                    objectFit: "contain", 
                                    backgroundColor:'#000000'
                                    
                                  }} />
                                ) : record?.video_url ? (
                                  <>
                                    <ReactPlayer
                                      style={{
                                        position: "relative",
                                        zIndex: 9899,
                                        // // width: "546px",
                                        // height: "390px",
                                        minWidth: "546px", 
                                        minHeight: "307px",
                                        top: "-15px",
                                      }}
                                      muted={true}
                                      controls={true}
                                      url={record?.video_url}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <img src={noImgJPG} alt="No-Image" />
                                  </>
                                )}
                              </Link>
                            </div>
                            <div className="carousel_image_title" onClick={() => { window.scrollTo(0,0)}}>
                              <h2 className="title-medium-light size-lg">
                                <Tooltip
                                  title={record?.title
                                    .replace(/<[^>]*>/g, "")
                                    .replace(/&nbsp;|&emsp;/g, "")
                                    .trim()}
                                  color="gray">
                                  <strong
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      navigateTo(record);
                                    }}
                                    className={`hoverTitle ${record?.language === "తెలుగు" ? 'telugu-text-title' : 'english-text-title'}`}>
                                    {record?.title
                                      .replace(/<[^>]*>/g, "")
                                      .replace(/&nbsp;|&emsp;/g, "")
                                      .trim()?.length > 50
                                      ? record?.title
                                          .replace(/<[^>]*>/g, "")
                                          .replace(/&nbsp;|&emsp;/g, "")
                                          .trim()
                                          ?.substring(0, 50) + "..."
                                      : record?.title
                                          .replace(/<[^>]*>/g, "")
                                          .replace(/&nbsp;|&emsp;/g, "")
                                          .trim()}
                                  </strong>
                                </Tooltip>
                                {/* <Link to={`/about?param1=${record?.id}`}>{record?.title}</Link> */}
                              </h2>
                              <div className="post-date-light" style={{ marignTop: "10px" }}>
                                <ul style={{ position: "relative", top: "1vh" }}>
                                  <li>
                                    <span>
                                      <i className="fa fa-user" aria-hidden="true"></i>
                                    </span>
                                    <Link to={`/GotoSource?param1=${record?.user_id}&activeTab=${"Latest"}`}>
                                      {record?.author} <strong className="duration_slider_main">{displayTime}</strong>
                                    </Link>
                                  </li>
                                  <li className="float-right social_data">
                                    <span className="social_icons">
                                      {/* <EmojiSelector
                                     onSelect={handleEmojiSelect}
                                   /> */}
                                      {record?.emoji_comments?.map((emj) => {
                                        const count = emj?.count;
                                        return (
                                          <>
                                            <Badge
                                              count={
                                                <div
                                                  style={{
                                                    width: "15px",
                                                    height: "14px",
                                                    borderRadius: "50%",
                                                    background: "#ff4d4f",
                                                  }}>
                                                  <span
                                                    style={{
                                                      marginLeft:
                                                        emj?.count < 9 ? "6px" : emj?.count < 100 ? "2px" : "1px",
                                                      marginTop: "3px",
                                                      fontSize: "9px",
                                                      color: "white",
                                                    }}>
                                                    {emj?.count}
                                                  </span>
                                                </div>
                                              }>
                                              {/* <Avatar size="small" src={<img style={{width:'25px',height:'25px',cursor:'pointer'}} src={wowIcon}/>} /> */}
                                              {emj?.emoji_name === "angry" && (
                                                <img
                                                  style={{
                                                    width: "18px",
                                                    height: "18px",
                                                    cursor: "pointer",
                                                    marginLeft: "5px",
                                                  }}
                                                  src={angryIcon}
                                                />
                                              )}
                                              {emj?.emoji_name === "love" && (
                                                <img
                                                  style={{
                                                    width: "18px",
                                                    height: "18px",
                                                    cursor: "pointer",
                                                    marginLeft: "5px",
                                                  }}
                                                  src={heartIcon}
                                                />
                                              )}
                                              {emj?.emoji_name === "like" && (
                                                <img
                                                  style={{
                                                    width: "18px",
                                                    height: "18px",
                                                    cursor: "pointer",
                                                    marginLeft: "5px",
                                                  }}
                                                  src={likeIcon}
                                                />
                                              )}
                                              {emj?.emoji_name === "laugh" && (
                                                <img
                                                  style={{
                                                    width: "18px",
                                                    height: "18px",
                                                    cursor: "pointer",
                                                    marginLeft: "5px",
                                                  }}
                                                  src={smileGif}
                                                />
                                              )}
                                              {emj?.emoji_name === "sad" && (
                                                <img
                                                  style={{
                                                    width: "18px",
                                                    height: "18px",
                                                    cursor: "pointer",
                                                    marginLeft: "5px",
                                                  }}
                                                  src={cryIcon}
                                                />
                                              )}
                                              {emj?.emoji_name === "wow" && (
                                                <img
                                                  style={{
                                                    width: "18px",
                                                    height: "18px",
                                                    cursor: "pointer",
                                                    marginLeft: "5px",
                                                  }}
                                                  src={wowIcon}
                                                />
                                              )}
                                            </Badge>
                                          </>
                                        );
                                      })}
                                      <span
                                       >
                                        <Emojis record={record} sendLatestData={getLatestData} />{" "}
                                      </span>
                                      <Tooltip title="Coming soon" color="gray">
                                        <img src={headphoneIcon} alt="icon" className="icons_img" />
                                      </Tooltip>
                                      <Tooltip title="Coming soon" color="gray">
                                        <img
                                          src={messageIcon}
                                          alt="icon"
                                          className="icons_img"
                                          style={{
                                            position: "relative",
                                            top: "2px",
                                          }}
                                          // onClick={() => {
                                          //   hangleCommentsDrawer(record);
                                          // }}
                                        />
                                      </Tooltip>
                                      {/* <span
                                        onClick={() => {
                                          hangleCommentsDrawer(record);
                                        }}
                                      >
                                        <img
                                          src={messageIcon}
                                          alt="icon"
                                          className="icons_img"
                                          style={{
                                            position: "relative",
                                            top: "2px",
                                          }}
                                        />
                                      </span> */}
                                      <span
                                        style={{
                                          position: "relative",
                                          left: "-4px",
                                        }}>
                                        <WhatsappShareButton
                                          url={`${shareStoryUrl}/about?param1=${record?.id}`}
                                          title={record?.title
                                            .replace(/<[^>]*>/g, "")
                                            .replace(/&nbsp;|&emsp;/g, "")
                                            .trim()}
                                          className="Demo__some-network__share-button">
                                          <img src={whatsappIcon} alt="icon" className="icons_img" />
                                        </WhatsappShareButton>
                                      </span>

                                      <span
                                        style={{
                                          position: "relative",
                                          top: "3px",
                                        }}>
                                        <MorEellipsisIcon
                                          story={record}
                                          sendLatestData={getLatestData}
                                          bookMarkStoryId={record?.id}
                                          page="PoliticsPage"
                                        />
                                      </span>
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </Carousel>
                  {showCommentsDrawer && (
                    <CommentsModal
                      showDrawer={showCommentsDrawer}
                      closeDrawer={handleCloseDrawer}
                      story={storyRecord}
                    />
                  )}
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Splide
                    options={{
                      rewind: true,
                      gap: "1rem",
                      autoplay: autoPlayStatus,
                      // gap: "-85px",          // for small divices gap:-15px
                      direction: "ttb",
                      height: "43rem",
                      type: allPoliticsData?.data?.length <= 3 ? "slide" : "slide",
                      drag: "free",
                      perPage: screens.xs ? 3 :4,
                      autoScroll: {
                        pauseOnHover: true,
                        pauseOnFocus: true,
                        rewind: false,
                        speed: 1,
                        enabled: splideAutoScroll, // Enable or disable auto-scroll based on the state
                      },
                    }}
                    // extensions={{ AutoScroll }}
                    // extensions={newsubData?.length > 3 ? {AutoScroll}  : null}
                  >
                    <>
                      {allPoliticsData?.data?.map((record) => {
                        const originalDate = new Date(record?.pubDate);
                        const currentDate = new Date();
                        const timeDifference = currentDate - originalDate;
                        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
                        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

                        let displayTime;
                        if (timeDifference < 24 * 60 * 60 * 1000) {
                          // Less than 24 hours
                          displayTime =
                            hours > 0
                              ? `${hours} hrs ago`
                              : minutes > 0
                              ? `${minutes} min ago`
                              : "Less than a min ago";
                        } else {
                          // More than 24 hours
                          displayTime = originalDate.toLocaleDateString("en-GB"); // Format the date as DD-MM-YYYY
                        }

                        return (
                          <SplideSlide>
                            <div className="item tiles_slider">
                              <div className="media" style={{ cursor: "pointer" }}>
                                <div
                                  className="width38-lg width40-md img-opacity-hover"
                                  onClick={() => {
                                    goToSourceWithId(record?.id);
                                  }}>
                                  {record?.image_urls?.[0] ? (
                                    <img src={record?.image_urls?.[0]} alt="news" style={{           
                                      display: "block", 
                                      margin: "0 auto", 
                                      // maxWidth: "100%", 
                                      // maxHeight: "32vh",
                                      width: "150px", 
                                      height: "85px", 
                                      objectFit: "contain", 
                                      // backgroundColor:'#000000',
                                      borderRadius:"8%"
                                    }} />
                                  ) : (record?.video_url && record?.video_thumbnail_url) ? (
                                    <>
                                      <span>
                                        <img style={imageStyle} src={videoPlayIcon} />
                                      </span>{" "}
                                      <img
                                     src={record?.video_thumbnail_url}
                                     alt="news"
                                     style={{
                                      display: "block", 
                                      margin: "0 auto", 
                                      // maxWidth: "100%", 
                                      // maxHeight: "32vh",
                                      width: "150px", 
                                      height: "85px", 
                                      objectFit: "cover", 
                                      // backgroundColor:'#000000'
                                      borderRadius:"8%"
                                      

                                      
                                    }}
                                   />
                                      {/* <video
                                        style={{
                                          width: "100px",
                                          height: "100px",
                                          backgroundColor: "black",
                                        }}
                                        muted
                                        // preload="metadata"
                                        preload="metadata"
                                        src={`${record?.video_url}#t=0.1`}></video> */}
                                    </>
                                  ) : (
                                    <>
                                      <img src={noImgJPG} alt="No-Image" />
                                    </>
                                  )}
                                </div>
                                <div className="media-body">
                                  <h3
                                     className={`title-medium-dark size-md mb-none ${record?.language === "తెలుగు" ? 'telugu-text' : 'english-text'}`}
                                    onClick={() => {
                                      goToSourceWithId(record?.id);
                                    }}>
                                    {record?.title
                                      .replace(/<[^>]*>/g, "")
                                      .replace(/&nbsp;|&emsp;/g, "")
                                      .trim()}
                                  </h3>
                                  {/* <p className='duration_slider'> {`${days} days ${hours} hours ago`}</p> */}
                                  <Row>
                                    <Col span={15}>
                                      <li
                                        key={record?.user_id}
                                        onClick={() => {
                                          goToSourceWitAuthorId(record?.user_id);
                                        }}>
                                        <span>
                                          <i className="fa fa-user" aria-hidden="true"></i>
                                        </span>
                                        &nbsp;
                                        {record?.author.length > 10 ? record?.author.substring(0, 10) + "..." : record?.author}
                                      
                                      </li>
                                    </Col>
                                    <Col span={7}>
                                      <p className="duration_slider" style={{ marginTop: "3px", lineHeight:"19px" }}>
                                        {displayTime}
                                      </p>
                                    </Col>
                                  </Row>

                                  <div className="post-date-light politics_post">
                                    <ul>
                                      {/* <li
                                       key={record?.user_id}
                                       onClick={() => {
                                         goToSourceWitAuthorId(
                                           record?.user_id
                                         );
                                       }}
                                     >
                                       <span>
                                         <i
                                           className="fa fa-user"
                                           aria-hidden="true"
                                         ></i>
                                       </span>
                                       {record?.author}
                                     </li> */}
                                      <li>
                                        {record?.emoji_comments?.map((emj) => {
                                          const count = emj?.count;
                                          return (
                                            <>
                                              <Badge
                                                count={
                                                  <div
                                                    style={{
                                                      width: "15px",
                                                      height: "14px",
                                                      borderRadius: "50%",
                                                      background: "#ff4d4f",
                                                    }}>
                                                    <span
                                                      style={{
                                                        marginLeft:
                                                          emj?.count < 9 ? "6px" : emj?.count < 99 ? "6px" : "1px",
                                                        marginTop: "3px",
                                                        fontSize: "9px",
                                                        color: "white",
                                                      }}>
                                                      {emj?.count}
                                                    </span>
                                                  </div>
                                                }>
                                                {emj?.emoji_name === "angry" && (
                                                  <img
                                                    style={{
                                                      width: "18px",
                                                      height: "18px",
                                                      cursor: "pointer",
                                                      marginRight: "5px",
                                                    }}
                                                    src={angryIcon}
                                                  />
                                                )}
                                                {emj?.emoji_name === "love" && (
                                                  <img
                                                    style={{
                                                      width: "18px",
                                                      height: "18px",
                                                      cursor: "pointer",
                                                      marginRight: "5px",
                                                    }}
                                                    src={heartIcon}
                                                  />
                                                )}
                                                {emj?.emoji_name === "like" && (
                                                  <img
                                                    style={{
                                                      width: "18px",
                                                      height: "18px",
                                                      cursor: "pointer",
                                                      marginRight: "5px",
                                                    }}
                                                    src={likeIcon}
                                                  />
                                                )}
                                                {emj?.emoji_name === "laugh" && (
                                                  <img
                                                    style={{
                                                      width: "18px",
                                                      height: "18px",
                                                      cursor: "pointer",
                                                      marginRight: "5px",
                                                    }}
                                                    src={smileGif}
                                                  />
                                                )}
                                                {emj?.emoji_name === "sad" && (
                                                  <img
                                                    style={{
                                                      width: "18px",
                                                      height: "18px",
                                                      cursor: "pointer",
                                                      marginRight: "5px",
                                                    }}
                                                    src={cryIcon}
                                                  />
                                                )}
                                                {emj?.emoji_name === "wow" && (
                                                  <img
                                                    style={{
                                                      width: "18px",
                                                      height: "18px",
                                                      cursor: "pointer",
                                                      marginRight: "5px",
                                                    }}
                                                    src={wowIcon}
                                                  />
                                                )}
                                              </Badge>
                                            </>
                                          );
                                        })}
                                      </li>
                                      <li className="float-right social_data">
                                        <span className="social_icons">
                                          {/* <EmojiSelector
                                           onSelect={handleEmojiSelect}
                                           onEmojiSelect={handleEmojiSelect}
                                           title="Coming soon"
                                         /> */}
                                          <span
                                           >
                                            <Emojis record={record} sendLatestData={getLatestData} />{" "}
                                          </span>
                                          <Tooltip title="Coming soon" color="gray">
                                            <img src={headphoneIcon} alt="icon" className="icons_img" />
                                          </Tooltip>
                                          <Tooltip title="Coming soon" color="gray">
                                            <img
                                              src={messageIcon}
                                              alt="icon"
                                              className="icons_img"
                                              style={{
                                                position: "relative",
                                                top: "2px",
                                              }}
                                              // onClick={() => {
                                              //   hangleCommentsDrawer(record);
                                              // }}
                                            />
                                          </Tooltip>
                                          {/* <img
                                              src={messageIcon}
                                              alt="icon"
                                              className="icons_img"
                                              style={{
                                                position: "relative",
                                                top: "2px",
                                              }}
                                              onClick={() => {
                                                hangleCommentsDrawer(record);
                                              }}
                                            /> */}

                                          <span>
                                            <WhatsappShareButton
                                              url={`${shareStoryUrl}/about?param1=${record?.id}`}
                                              title={record?.title
                                                .replace(/<[^>]*>/g, "")
                                                .replace(/&nbsp;|&emsp;/g, "")
                                                .trim()}
                                              className="Demo__some-network__share-button"
                                              image={
                                                "https://api-dev.politikos.cloud/story/image/7528944540472399734.jpeg"
                                              }>
                                              <img src={whatsappIcon} alt="icon" className="icons_img" />
                                            </WhatsappShareButton>
                                          </span>
                                          <span
                                            style={{
                                              position: "relative",
                                              top: "-6px",
                                              width: "10px",
                                              backgroundColor: "white",
                                              padding: "10px",
                                            }}
                                            onMouseOver={handleMouseOverSubSlice}
                                            onMouseLeave={handleMouseLeaveSubSlice}>
                                            {isEllipsisVisibleSubSlice ? (
                                              <MorEellipsisIcon
                                                story={record}
                                                sendLatestData={getLatestData}
                                                bookMarkStoryId={record?.id}
                                                page="PoliticsPage"
                                              />
                                            ) : (
                                              <span>
                                                <FontAwesomeIcon
                                                  icon={faEllipsisV}
                                                  style={{ position: "relative", top: "-3px" }}
                                                />
                                              </span>
                                            )}
                                          </span>
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </SplideSlide>
                        );
                      })}
                    </>
                  </Splide>
                  {showCommentsDrawer && (
                    <CommentsModal
                      showDrawer={showCommentsDrawer}
                      closeDrawer={handleCloseDrawer}
                      story={storyRecord}
                    />
                  )}
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </>
  );
};

export default PoliticsSection;
