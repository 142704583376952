import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import BaseUrl from "../utils/config";
const initialState = {
  status: "idle",
  error: "",
};

const BookmarkedLivetvSlice = createSlice({
  name: "BookmarkedLivetv",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addBookmarkLivetv.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(addBookmarkLivetv.fulfilled, (state, action) => {
      state.status = "idle";
    });
    builder.addCase(removeBookmarkLivetv.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(removeBookmarkLivetv.fulfilled, (state, action) => {
      state.status = "idle";
    });
  },
});

export const addBookmarkLivetv = createAsyncThunk("addBookmarkLivetv/post", async (payload) => {
  const data = await fetch(`${BaseUrl}/bookmarkLiveTvManagement`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  const result = await data.json();
  return result;
});
export const removeBookmarkLivetv = createAsyncThunk("removeBookmarkLivetv/get", async (payload) => {
  const data = await fetch(
    `${BaseUrl}/bookmarkLiveTvManagement?login_user_id=${payload.userId}&livetv_management_id=${payload.liveTvManagmentId}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const result = await data.json();
  return result;
});
export default BookmarkedLivetvSlice.reducer;
