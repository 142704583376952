import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import BaseUrl from "../utils/config";
const initialState = {
  data: {},
  status: "idle",
  error: "",
};

const LivetvdetailsSlice = createSlice({
  name: "livetvDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLivetvdetailsPage.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getLivetvdetailsPage.fulfilled, (state, action) => {
      state.data = action?.payload;
      state.status = "idle";
    });
  },
});

export const getLivetvdetailsPage = createAsyncThunk("livetvDetails/get", async ({ id, login_user_id }) => {
  const data = await fetch(`${BaseUrl}/livetvManagement/${id}?login_user_id=${login_user_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const result = await data.json();
  return result;
});

export default LivetvdetailsSlice.reducer;
