import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Tabs,
  Upload,
  message,
  TreeSelect,
  Checkbox
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import userProfilePic from "../../imgaes/userProfilePic.svg";
import uploadProfileImg from "../../imgaes/uploadProfileImg.svg";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import OTP from "../Login/OTP/OTP";
import { getOTPData, sendOTPtoMail } from "../../Store/LoginSlice";
import {
  getUserProfileData,
  updateProfileUserPicture,
  updateProfileUserSettings,
} from "../../Store/ProfileSettingsSlice";
import BaseUrl from "../../utils/config";
import deleteIcon from "../../imgaes/deleteIcon.png";
import backIcon from '../../imgaes/backIcon.svg'
import axios from "axios";
import TranslateWord from "../sharedComponents/TranslateWord";
import {
  getAreaOfExpertise,
  getInvolvedData,
  getJoinVolunteerData,
  getJoinVolunteerInfoData,
  getVolunteerActivites,
  updateVolunteerActivities,
  updateVolunteerAdditionalInfo,
  updateVolunteerKYC,
  updateVolunteerSignup,
} from "../../Store/JoinVolunteerSlice";
import { useNavigate } from "react-router-dom";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
const { TreeNode, SHOW_PARENT } = TreeSelect;
const JoinVolunteer = () => {
  const { TextArea } = Input;
  const { TabPane } = Tabs;
  const { Option } = Select;
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [profileAboutYourSelf] = Form.useForm();
  const [joinVolunteerForm] = Form.useForm();
  const [additionalInformationForm] = Form.useForm();
  const [volunteerKYC] = Form.useForm();
  const [addressFrom] = Form.useForm();
  const dateFormat = "DD-MM-YYYY";
  const [fileList, setFileList] = useState([]);
  const [fModalVisible, setFModalVisible] = useState(false);
  const [isOTPModalOpen, setIsOTPModalOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const [emptyFieldIndexArr, setEmptyFieldIndexArr] = useState([]);
  const inputRefs = useRef([]);
  const mypancardRef = useRef(null);
  const [disableAddBtn, setDisableAddBtn] = useState(false);
  const [currentFormsList, setCurrentFormsList] = useState(null);
  const [removingItem, setRemovingItem] = useState(null);
  const [removedList, setRemovedList] = useState([]);
  const [subLevelOldData, setSubLevelOldData] = useState(null);
  const [dupIndex, setDupIndex] = useState([]);
  const [fileListForAddhar, setFileListForAddhar] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [fileListForPANcard, setFileListForPANcard] = useState([]);
  const [involvedTreeData, setInvolvedTreeData] = useState([]);
  const [involvedTreeDataIds, setInvolvedTreeDataIds] = useState([]);
  const [areaOfExpertiseTreeDataIds, setAreaOfExpertiseTreeDataIds] = useState([]);
  const [aadharImgId, setAadharImgId] = useState(null);
  const [pancardImgId, setPancardImgId] = useState(null);
  const [volunteerId, setVolunteerId] = useState(null);
  const [converteActivitiesData, setConverteActivitiesData] = useState([]);
  const [selectedActivitiesList, setSelectedActivitiesList] = useState([]);
  const [areaOfExpertiseTreeData, setAreaOfExpertiseTreeData] = useState([]);

  //   const [isModalVisible, setIsModalVisible] = useState(false);
  let curentListValues = [];
  const { data: genderData } = useSelector((state) => state.genderList);
  const { data: maritalStatusData } = useSelector(
    (state) => state.maritalStatusList
  );
  const { selectedInterfaceLanguage } = useSelector(
    (state) => state.interfaceLanguageList
  );
  const { data: feedLangListData } = useSelector(
    (state) => state.feedLanguageList
  );

  console.log('selectedInterfaceLanguage',selectedInterfaceLanguage)
  const { userProfileData, workExperienceData, educationQualificationData } =
    useSelector((state) => state.profileSettingsList);
  const { joinVolunteerData,volunteerInfo,volunteerActivitesData,volunteerAreaOfExpertiseData } = useSelector((state) => state.joinVolunteerList);
  console.log("volunteerAreaOfExpertiseData", volunteerAreaOfExpertiseData);


  useEffect(()=>{
    const convertedData = volunteerActivitesData?.map(item => ({
      value: item.volunteer_activities_id,
      label: item.activity_name
    }));
    setConverteActivitiesData(convertedData)
  },[volunteerActivitesData])

  const getVolunteerTotalInfo = () =>{
    let user_id =  239; 
    // let user_id = userProfileData?.user_id ?? 239; 

    dispatch(getJoinVolunteerInfoData(user_id)).then((action) => {
      if (getJoinVolunteerInfoData.fulfilled.match(action)) {
     console.log('action',action)
     console.log("volunteerInfo", action?.payload?.data?.kyc_images);
     setVolunteerId(action?.payload?.data?.signup?.volunteer_id)
     joinVolunteerForm.setFieldsValue({
      full_name: action?.payload?.data?.signup?.full_name,
      mobile_number: action?.payload?.data?.signup?.mobile_number,
      email: action?.payload?.data?.signup?.email,
    });
    additionalInformationForm.setFieldsValue({
      streetName: action?.payload?.data?.additonal_information?.streetName,
      mandal: action?.payload?.data?.additonal_information?.mandal,
      district: action?.payload?.data?.additonal_information?.district,
      state: action?.payload?.data?.additonal_information?.state,
      village: action?.payload?.data?.additonal_information?.village,
      location: action?.payload?.data?.additonal_information?.location,
      address_line1: action?.payload?.data?.additonal_information?.address_line1,
      address_line2: action?.payload?.data?.additonal_information?.address_line2,
      name: action?.payload?.data?.additonal_information?.name,
      phone: action?.payload?.data?.additonal_information?.phone,
      nature_of_relationship: action?.payload?.data?.additonal_information?.nature_of_relationship,
      
    });
    volunteerKYC.setFieldsValue({
      // volunteer_id: volunteerInfo?.signup?.volunteer_id,
      // user_id: 239,
      // aadhaar_image_id: aadharImgId,
      aadhaar_number: action?.payload?.data?.kyc_images?.aadhaar_number?.replace(/-/g, ""),
      // pancard_image_id: pancardImgId,
      pancard_number: action?.payload?.data?.kyc_images?.pancard_number,
    })

    setAadharImgId(action?.payload?.data?.kyc_images?.aadhar?.image_id)
    setPancardImgId(action?.payload?.data?.kyc_images?.pan?.image_id)
    
  console.log('kyc',action?.payload?.data?.kyc_images)
 
    
      }
    });
  }
  useEffect(()=>{
     getVolunteerTotalInfo()
  },[])


  useEffect(() => {
    var dob = new Date(moment(userProfileData?.user_profile?.date_of_birth));
    //calculate month difference from current date in time
    var month_diff = Date.now() - dob.getTime();

    //convert the calculated difference in date format
    var age_dt = new Date(month_diff);

    //extract year from date
    var year = age_dt.getUTCFullYear();

    //now calculate the age of the user
    var ageDiff = Math.abs(year - 1970);
    if (userProfileData?.profile_url) {
      fileList.splice(0, fileList.length);
      // to view image file
      const profImageObj = {
        uid: "-1",
        name: `Profile picture`,
        status: "done",
        url: userProfileData?.profile_url ? userProfileData?.profile_url : null,
        response: '{"status": "success"}', // response from server
        linkProps: '{"download": "image"}', // additional html props of file link
        xhr: "{}",
      };
      fileList.push(profImageObj);
    } else {
      setFileList([]);
    }
    if (userProfileData?.user_profile?.aadhar_image?.image_url) {
      fileListForAddhar.splice(0, fileListForAddhar.length);
      // to view image file
      const addharImageObj = {
        uid: "-1",
        // name: record?.empName,
        name: `Aadhar card`,
        status: "done",
        url: userProfileData?.user_profile?.aadhar_image?.image_url
          ? userProfileData?.user_profile?.aadhar_image?.image_url
          : null,
      };
      fileListForAddhar.push(addharImageObj);
    } else {
      setFileListForAddhar([]);
    }
    if (userProfileData?.user_profile?.pan_image?.image_url) {
      fileListForPANcard.splice(0, fileListForPANcard.length);
      // to view image file
      const panImageObj = {
        uid: "-1",
        // name: record?.empName,
        name: `Pan card`,
        status: "done",
        url: userProfileData?.user_profile?.pan_image?.image_url
          ? userProfileData?.user_profile?.pan_image?.image_url
          : null,
      };
      fileListForPANcard.push(panImageObj);
    } else {
      setFileListForPANcard([]);
    }

   
   
  }, [joinVolunteerData]);
  useEffect(() => {
    const payload = 239;
    dispatch(getJoinVolunteerData(payload));
    const interfaceLangId = selectedInterfaceLanguage?.interface_lang_id;
    interfaceLangId &&  dispatch(getVolunteerActivites(interfaceLangId))
    interfaceLangId &&  dispatch(getInvolvedData(interfaceLangId)).then((action) => {
      if (getInvolvedData.fulfilled.match(action)) {
        console.log("action", action?.payload?.data);
        const treeDataRes = action?.payload?.data;
        const treeData1 = transformDataToTree(treeDataRes);
        console.log("treeData1", treeData1);
        const treeData = treeDataRes?.map((option) => ({
          label: option.vol_involvement_name,
          value: `${option.vol_involvement_id},${option.vol_involvement_name}`,
          // key: option.state_id,
          children: option.vol_sub_involvements
            ? option.vol_sub_involvements.map((child) => ({
                label: child.sub_involvement_name,
                // value: child.district_id,
                value: `${option.vol_involvement_id},${child.vol_sub_involvemnt_id}`,
                // key: child.district_id,
              }))
            : null,
        }));
        console.log("treeData", treeData);
        setInvolvedTreeData(treeData);
      }
    });
    interfaceLangId &&  dispatch(getAreaOfExpertise(interfaceLangId)).then((action) => {
      if (getAreaOfExpertise.fulfilled.match(action)) {
        console.log("action", action?.payload?.data);
        const areaOfExpertiseRes = action?.payload?.data;
        // const treeData1 = transformDataToTree(areaOfExpertiseRes);
        // console.log("treeData1", treeData1);
        const treeareaOfExpertiseData = areaOfExpertiseRes?.map((option) => ({
          label: option.vol_area_of_expertise_name,
          value: `${option.vol_area_of_expertise_id},${option.vol_area_of_expertise_name}`,
          // key: option.state_id,
          children: option.vol_sub_area_of_expertise
            ? option.vol_sub_area_of_expertise.map((child) => ({
                label: child.sub_area_of_expertise_name,
                // value: child.district_id,
                value: `${option.vol_area_of_expertise_id},${child.sub_area_of_expertise_id}`,
                // key: child.district_id,
              }))
            : null,
        }));
        console.log("treeareaOfExpertiseData", treeareaOfExpertiseData);
        setAreaOfExpertiseTreeData(treeareaOfExpertiseData);
      }
    });
  }, [selectedInterfaceLanguage]);
  const transformDataToTree = (data) => {
    return data?.map((parent) => ({
      title: parent.vol_involvement_name,
      value: parent.vol_involvement_id.toString(),
      key: parent.vol_involvement_id.toString(),
      children: parent.vol_sub_involvements.map((child) => ({
        title: child.sub_involvement_name,
        value: `${parent.vol_involvement_id}-${child.vol_sub_involvemnt_id}`,
        key: `${parent.vol_involvement_id}-${child.vol_sub_involvemnt_id}`,
      })),
    }));
  };

  const submitProfileAboutYourSelfForm = (values) => {
    let user_id = userProfileData?.user_id;
    const payload = {
      user_id: user_id,
      full_name: values?.full_name,
      lastname: values?.lastname,
      display_name: values?.display_name,
      mobile_number: values?.mobile_number,
      gender: values?.gender,
      marital_status: values?.marital_status,
      date_of_birth: moment(values?.date_of_birth),
      // gmail_id: values?.gmail_id,
      // age: values?.age,
      location: values?.location,
      state: values?.state,
      mandal: values?.mandal,
      district: values?.district,
      village: values?.village,
      breif_about_self: values?.breif_about_self,
      educational_qualification_id: values?.educational_qualification_id,
      work_experience_id: values?.work_experience_id,
      aadhaar_number: values?.aadhaar_number,
      pancard_number: values?.pancard_number,

      bank_account_number: values?.bank_account_number,
      bank_address: values?.bank_address,
      bank_ifsc_code: values?.bank_ifsc_code,
      bank_name: values?.bank_name,
      swift_code: values?.swift_code,
    };
    dispatch(updateProfileUserSettings(payload)).then((action) => {
      if (updateProfileUserSettings.fulfilled.match(action)) {
        dispatch(getUserProfileData(user_id));
        message.success({
          content: "Updated successfully",
          duration: 5,
          style: {
            marginTop: "20px",
            marginBottom: "20px",
          },
        });
      }
    });
    // const payload = {
    //   interface_lang_id: values?.interfaceLang,
    //   user_id: user?.user_id,
    // };
    // dispatch(selectingInterfaceLanguage(payload));
  };
  const submitJoinVolunteerTabDetails = (values) => {
    let user_id = userProfileData?.user_id;
    console.log("submitJoinVolunteerTabDetails", values);
    const payload = {
      mobile_number: +values?.mobile_number,
      email: values?.email,
      full_name: values?.full_name,
      volunteer_id: volunteerInfo?.signup?.volunteer_id,
      user_id: user_id ?? 239,
      volunteer_involvements: involvedTreeDataIds,
    };
    dispatch(updateVolunteerSignup(payload)).then((action) => {
      if (updateVolunteerSignup.fulfilled.match(action)) {
        message.success({
          content: "Updated successfully",
          duration: 5,
          style: {
            marginTop: "20px",
            marginBottom: "20px",
          },
        });
      }
    });
  };
  const submitJoinVolunteerTabKYC = (values) => {
    let user_id = userProfileData?.user_id;
    console.log("submitJoinVolunteerTabDetails", values);
    const payload = {
      volunteer_id: volunteerInfo?.signup?.volunteer_id,
      user_id: 239,
      aadhaar_image_id: aadharImgId,
      aadhaar_number: values?.aadhaar_number,
      pancard_image_id: pancardImgId,
      pancard_number: values?.pancard_number,
    };
    dispatch(updateVolunteerKYC(payload)).then((action) => {
      if (updateVolunteerKYC.fulfilled.match(action)) {
        message.success({
          content: "Updated successfully",
          duration: 5,
          style: {
            marginTop: "20px",
            marginBottom: "20px",
          },
        });
      }
    });
  };

 
  const submitAdditionalInformationForm = (values) => {
    let user_id = userProfileData?.user_id;
    console.log("submitJoinVolunteerTabDetails", values);
    const payload = {
      streetName: values?.streetName,
      mandal: values?.mandal,
      district: values?.district,
      state: values?.state,
      village: values?.village,
      location: values?.location,
      address_line1: values?.address_line1,
      address_line2: values?.address_line2,
      name: values?.name,
      phone: values?.phone,
      nature_of_relationship: values?.nature_of_relationship,
      user_id: 239,
      volunteer_id: volunteerInfo?.signup?.volunteer_id,
    };
    dispatch(updateVolunteerAdditionalInfo(payload)).then((action) => {
      if (updateVolunteerAdditionalInfo.fulfilled.match(action)) {
        message.success({
          content: "Updated successfully",
          duration: 5,
          style: {
            marginTop: "20px",
            marginBottom: "20px",
          },
        });
      }
    });
  };
  const submitInterestedActivties = (values) => {
    let user_id = userProfileData?.user_id;
    console.log("submitJoinVolunteerTabDetails", values);
    const payload = {
      volunteer_id: volunteerInfo?.signup?.volunteer_id,
      user_id: 239,
      intrested_activities_Ids:selectedActivitiesList
    };
    dispatch(updateVolunteerActivities(payload)).then((action) => {
      if (updateVolunteerActivities.fulfilled.match(action)) {
        message.success({
          content: "Updated successfully",
          duration: 5,
          style: {
            marginTop: "20px",
            marginBottom: "20px",
          },
        });
      }
    });
  };
  
  const submitAreaOfExpertiseForm = (values) => {
    let user_id = userProfileData?.user_id;
    console.log("submitJoinVolunteerTabDetails", values);
    const payload = {
      accept_terms_and_conditions:true,
      area_of_expertise:areaOfExpertiseTreeDataIds,
      volunteer_id: volunteerInfo?.signup?.volunteer_id,
      user_id: 239,
      is_final_save:false
      
    };
    dispatch(updateVolunteerActivities(payload)).then((action) => {
      if (updateVolunteerActivities.fulfilled.match(action)) {
        message.success({
          content: "Updated successfully",
          duration: 5,
          style: {
            marginTop: "20px",
            marginBottom: "20px",
          },
        });
      }
    });
  };
  const submitAddressSelfForm = (values) => {
    // const payload = {
    //   interface_lang_id: values?.interfaceLang,
    //   user_id: user?.user_id,
    // };
    // dispatch(selectingInterfaceLanguage(payload));
  };

  const transformData = (data) => {
    return data.reduce((acc, item) => {
      const [parentId, subId] = item.involvement_id.split(",");

      let involvement = acc.find(
        (involvement) => involvement.involvement_id === parentId
      );
      if (!involvement) {
        involvement = { involvement_id: parentId, sub_involvement_ids: [] };
        acc.push(involvement);
      }

      if (typeof subId === "string" && !isNaN(subId) && subId.trim() !== "") {
        if (subId) {
          involvement.sub_involvement_ids.push(subId);
        }
      }

      return acc;
    }, []);
  };
  const transformArea_of_expertise_Data = (data) => {
    return data.reduce((acc, item) => {
      const [parentId, subId] = item.area_of_expertise_id.split(",");

      let involvement = acc.find(
        (involvement) => involvement.area_of_expertise_id === parentId
      );
      if (!involvement) {
        involvement = { area_of_expertise_id: parentId, sub_area_of_expertise_ids: [] };
        acc.push(involvement);
      }

      if (typeof subId === "string" && !isNaN(subId) && subId.trim() !== "") {
        if (subId) {
          involvement.sub_area_of_expertise_ids.push(subId);
        }
      }

      return acc;
    }, []);
  };
  const onChangeTree = (value, label, extra) => {
    const transformed = transformData(
      value.map((val) => ({ involvement_id: val, sub_involvement_ids: [] }))
    );
    setInvolvedTreeDataIds(transformed);
    console.log("Formatted Value:", transformed);
  };
  const onChangeAreaOfExpertiseTree = (value, label, extra) => {
    const transformed = transformArea_of_expertise_Data(
      value.map((val) => ({ area_of_expertise_id: val, sub_area_of_expertise_ids: [] }))
    );
    setAreaOfExpertiseTreeDataIds(transformed);
    console.log("Formatted Value:", transformed);
  };
  const handleDOB = (value) => {
    console.log("value", value);
    if (value === null) {
      profileAboutYourSelf.setFieldsValue({
        age: "",
      });
      return;
    }
    var dob = new Date(moment(value));
    //calculate month difference from current date in time
    var month_diff = Date.now() - dob.getTime();

    //convert the calculated difference in date format
    var age_dt = new Date(month_diff);

    //extract year from date
    var year = age_dt.getUTCFullYear();

    //now calculate the age of the user
    var ageDiff = Math.abs(year - 1970);
    profileAboutYourSelf.setFieldsValue({
      age: ageDiff,
    });
  };
  const handleuChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    let user_id = userProfileData?.user_id;
    var payload = new FormData();

    // if (fileList?.[0]?.originFileObj) {
    // }
    // else {
    //   toast.error('Please Upload image');
    // }

    // if (newFileList?.length === 1 && newFileList?.[0]?.originFileObj) {

    //   if (newFileList?.[0]?.originFileObj) {
    //     const reader = new FileReader();
    //     reader.onload = function (e) {
    //       const binaryData = e.target.result;
    //       console.log('Binary data:', binaryData);
    //       // You can now use binaryData as needed, such as sending it to a server or processing it further
    //     };
    //     reader.readAsBinaryString(fileList?.[0]?.originFileObj);
    //   }

    //   payload.append("profileimage", fileList?.[0]?.originFileObj);
    //   dispatch(updateProfileUserPicture(payload))
    // }

    // if (newFileList?.length === 1 && newFileList?.[0]?.originFileObj) {
    //   const reader = new FileReader();
    //   reader.onload = function (e) {
    //     const binaryData = e.target.result;
    //     console.log('Binary data:', binaryData);

    //     const payload = new FormData();
    //    / Append the binary data to the FormData

    //     // Dispatch the action with the FormData containing the binary data
    //     dispatch(updateProfileUserPicture(payload));
    //   };
    //   reader.readAsBinaryString(newFileList?.[0]?.originFileObj);
    // }

    var data1 = new FormData();
    if (newFileList?.[0]?.originFileObj) {
      const file = newFileList[0]?.originFileObj;
      if (file) {
        const formData = new FormData();
        formData.append("profileimage", file);
        console.log("FormData:", formData); // Che

        dispatch(updateProfileUserPicture(formData));
      }
    }
  };
  const uploadButton = (
    <div style={{ position: "relative", top: "12vh" }}>
      <Button>
        <img src={uploadProfileImg} style={{ cursor: "pointer" }} />
        <input
          hidden
          accept="image/*"
          id="contained-button-file"
          multiple
          type="file"
          onChange={(e) => fileChangedHandler(e)}
        />
      </Button>
    </div>
  );
  const uploadButtonAadhar = (
    <div style={{ position: "relative", top: "12vh" }}></div>
  );
  const uploadButtonPanCard = (
    <div style={{ position: "relative", top: "12vh" }}></div>
  );

  const handlePreview = async (file) => {
    console.log("entered preview", file);
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    setFModalVisible(true);
    setImagePreview(src);
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const handleImageModelCancel = () => {
    // setIsModalVisible(false);
    setFModalVisible(false);
  };
  const handleOpenOTPModel = () => {
    const payload = {
      first_name: userProfileData?.user_profile?.firstname,
      mobile_number: userProfileData?.gmail_id,
    };

    // localStorage.setItem('userDetails', JSON.stringify(payload))
    // handleCancel();
    //  openingOTPlsModel(true);
    let gmail = userProfileData?.gmail_id;
    let user_id = userProfileData?.user_id;
    dispatch(sendOTPtoMail({ gmail, user_id }))
      .then((action) => {
        if (sendOTPtoMail.fulfilled.match(action)) {
          message.success({
            content: "OTP sent successfully",
            duration: 5, // Specify the duration in seconds
            style: {
              marginTop: "20px", // Adjust the margin if needed
              marginBottom: "20px", // Add margin at the bottom
            },
          });
        }
      })
      .catch((err) => {
        message.error({
          content: "Some thing went wrong",
          duration: 5,
          style: {
            marginTop: "20px",
            marginBottom: "20px",
          },
        });
      });
    setIsOTPModalOpen(true);
  };
  const handleCloseLoginModel = (data) => {
    setIsOTPModalOpen(false);
  };

  const handleRemove = (index) => {
    const newValues = [
      ...profileAboutYourSelf.getFieldValue("socialMediaAccounts"),
    ];
    setEmptyFieldIndexArr([]);
    // setDupIndex([])
    // setLengthError([])
    const fieldValueToRemove = newValues[index];
    if (!newValues?.includes("")) {
      setDisableAddBtn(false);
    }

    if (fieldValueToRemove === undefined) {
      const myupdated = newValues.filter((value) => value !== undefined);
      setSubLevelOldData(myupdated);
      profileAboutYourSelf.setFieldsValue({
        socialMediaAccounts: myupdated,
      });
      return;
    }
    setCurrentFormsList(newValues);
    setRemovingItem(fieldValueToRemove);
    if (fieldValueToRemove) {
      updateRemoveValuesFromCurrentList(newValues, fieldValueToRemove);
    }
  };
  const updateRemoveValuesFromCurrentList = (newValues, fieldValueToRemove) => {
    const data = newValues?.filter((value) => value !== fieldValueToRemove);
    const copyRemovedData = [...removedList];
    copyRemovedData.push(fieldValueToRemove);
    setRemovedList(copyRemovedData);
    const afterRemoveoldData = subLevelOldData?.filter(
      (value) => value !== fieldValueToRemove
    );
    setSubLevelOldData(afterRemoveoldData);
    // setSubLevelBackup(afterRemoveoldData);
    profileAboutYourSelf.setFieldsValue({
      socialMediaAccounts: data,
    });
    setCurrentFormsList(data);
    setRemovingItem(null);
  };
  const validateAadharUpload = (rule, value, callback) => {
    if (fileListForAddhar?.length === 0) {
      callback("Upload Aadhar Card");
    } else {
      callback();
    }
  };

  const dummyRequestForAAdhar = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const dummyRequestForPAN = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handlePreviewForAAdhar = async (file) => {
    console.log("entered preview", file);
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    // setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    // setPreviewTitle(
    //   file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    // );

    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }

    setFModalVisible(true);
    setImagePreview(src);
  };
  const handlePreviewForPANcard = async (file) => {
    // console.log("entered preview", file);
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    // setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    // setPreviewTitle(
    //   file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    // );

    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }

    setFModalVisible(true);
    setImagePreview(src);
  };
  const handleuChangeForAddhar = ({ fileList: newFileList }) => {
    setFileListForAddhar(newFileList);
    // if (currentAction === "update" && buData.aadhaar_url === null) {
    //   deleteform.resetFields();
    //   setDeletedAadharComments("aadharcard");
    //   setModalTitle("Uploading Image");
    //   setIsModalVisible(true);
    // }
    // if (newFileList?.length == 0) {
    //   // showAuditModal("is_primary_tag", true ? "true" : "false", "yest", '')fileListForAddhar fileListForPANcard
    //   if (currentAction === "update") {
    //     deleteform.resetFields();
    //     setDeletedAadharComments("aadharcard");
    //     setModalTitle("Current uploaded Aadhar");
    //     setIsModalVisible(true);
    //   }
    // }
  };
  const handleuChangeForPANcard = ({ fileList: newFileList }) => {
    setFileListForPANcard(newFileList);
    // if (currentAction === "update" && buData.pancard_url === null) {
    //   deleteform.resetFields();
    //   setDeletedPanComments("pancard_number");
    //   setModalTitle("Uploading Image");
    //   setIsModalVisible(true);
    // }
    // if (newFileList?.length == 0) {
    //   if (currentAction === "update") {
    //     deleteform.resetFields();
    //     setDeletedPanComments("pancard_number");
    //     setModalTitle("Current uploaded Pancard");
    //     setIsModalVisible(true);
    //   }
    // }
  };
  const transformInput = (value) => {
    return value ? value.toUpperCase() : value;
  };

  const fileChangedHandler = async (event) => {
    const files = event.target.files;
    var formdata = new FormData();

    formdata.append("profileimage", files[0]);
    let user_id = userProfileData?.user_id ?? 239;
    // dispatch(updateProfileUserPicture(formData));
    var requestOptions = {
      method: "POST",
      body: formdata,
    };

    fetch(`${BaseUrl}/user/profileimage?user_id=${user_id}`, requestOptions)
      .then((response) => response.json())
      .then(async (res) => {
        console.log(res);
        if (res?.profile_image_url) dispatch(getUserProfileData(user_id));
        else console.error("something went wrong while uploading user profile");
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const kycfilechangehandler = async (event, type) => {
    let user_id = userProfileData?.user_id ?? 239
    const files = event.target.files;
    if (files) {
      var formdata = new FormData();

      formdata.append("kycimage", files[0]);
      var requestOptions = {
        method: "POST",
        body: formdata,
      };
      fetch(
        `${BaseUrl}/user/kycimage?user_id=${user_id}&kyc_type=${type}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((res) => {
          if (res) {
             console.log('res',res)
             if(type === 'Aadhar') setAadharImgId(res?.image_id)
              if(type === 'Pan') setPancardImgId(res?.image_id)
            dispatch(getVolunteerTotalInfo());
          }
          else{           
             console.error("something went wrong while uploading user profile");
            }

        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  const onImageRemove = async (value) => {
    let user_id = userProfileData?.user_id;
    const result = await axios.delete(
      `${BaseUrl}/user/kycimage?image_id=${value}`
    );
    if (result.data?.is_success) {
      getVolunteerTotalInfo()
    }
    else {
      console.error("something went wrong while uploading user profile");
    }
  };


  const onChange = (checkedValues) => {
    setSelectedActivitiesList(checkedValues)
    console.log('checked = ', checkedValues);
  };

 const handleAggreeAndTerms = (e) =>{
  console.log(e.target.checked)
 }
 
const backToProfile = () =>{
  navigate(`/profile`)
}
  return (
    <>
      <Row style={{margin:'20px 0px'}}>
          <Col offset={6}> <span onClick={backToProfile} style={{cursor:'pointer'}}><img src={backIcon} height={25} width={25}/>&nbsp; back to Profile</span> </Col>
        
        </Row>
      <Row justify="space-evenly">
        <Col span={20}>
          <Tabs tabPosition="left">
            <TabPane
              tab={
                selectedInterfaceLanguage && (
                  <TranslateWord
                    key={"JOIN_VOULUNTEER"}
                    text={"JOIN_VOULUNTEER"}
                    lang={selectedInterfaceLanguage}
                  />
                )
              }
              key="About your self"
            >
              <div style={{ marginBottom: "3vh" }}>
                <h1>
                  {selectedInterfaceLanguage && (
                    <TranslateWord
                      key={"JOIN_VOULUNTEER"}
                      text={"JOIN_VOULUNTEER"}
                      lang={selectedInterfaceLanguage}
                      headingStyle={true}
                    />
                  )}
                </h1>
              </div>
              <div>
                <Form
                  form={joinVolunteerForm}
                  layout="vertical"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={submitJoinVolunteerTabDetails}
                >
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={18} xxl={18}>
                      <Form.Item
                        name="full_name"
                        label={
                          selectedInterfaceLanguage && (
                            <TranslateWord
                              key={"FIRSTNAME"}
                              text={"FIRSTNAME"}
                              lang={selectedInterfaceLanguage}
                            />
                          )
                        }
                      >
                        <Input name="full_name" />
                      </Form.Item>
                      {/* <Form.Item name="lastname" label={selectedInterfaceLanguage && (
                                 <TranslateWord
                                 key={"LASTNAME"}
                                 text={"LASTNAME"}
                                 lang={selectedInterfaceLanguage}
                                
                               />
                                )}>
                      <Input name="lastname" />
                    </Form.Item>
                    <Form.Item name="display_name" label={selectedInterfaceLanguage && (
                                 <TranslateWord
                                 key={"DISPLAYNAME"}
                                 text={"DISPLAYNAME"}
                                 lang={selectedInterfaceLanguage}
                                
                               />
                                )}>
                      <Input name="display_name" />
                    </Form.Item> */}
                      <Form.Item
                        name="mobile_number"
                        label={
                          selectedInterfaceLanguage && (
                            <TranslateWord
                              key={"MOBILENUMBER"}
                              text={"MOBILENUMBER"}
                              lang={selectedInterfaceLanguage}
                            />
                          )
                        }
                      >
                        <Input name="mobile_number" />
                      </Form.Item>
                      <Row>
                        <Col span={18}>
                          <Form.Item
                            name="email"
                            label={
                              selectedInterfaceLanguage && (
                                <TranslateWord
                                  key={"EMAIL_ID"}
                                  text={"EMAIL_ID"}
                                  lang={selectedInterfaceLanguage}
                                />
                              )
                            }
                          >
                            <Input name="email" />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Button
                            style={{ position: "relative", top: "5vh" }}
                            onClick={handleOpenOTPModel}
                            type="link"
                          >
                            {selectedInterfaceLanguage && (
                              <TranslateWord
                                key={"SEND_OTP"}
                                text={"SEND_OTP"}
                                lang={selectedInterfaceLanguage}
                              />
                            )}
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={18} xxl={18}>
                          <Form.Item
                            className="my-select-container"
                            name="volunteer_involvements"
                            label={
                              selectedInterfaceLanguage && (
                                <TranslateWord
                                  key={"HOW_WOULD_LIKE_TO_BE_INVOLVED"}
                                  text={"HOW_WOULD_LIKE_TO_BE_INVOLVED"}
                                  lang={selectedInterfaceLanguage}
                                />
                              )
                            }
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Select Desk Name",
                            //   },
                            // ]}
                          >
                            <TreeSelect
                              name="volunteer_involvements"
                              treeData={involvedTreeData}
                              treeCheckable={true}
                              // showCheckedStrategy={SHOW_PARENT}
                              placeholder="Please select "
                              //  treeCheckStrictly={true}
                              showArrow={true}
                              onChange={onChangeTree}
                            >
                              {involvedTreeData?.map((parent) => (
                                <TreeNode
                                  key={parent.value}
                                  title={parent.label}
                                  value={parent.value}
                                >
                                  {parent.children.map((child) => (
                                    <TreeNode
                                      key={child.value}
                                      title={child.label}
                                      value={child.value}
                                      isLeaf
                                    />
                                  ))}
                                </TreeNode>
                              ))}
                            </TreeSelect>
                          </Form.Item>
                        </Col>
                      </Row>
                      {/* <Row>
                      <Col span={18}>
                        <Form.Item name="date_of_birth" label={selectedInterfaceLanguage && (
                                 <TranslateWord
                                 key={"DOB"}
                                 text={"DOB"}
                                 lang={selectedInterfaceLanguage}
                                
                               />
                                )}>                         
                          <DatePicker
                            picker="date"
                            placeholder="Enter DOB"
                            format={dateFormat}
                            style={{ width: "24vw" }}
                            onChange={handleDOB}                          
                          />
                      
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item name="age" label={selectedInterfaceLanguage && (
                                 <TranslateWord
                                 key={"AGE"}
                                 text={"AGE"}
                                 lang={selectedInterfaceLanguage}
                                
                               />
                                )}>
                          <Input name="age" readOnly />
                        </Form.Item>
                      </Col>
                      <span
                        style={{
                          fontSize: "11px",
                          position: "relative",
                          top: "-3vh",
                        }}
                      >
                        Minimum age of users to post content (user generated
                        content) to be 13 years.
                      </span>
                    </Row> */}
                      {/* <Row gutter={5}>
                      <Col span={12}>
                        <Form.Item name="gender" label={selectedInterfaceLanguage && (
                                 <TranslateWord
                                 key={"GENDER"}
                                 text={"GENDER"}
                                 lang={selectedInterfaceLanguage}
                                
                               />
                                )}>
                          <Select placeholder="Select Gender">
                            {genderData?.map((gender) => (
                              <Option key={gender.gender} value={gender.gender}>
                                {gender.gender}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item name="marital_status" label={selectedInterfaceLanguage && (
                                 <TranslateWord
                                 key={"MARITAL_STATUS"}
                                 text={"MARITAL_STATUS"}
                                 lang={selectedInterfaceLanguage}
                                
                               />
                                )}>
                          <Select placeholder="Select Marital Status">
                            {maritalStatusData?.map((status) => (
                              <Option
                                key={status.marital_status}
                                value={status.marital_status}
                              >
                                {status.marital_status}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row> */}
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          style={{ float: "right" }}
                        >
                          {selectedInterfaceLanguage && (
                            <TranslateWord
                              key={"UPDATE"}
                              text={"UPDATE"}
                              lang={selectedInterfaceLanguage}
                            />
                          )}
                        </Button>
                      </Form.Item>
                    </Col>
                    
                  </Row>
                </Form>
              </div>
            </TabPane>
            <TabPane
              tab={
                selectedInterfaceLanguage && (
                  <TranslateWord
                    key={"INTERESTED_ACTIVITIES"}
                    text={"INTERESTED_ACTIVITIES"}
                    lang={selectedInterfaceLanguage}
                  />
                )
              }
              key="INTERESTED_ACTIVITIES"
            >
              <div style={{marginBottom:'20px'}}>
                <h1>
                  {selectedInterfaceLanguage && (
                    <TranslateWord
                      key={"INTERESTED_ACTIVITIES"}
                      text={"INTERESTED_ACTIVITIES"}
                      lang={selectedInterfaceLanguage}
                      headingStyle={true}
                    />
                  )}
                </h1>
              </div>
          
              <div>
                <Form
                  form={profileAboutYourSelf}
                  layout="vertical"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={submitInterestedActivties}
                >
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={22} xxl={18}>
                      {/* <Form.Item
                        name="location"
                        label= { <h1>
                          {selectedInterfaceLanguage && (
                            <TranslateWord
                              key={"INTERESTED_ACTIVITIES"}
                              text={"INTERESTED_ACTIVITIES"}
                              lang={selectedInterfaceLanguage}
                              headingStyle={true}
                            />
                          )}
                        </h1>}
                      > */}
                      <Checkbox.Group
                      options={converteActivitiesData}    
                      defaultValue={volunteerInfo?.intrested_activities?.intrested_activities_Ids}      
                      
                      onChange={onChange}
                    />
                      {/* </Form.Item> */}

                    
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          style={{ float: "right" }}
                        >
                          {selectedInterfaceLanguage && (
                            <TranslateWord
                              key={"UPDATE"}
                              text={"UPDATE"}
                              lang={selectedInterfaceLanguage}
                            />
                          )}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </TabPane>
            <TabPane
              tab={
                selectedInterfaceLanguage && (
                  <TranslateWord
                    key={"DETAILS_OF_SELF"}
                    text={"DETAILS_OF_SELF"}
                    lang={selectedInterfaceLanguage}
                  />
                )
              }
              key="DETAILS_OF_SELF"
            >
              <div style={{ marginBottom: "3vh" }}>
                <h1>
                  {selectedInterfaceLanguage && (
                    <TranslateWord
                      key={"DETAILS_OF_SELF"}
                      text={"DETAILS_OF_SELF"}
                      lang={selectedInterfaceLanguage}
                      headingStyle={true}
                    />
                  )}
                </h1>
              </div>
              <div>
                <Form
                  form={joinVolunteerForm}
                  layout="vertical"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={submitJoinVolunteerTabDetails}
                >
                 
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <div style={{ marginLeft: "4vw", marginBottom: "5vh" }}>                        
                        Upload Profile
                      </div>                   

                      <div style={{ textAlign: "center" }}>
                        <div>
                          <img
                            src={volunteerInfo?.details_of_self?.profile_image_url}
                            width={150}
                            height={150}
                          />
                        </div>

                        <div>
                          <label for="file-upload" class="custom-file-upload">
                            Upload Image
                          </label>
                          <input
                            id="file-upload"
                            type="file"
                            accept="image/*"
                            onChange={(e) => fileChangedHandler(e)}
                            multiple
                            style={{ display: "none" }}
                          />
                        </div>
                      </div>

                      <div className="fileViewModel">
                        <Modal
                          title="Image view"
                          visible={fModalVisible}
                          onCancel={handleImageModelCancel}
                          footer={null}
                          width={500}
                        >
                          <div className="modelIcon "></div>                          
                          <img
                            src={imagePreview}
                            alt="Girl in a jacket"
                            width="450"
                            height="410"
                          />
                        </Modal>
                      </div>
                    </Col>
                    </Row>  
                   
                   
                    
                 <Row gutter={5}>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <Form.Item name="date_of_birth" label={selectedInterfaceLanguage && (
                                 <TranslateWord
                                 key={"DOB"}
                                 text={"DOB"}
                                 lang={selectedInterfaceLanguage}
                                
                               />
                                )}>                         
                          <DatePicker
                            picker="date"
                            placeholder="Enter DOB"
                            format={dateFormat}
                            style={{ width: "100%" }}
                            onChange={handleDOB}                          
                          />
                      
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        name="religion"
                        label={
                          selectedInterfaceLanguage && (
                            <TranslateWord
                              key={"RELIGION"}
                              text={"RELIGION"}
                              lang={selectedInterfaceLanguage}
                            />
                          )
                        }
                      >
                        <Input name="religion" />
                      </Form.Item>
                      </Col>
                     
                    </Row> 
                    <Row gutter={5}>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        name="caste"
                        label={
                          selectedInterfaceLanguage && (
                            <TranslateWord
                              key={"CASTE"}
                              text={"CASTE"}
                              lang={selectedInterfaceLanguage}
                            />
                          )
                        }
                      >
                        <Input name="caste" />
                      </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                      <Form.Item
                      name="known_language_ids"
                      label={selectedInterfaceLanguage && (
                        <TranslateWord
                        key={"LANGUAGES_KNOW"}
                        text={"LANGUAGES_KNOW"}
                        lang={selectedInterfaceLanguage}
                        
                      />
                       )}
                    >
                      <Select placeholder="Select">
                        {feedLangListData?.map((feed) => (
                          <Option
                            key={feed?.newsfeed_lang_id}
                            value={feed?.newsfeed_lang_id}
                          >
                            {feed?.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                      </Col>
                     
                    </Row> 
                       <Row gutter={5}>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <Form.Item name="gender" label={selectedInterfaceLanguage && (
                                 <TranslateWord
                                 key={"GENDER"}
                                 text={"GENDER"}
                                 lang={selectedInterfaceLanguage}
                                
                               />
                                )}>
                          <Select placeholder="Select Gender">
                            {genderData?.map((gender) => (
                              <Option key={gender.gender} value={gender.gender}>
                                {gender.gender}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <Form.Item name="marital_status" label={selectedInterfaceLanguage && (
                                 <TranslateWord
                                 key={"MARITAL_STATUS"}
                                 text={"MARITAL_STATUS"}
                                 lang={selectedInterfaceLanguage}
                                
                               />
                                )}>
                          <Select placeholder="Select Marital Status">
                            {maritalStatusData?.map((status) => (
                              <Option
                                key={status.marital_status}
                                value={status.marital_status}
                              >
                                {status.marital_status}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={5}>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                      <Form.Item
                      name="educational_qualification_id"
                      label={selectedInterfaceLanguage && (
                        <TranslateWord
                        key={"EDUCATIONAL_QUALIFICATIONS"}
                        text={"EDUCATIONAL_QUALIFICATIONS"}
                        lang={selectedInterfaceLanguage}
                        
                      />
                       )}
                    >
                      <Select placeholder="Select">
                        {educationQualificationData?.map((qualification) => (
                          <Option
                            key={qualification.eq_id}
                            value={qualification.eq_id}
                          >
                            {qualification.eq_name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                      <Form.Item name="work_experience_id" label="Occupation">
                      <Select placeholder="Select">
                        {workExperienceData?.map((occ) => (
                          <Option key={occ.we_id} value={occ.we_id}>
                            {occ.we_name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                      </Col>
                    </Row>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          style={{ float: "right" }}
                        >
                          {selectedInterfaceLanguage && (
                            <TranslateWord
                              key={"UPDATE"}
                              text={"UPDATE"}
                              lang={selectedInterfaceLanguage}
                            />
                          )}
                        </Button>
                      </Form.Item>
                   
                  
                  
                </Form>
              </div>
            </TabPane>

            <TabPane
              tab={
                selectedInterfaceLanguage && (
                  <TranslateWord
                    key={"ADDITIONAL_INFORMATION"}
                    text={"ADDITIONAL_INFORMATION"}
                    lang={selectedInterfaceLanguage}
                  />
                )
              }
              key="Additional Information"
            >
              <div style={{ marginBottom: "3vh" }}>
                <h1>
                  {selectedInterfaceLanguage && (
                    <TranslateWord
                      key={"ADDITIONAL_INFORMATION"}
                      text={"ADDITIONAL_INFORMATION"}
                      lang={selectedInterfaceLanguage}
                      headingStyle={true}
                    />
                  )}
                </h1>
              </div>
              <Form
                form={additionalInformationForm}
                layout="vertical"
                initialValues={{
                  remember: true,
                }}
                onFinish={submitAdditionalInformationForm}
              >
                <Row>
                  <Col span={24}>
                    <Row gutter={5}>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <Form.Item
                          name="address_line1"
                          label={
                            selectedInterfaceLanguage && (
                              <TranslateWord
                                key={"VOL_ADDRESS_LINE1"}
                                text={"VOL_ADDRESS_LINE1"}
                                lang={selectedInterfaceLanguage}
                              />
                            )
                          }
                        >
                          <Input name="address_line1" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <Form.Item
                          name="address_line2"
                          label={
                            selectedInterfaceLanguage && (
                              <TranslateWord
                                key={"VOL_ADDRESS_LINE2"}
                                text={"VOL_ADDRESS_LINE2"}
                                lang={selectedInterfaceLanguage}
                              />
                            )
                          }
                        >
                          <Input name="address_line2" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={5}>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <Form.Item
                          name="location"
                          label={
                            selectedInterfaceLanguage && (
                              <TranslateWord
                                key={"LOCATION"}
                                text={"LOCATION"}
                                lang={selectedInterfaceLanguage}
                              />
                            )
                          }
                        >
                          <Input name="location" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={5}>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <Form.Item
                          name="state"
                          label={
                            selectedInterfaceLanguage && (
                              <TranslateWord
                                key={"STATE"}
                                text={"STATE"}
                                lang={selectedInterfaceLanguage}
                              />
                            )
                          }
                        >
                          <Input name="state" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <Form.Item
                          name="district"
                          label={
                            selectedInterfaceLanguage && (
                              <TranslateWord
                                key={"DISTRICT"}
                                text={"DISTRICT"}
                                lang={selectedInterfaceLanguage}
                              />
                            )
                          }
                        >
                          <Input name="district" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={5}>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <Form.Item
                          name="mandal"
                          label={
                            selectedInterfaceLanguage && (
                              <TranslateWord
                                key={"MANDAL"}
                                text={"MANDAL"}
                                lang={selectedInterfaceLanguage}
                              />
                            )
                          }
                        >
                          <Input name="mandal" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <Form.Item
                          name="village"
                          label={
                            selectedInterfaceLanguage && (
                              <TranslateWord
                                key={"VILLAGE_AREA_LOCALITY"}
                                text={"VILLAGE_AREA_LOCALITY"}
                                lang={selectedInterfaceLanguage}
                              />
                            )
                          }
                        >
                          <Input name="village" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <div style={{ marginBottom: "3vh" }}>
                      <h1>
                        {selectedInterfaceLanguage && (
                          <TranslateWord
                            key={"EMG_POINT_OF_CONTACT"}
                            text={"EMG_POINT_OF_CONTACT"}
                            lang={selectedInterfaceLanguage}
                            headingStyle={true}
                          />
                        )}
                      </h1>
                    </div>
                    <Row gutter={5}>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <Form.Item
                          name="name"
                          label={
                            selectedInterfaceLanguage && (
                              <TranslateWord
                                key={"NAME"}
                                text={"NAME"}
                                lang={selectedInterfaceLanguage}
                              />
                            )
                          }
                        >
                          <Input name="name" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <Form.Item
                          name="phone"
                          label={
                            selectedInterfaceLanguage && (
                              <TranslateWord
                                key={"MOBILE"}
                                text={"MOBILE"}
                                lang={selectedInterfaceLanguage}
                              />
                            )
                          }
                        >
                          <Input name="phone" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={5}>
                      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <Form.Item
                          name="nature_of_relationship"
                          label={
                            selectedInterfaceLanguage && (
                              <TranslateWord
                                key={"NATURE_OF_RELATIONSHIP"}
                                text={"NATURE_OF_RELATIONSHIP"}
                                lang={selectedInterfaceLanguage}
                              />
                            )
                          }
                        >
                          <Input name="nature_of_relationship" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ float: "right" }}
                      >
                        Update
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </TabPane>
            <TabPane
              tab={
                selectedInterfaceLanguage && (
                  <TranslateWord
                    key={"KYC"}
                    text={"KYC"}
                    lang={selectedInterfaceLanguage}
                  />
                )
              }
              key="KYC"
            >
              <div>
                <h1>
                  {selectedInterfaceLanguage && (
                    <TranslateWord
                      key={"KYC"}
                      text={"KYC"}
                      lang={selectedInterfaceLanguage}
                      headingStyle={true}
                    />
                  )}
                </h1>
              </div>
              <div>
                <Form
                  form={volunteerKYC}
                  layout="vertical"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={submitJoinVolunteerTabKYC}
                >
                  <Row gutter={16}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        name="aadhaar_number"
                        label={
                          selectedInterfaceLanguage && (
                            <TranslateWord
                              key={"AADHAR_NUMBER"}
                              text={"AADHAR_NUMBER"}
                              lang={selectedInterfaceLanguage}
                            />
                          )
                        }
                        rules={[
                          {
                            required: true,
                            message: "Enter Aadhar Number",
                          },
                          {
                            whitespace: true,
                            message: "Aadhar Number can not be empty",
                          },
                          {
                            max: 12,
                            message: "Aadhar Number should be 12 digits",
                          },
                          {
                            min: 12,
                            message: "Aadhar Number should be 12 digits",
                          },
                          // {
                          //   pattern: new RegExp(/^(\d{4}-){3}\d{4}$/),
                          //   message: "Numbers only allowed",
                          // },
                          // {
                          //   pattern: new RegExp(/^\S+$/),
                          //   message: "Spaces not allowed",
                          // },
                          // {
                          //   validator: validateAadhaarNumber,
                          // },
                        ]}
                      >
                        <Input
                          name="aadhaar_number"
                          autoComplete="off"
                          placeholder="1111222233334444"
                          // onChange={chandleChange}

                          // ref={myaadharRef}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                      <div style={{ textAlign: "center" }}>
                        <div>
                          {volunteerInfo?.kyc_images?.aadhar
                            ?.image_url && (
                            <img
                              src={
                                volunteerInfo?.kyc_images?.aadhar
                            ?.image_url
                              }
                              width={100}
                              height={100}
                            />
                          )}
                        </div>

                        <div>
                          <label
                            for="file-upload-aadhar"
                            class="custom-file-upload"
                          >
                            {selectedInterfaceLanguage && (
                              <TranslateWord
                                key={"UPLOAD_AADHAR"}
                                text={"UPLOAD_AADHAR"}
                                lang={selectedInterfaceLanguage}
                              />
                            )}
                          </label>
                          {volunteerInfo?.kyc_images?.aadhar
                            ?.image_url && (
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                onImageRemove(
                                  volunteerInfo?.kyc_images?.aadhar
                            ?.image_id
                                    
                                );
                              }}
                            >
                              <img src={deleteIcon} width={20} height={20} />
                            </span>
                          )}

                          <input
                            id="file-upload-aadhar"
                            type="file"
                            accept="image/*"
                            onChange={(e) => kycfilechangehandler(e, "Aadhar")}
                            multiple
                            style={{ display: "none" }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                      <Form.Item
                        name="pancard_number"
                        label={
                          selectedInterfaceLanguage && (
                            <TranslateWord
                              key={"PANCARD"}
                              text={"PANCARD"}
                              lang={selectedInterfaceLanguage}
                            />
                          )
                        }
                        getValueFromEvent={(e) =>
                          transformInput(e.target.value)
                        }
                        rules={[
                          // {
                          //   required: true,
                          //   message: "Enter PAN Number",
                          // },
                          // { whitespace: true, message: "PAN Number can not be empty" },
                          {
                            pattern: new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]$/),
                            message: "PAN Number format should be AAAAA1234D ",
                          },

                          // {
                          //   max: 10,
                          //   message: "PAN Number must be less than 10 characters",
                          // },
                          //  { validator: validateSubCategoryNameExists } ,
                        ]}
                      >
                        <Input
                          name="pancard_number"
                          autoComplete="off"
                          placeholder="Enter PAN Number"
                          maxLength={10}
                          // onChange={chandleChange}
                          // onKeyUp={convertToUpperCase}

                          ref={mypancardRef}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                      <div style={{ textAlign: "center" }}>
                        <div>
                          {volunteerInfo?.kyc_images?.pan
                            ?.image_url && (
                            <img
                              src={
                                volunteerInfo?.kyc_images?.pan
                            ?.image_url
                              }
                              width={100}
                              height={100}
                            />
                          )}
                        </div>

                        <div>
                          <label
                            for="file-upload-pan"
                            class="custom-file-upload"
                          >
                            {selectedInterfaceLanguage && (
                              <TranslateWord
                                key={"UPLOAD_PANCARD"}
                                text={"UPLOAD_PANCARD"}
                                lang={selectedInterfaceLanguage}
                              />
                            )}
                          </label>
                          {volunteerInfo?.kyc_images?.pan
                            ?.image_url && (
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                onImageRemove(
                                  volunteerInfo?.kyc_images?.pan
                            ?.image_id
                                );
                              }}
                            >
                              <img src={deleteIcon} width={20} height={20} />
                            </span>
                          )}

                          <input
                            id="file-upload-pan"
                            type="file"
                            accept="image/*"
                            onChange={(e) => kycfilechangehandler(e, "Pan")}
                            multiple
                            style={{ display: "none" }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ float: "right" }}
                    >
                      {selectedInterfaceLanguage && (
                        <TranslateWord
                          key={"UPDATE"}
                          text={"UPDATE"}
                          lang={selectedInterfaceLanguage}
                        />
                      )}
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </TabPane>
            <TabPane
              tab={
                selectedInterfaceLanguage && (
                  <TranslateWord
                    key={"AREA_OF_EXPERTISE"}
                    text={"AREA_OF_EXPERTISE"}
                    lang={selectedInterfaceLanguage}
                  />
                )
              }
              key="Bank Details"
            >
              {/* <div>
                <h1>
                  {selectedInterfaceLanguage && (
                    <TranslateWord
                      key={"AREA_OF_EXPERTISE"}
                      text={"AREA_OF_EXPERTISE"}
                      lang={selectedInterfaceLanguage}
                      headingStyle={true}
                    />
                  )}
                </h1>
              </div> */}
              <div>
                <Form
                  form={profileAboutYourSelf}
                  layout="vertical"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={submitAreaOfExpertiseForm}
                >
                  <Row>
                    <Col span={24}>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={18} xl={18} xxl={18}>
                          <Form.Item
                            className="my-select-container"
                            name="volunteer_involvements"
                            label={
                             <h1>

                              { selectedInterfaceLanguage && (
                                <TranslateWord
                                  key={"AREA_OF_EXPERTISE"}
                                  text={"AREA_OF_EXPERTISE"}
                                  lang={selectedInterfaceLanguage}
                                  headingStyle={true}
                                />
                              )}
                             </h1>
                            }
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Select Desk Name",
                            //   },
                            // ]}
                          >
                            <TreeSelect
                              name="volunteer_involvements"
                              treeData={areaOfExpertiseTreeData}
                              treeCheckable={true}
                              // showCheckedStrategy={SHOW_PARENT}
                              placeholder="Please select "
                              //  treeCheckStrictly={true}
                              showArrow={true}
                              onChange={onChangeAreaOfExpertiseTree}
                            >
                              {areaOfExpertiseTreeData?.map((parent) => (
                                <TreeNode
                                  key={parent.value}
                                  title={parent.label}
                                  value={parent.value}
                                >
                                  {parent.children.map((child) => (
                                    <TreeNode
                                      key={child.value}
                                      title={child.label}
                                      value={child.value}
                                      isLeaf
                                    />
                                  ))}
                                </TreeNode>
                              ))}
                            </TreeSelect>
                          </Form.Item>
                        </Col>
                      </Row>
                     <Row>
                      <Col  span={18}> <Checkbox onChange={handleAggreeAndTerms}>I have read and agree to the Volunteer terms and conditions</Checkbox></Col>
                     </Row>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          style={{ float: "right" }}
                        >
                          {selectedInterfaceLanguage && (
                            <TranslateWord
                              key={"UPDATE"}
                              text={"UPDATE"}
                              lang={selectedInterfaceLanguage}
                            />
                          )}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </TabPane>
          </Tabs>
        </Col>
      </Row>

      {isOTPModalOpen && (
        <OTP
          openFlag={isOTPModalOpen}
          closeLoginModel={handleCloseLoginModel}
          sendingFrom="profileSettings"
          optType="gmail"
        />
      )}
    </>
  );
};

export default JoinVolunteer;
