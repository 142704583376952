import React, { useEffect, useState } from "react";
import {
  TranslateTeluguWords,
  TranslateEnglishWords,
  teluguFontStyle,
  teluguFontStyleHeading,
  largeTextStyle,
} from "../../Translation/Words";
import { useSelector } from "react-redux";
let translatedWord = null;

let langId = [];

const TranslateWord = ({ text, headingStyle, lang ,text_lg}) => {
  // console.log('translate',text,lang)
  const [translateWord, setTranslateWord] = useState(null);
  const { data: interfaceLanguagesData, selectedInterfaceLanguage } = useSelector(
    (state) => state.interfaceLanguageList
  );
  // console.log('translate',selectedInterfaceLanguage)
  useEffect(() => {
    if (lang?.name === "తెలుగు" || lang?.name === "Telugu") {
      translatedWord = TranslateTeluguWords?.filter((word) => {
        if (word?.value === text) {
          return word;
        }
      });
      setTranslateWord(translatedWord);
    }
    if (lang?.name === "English") {
      translatedWord = TranslateEnglishWords?.filter((word) => {
        if (word?.value === text) {
          return word;
        }
      });
      setTranslateWord(translatedWord);
    }
  }, [text, lang]);
  return (
    <>
      <span
        //  style={lang?.name === "తెలుగు" ? (headingStyle ? teluguFontStyleHeading : teluguFontStyle) : {}}
         style={lang?.name === "తెలుగు" ? (headingStyle ? teluguFontStyleHeading : text_lg ? largeTextStyle : teluguFontStyle) : {}}

        
        
        dangerouslySetInnerHTML={{ __html: translateWord?.[0]?.lable }}></span>
    </>
  );
};
export default TranslateWord;
