export const TranslateTeluguWords = [
    {lable:'‌డౌన్‌లోడ్ యాప్',value:'DOWNLOADAPPON'},
    {lable:'త్వరలో వస్తోంది',value:'COMINGSOON'},
    {lable:'సైన్ ఇన్',value:'SIGNIN'},
    {lable:'వెతకండి',value:'SEARCH'},
    {lable:'అన్ని',value:'ALL'},
    {lable:'మరిన్ని',value:'MORE'},
    {lable:'అన్నీ వీక్షించండి',value:'VIEWALL'},
    {lable:'షేర్ చేయండి',value:'SHARE'},
    {lable:'తాజా పోల్స్',value:'LATESTPOLLS'},
    {lable:'సమర్పించండి',value:'SUBMIT'},
    {lable:'మునుపటి పోల్స్',value:'PREVIOUSPOLLS'},
    {lable:'ఇప్పుడే   చేరండి',value:'JOINNOW'},
    {lable:'పోలిటికోస్‌ తాజా వార్తలు',value:'LATESTNEWSFROMPOLITIKOS'},
    {lable:'పోలిటికోస్‌ నుంచి మరిన్ని',value:'MOREFORMTHEPOLITIKOS'},
    {lable:'మీ కోసం',value:'FORYOU'},
    {lable:'హోమ్',value:'HOME'},
    {lable:'ట్రెండింగ్',value:'TRENDING'},
    {lable:'రాజకీయాలు',value:'POLITICS'},
    {lable:'వినోదం',value:'ENTERTAINMENT'},
    {lable:'క్రీడలు',value:'SPORTS'},
    {lable:'వాణిజ్యం',value:'BUSINESS'},
    {lable:'సాంకేతికం',value:'TECHNOLOGY'},
    {label:'లైవ్ టివి', value:'LIVETV'},
    {lable:'బ్రేకింగ్ న్యూస్',value:'BREAKINGNEWS'},
    {lable:'అనుసరించవద్దు',value:'UNFOLLOW'},
    {lable:'అనుసరించండి',value:'FOLLOW'},
    {lable:'పోస్ట్',value:'POSTS'},
    {lable:'అనుచరులు',value:'FOLLOWERS'},
    {lable:'అనుసరిస్తున్నవి',value:'FOLLOWING'},
    {lable:'యూజర్',value:'USER'},
    {lable:'మీ గురించి',value:'ABOUT_YOUR-SELF'},
    {lable:'సమర్పణలు',value:'SUBMISSIONS'},
    {lable:'వాలెట్',value:'WALLET'},
    {lable:'అప్‌లోడ్ ప్రొఫైల్‌',value:'UPLOAD_PROFILE'},
    {lable:'అప్‌లోడ్ ఫోటో',value:'UPLOAD_PHOTO'},
    {lable:'తాజావి',value:'LATEST'},
    {lable:'ప్రజాదరణ పొందినవి',value:'POPULAR'},
    {lable:'ఎక్కువగా చర్చించినవి',value:'MOSTDISCUSSED'},
    {lable:'లెన్స్',value:'LENS'},
    {lable:'కథ',value:'STORY'},
    {lable:'లింకులు',value:'LINKS'},
    {lable:'మా గురించి',value:'ABOUTUS'},
    {lable:'ట్యాగ్‌లు',value:'TAGS'},   
    // {lable:'కేటగిరీలు',value:'CATEGORIES'},   
    {lable:'సంబంధిత కథ',value:'RELATED_STORY'},
    {lable:'తిరస్కరించిది',value:'REJECTED'},   
    {lable:'సవరించి మళ్లీ పంపండి',value:'RETURNED'},   
    {lable:'ప్రచురించబడింది',value:'PUBLISHED'},   
    {lable:'ఇంకా ముసాయిదా',value:'DRAFT'},   
    {lable:'తిరస్కరించబడటానికి కారణం',value:'REASON_FOR_REJECTED'},
    {lable:'తిరిగి రావడానికి కారణం',value:'REASON_FOR_RETURNED'},
    {lable:'కథ ID',value:'STORY_ID'},
    {lable:'ఆమోదం కోసం వేచి ఉంది',value:'WAITING_FOR_APPROVAL'},
    {lable:'ప్రొఫైల్',value:'PROFILE'},
    {lable:'సైన్‌ఔట్‌',value:'LOGOUT'},
    {lable:'సెట్టింగ్‌లు',value:'SETTINGS'},
    {lable:"ఫాక్స్",value:'FAQs'},
    {lable:'పరిష్కరించబడింది',value:'RESOLVED'},
    {lable:'సమీక్షలో ఉంది',value:'UNDER_REVIEW'},
    {lable:'TELUGE',value:'POSTED_FOR_SINGNATURE'},  
    {lable:'సంబంధిత అధికారులకు పంపారు',value:'SENT_TO_AUTH_CONCERNED'},


    {lable:'ప్ర‌త్యేక‌ క‌థ‌నం (ఫీచ‌ర్‌)',value:'FEATURES'},
    {lable:'మాాతో భాగస్వామ్యం కండి',value:'PARTNERWITHUS'},
    {lable:'కాల్ ఫర్ పైలట్',value:'CALLFORPILOTS'},
    {lable:'విధానాలు',value:'POLICIES'},
    {lable:'డిస్క్లైమర్',value:'DISCLAIMER'},
    {lable:'ఎస్ఐఐ ఎండబ్ల్యు',value:'SIIMW'},
    {lable:'బుక్ టాలెంట్',value:'BOOKTALENT'},
    {lable:'పీపుల్ సెంటర్',value:'PEOPLECENTER'},
    {lable:'పోలిటికోస్ వాలంటీర్లు',value:'POLITIKOSVOLUNTEERS'},
    {lable:'పోలిటికోస్ అఫిలియేట్స్',value:'POLITIKOSAFFILIATES'},
    {lable:'వర్గీకృత  ప్రకటనలు (క్లాసిఫైడ్)',value:'CLASSIFIEDS'},
    {lable:'ప్రకటనలు ఇవ్వండి',value:'ADVERTISEWITHUS'},
    {lable:'ఉద్యోగ అవకాశాలు  (కెరీర్)',value:'CAREERS'},
    {lable:'దిద్దుబాటును సమర్పించండి',value:'SUBMITCORRECTION'},
    {lable:'సంప్రదించండి',value:'CONTACTUS'},
    {lable:'పొలిటికోస్ నూతన అవిష్కరణలు, పరివర్తన,  సుస్థిర భవిష్యత్తు కోసం సంఘంలో మమేకం కావడానికి  అత్యాధునిక సాంకేతికత  ద్వారా  పరిష్కారాలను అందించే మీడియా & టెక్నాలజీ  సంస్థ.',value:'ABOUTUSP1'},
    {lable:'సుస్థిర మార్పు కోసం  సాంకేతికాభివృద్ధికి చోరవ తీసుకోవటం,  జనంతో మమేకం కావటం,  ప్రజల భాగస్వామ్యంతో వ్యక్తిగత, సామాజిక జీవిత చక్రాల  మార్పు నిర్వహణకు,  రాజీపడని సమగ్రత  ఒక చోదక శక్తిగా ఉండే  సూత్రాలపై  పొలిటికోస్  స్థాపించబడింది.',value:'ABOUTUSP2'},

    {lable:'వార్తా కధనాల రాయడం మొదలుకొని  ప్రకటలనలను పోస్ట్ చేయడం వరకు, "ఆన్‌లైన్ పిటిషన్‌లు" నుంచి " సపొర్ట్‌ కాజ్‌ " వరకు ,  "మార్కెట్‌ప్లేస్" నుంచి "షాపింగ్ ఇంటిగ్రేషన్" వరకు ఉన్న మా బహుళ ఫీచర్లు   మాతో అనుబంధం ఉన్న అన్ని వాటాదారులందరికి ప్రత్యక్ష, పరోక్ష ప్రయోజనాన్ని అందిస్తాయి.',value:'FEATURE_P1'},
    {lable:'వాలంటీర్ల నెట్ వర్క్',value:'VOLUNTEERS_NETWORK'},
    {lable:'వినతి (పిటిషన్)',value:'PETITIONS'},
    {lable:'పిటిషన్లు',value:'PETITION'},
    {lable:'ఫిర్యాదు (గ్రీవెన్స్)',value:'GRIEVANCES'},
    {lable:'గ్రీవెన్స్',value:'GRIEVANCE'},
    {lable:'కథలు',value:'STORIES'},
    {lable:'పర్స్ ఫ్రెండ్',value:'PURSE_FRIEND'},
    {lable:'వర్గీకృత  ప్రకటనలు (క్లాసిఫైడ్)',value:'CLASSIFIEDS'},
    {lable:'పోల్స్',value:'POLLS'},
    {lable:'సర్వేలు',value:'SURVEYS'},
    {lable:'పోలిటికోస్ స్టోర్',value:'POLITIKOS_STORE'},
    {lable:'మార్కేట్  ప్లేస్',value:'MARKET_PLACE'},
    {lable:'స్పాన్సర్‌షిప్‌',value:'SPONSORSSHIPS'},
    {lable:'కూపన్స్  & డీల్స్',value:'COUPONS_DEALS'},
    {lable:'ఓటర్స్ హబ్',value:'VOTERS_HUBnELECTION_LAND'},
    {lable:'చర్చా వేదిక (డిస్కషన్ రూం)',value:'DISCUSSION_ROOM'},
    {lable:'టీవీ & ఓటిపి గైడ్ ',value:'TVnOTTGUIDE'},
    {lable:'అంకుర నిధులు (స్టార్టప్ ఫండింగ్)',value:'STARTUP_FUNDING'},
    {lable:'సమాజహిత కార్యక్రమాలకు మద్దతివ్వండి (సపోర్ట్ కాజెస్)',value:'SUPPORT_CAUSES'},
    {lable:'ప్రచారం  (క్యాంపెయిన్)',value:'CAMPAIGNS'},
    {lable:'కాల్ ఫర్ పైలట్',value:'CALLFORPILOTS'},
    {lable:'లిజన్ మ్యూజిక్',value:'LISTENMUSIC'},
    {lable:'జాబ్ మార్కేట్ ',value:'JOBMARKET'},
    {lable:'బెటర్ హాఫ్',value:'BETTERHALR'},
    {lable:'మార్కేట్ సిటీ',value:'MARKET_CITIES'},
    {lable:'ఇ-పత్రిక',value:'E_PAPER'},
    {lable:'ఇ-మ్యాగజీన్',value:'E_MAGAZINES'},
    {lable:'కార్టూన్ కార్నర్',value:'CARTOON_CORNER'},
    {lable:'సమీక్షలు',value:'REVIEWS'},
    {lable:'రేటింగ్ ',value:'RATINGS'},
    {lable:'ర్యాంకింగ్',value:'RANKINGS'},
    {lable:'పొలిటికోస్‌తో భాగస్వామ్య అవకాశాలు',value:'PARTNERWITHUS'},
    {lable:'పోలిటికోస్‌లో మేము సాధారణమైన విషయాలతో సంతృప్తి చెందము. పోలిటికోస్‌ మార్పు, ప్రగతి, సంపూర్ణ మానవ అభివృద్ధి  పట్ల తపన ఉన్నఅన్ని వర్గాల వ్యక్తులను కలిపి, హద్దులు దాటించి మంత్రముగ్దుల్ని చేసే అనుభవాన్ని ఇస్తుంది. సమాచారాన్ని అందించటం, ఉత్పత్తులు, సేవలను విస్తరించడం, ప్రజా సమస్యలపై స్పందించటం వంటి అంశాలపై అనేక ఉత్తేజకరమైన ఫీచర్లతో పొలిటికోస్ మీ చుట్టూ ఉన్న ప్రపంచంతో మీరు సంభాషించే విధానాన్ని విప్లవాత్మకంగా మారుస్తుంది.',value:'PARTNERWITHUS_P1'},
    {lable:'వ్యాపారాలు తమ లక్ష్య ప్రేక్షకులతో కనెక్ట్ అయ్యే విధానాన్ని విప్లవాత్మకంగా మార్చుతూ... డిజిటల్ మీడియా,  ఆన్‌లైన్ సేవలలో మేము    గ్లోబల్ లీడర్‌గా ఉండాలని లక్ష్యంగా పెట్టుకున్నాము.   పొలిటికోస్ అఫిలియేట్‌లు,  పీపుల్ సెంటర్‌లతో కూడిన మా విస్తృతమైన నెట్‌వర్క్ సంప్రదాయ మీడియా సరిహద్దులను అధిగమించి వ్యక్తులను, సంఘాలను శక్తివంతం చేసే సమాంతర వ్యవస్థను రూపొందించడానికి మీకు ఏఉపయోగపడుతుంది.  ప్రజా సమస్యల పరిష్కరం  కోసం కృషి చేస్తూ  పొలిటికోస్ ప్రజలకు,  ప్రభుత్వాలకు మధ్య వారధిగా పనిచేస్తుంది.',value:'PARTNERWITHUS_P2'},
    {lable:'పొలిటికోస్‌తో భాగస్వామ్యం అనేక ప్రయోజనాలను అందిస్తుంది',value:'PARTNERWITHUS_P4'},
    {lable:'విస్తృత నెట్‌వర్క్:  పొలిటికోస్ తన ప్రత్యేకమైన సాంకేతిక ప్లాట్‌ఫామ్‌తో వాణిజ్యసంస్థలు సంఘంతో మమేకం కావాటానికి, సంపూర్ణ విధానంలో,  ఆన్‌లైన్‌, క్షేత్ర స్థాయి నెట్‌వర్క్ సహాయంతో కోత్త ప్రాంతాలకు విస్తరించే అవకాశం కల్పిస్తుంది.',value:'VASTNETWORK'},
    {lable:'నూత్న(ఇన్నోవేటివ్) పరిష్కారాలు: పొలిటికోస్ అత్యాధునిక సాంకేతిక వేదిక  మీ బ్రాండ్‌ ఉనికిని వ్యూహాత్మకంగా విస్తరించి మీ లక్ష్య ప్రేక్షకులతో  అర్ధవంతంగా మమేకం కావాటానికి దోహదం చేస్తుంది.',value:'INNOVATIVE_SOLUTION'},
    {lable:'సంఘాలను శక్తివంతం చేయటం : పొలిటికోస్‌తో కలిసి పని చేయడం వ్యాపార అవకాశాన్ని అందిస్తుంది. పరివర్తన, సంఘ సాధికారతకు ప్రాధాన్యతనిచ్చే ఉద్యమంలో మీ బ్రాండ్‌ను భాగస్వామిగా ఉంచుతుంది. సమాజ హితం కోసం దోహదపడుతూనే...,  సానుకూల బ్రాండ్ ఇమేజ్‌ని నిర్మించుకునే గొప్ప అవకాశం ఇది.',value:'PARTNERWITHUS_P7'},
    {lable:'సమాంతర వ్యవస్థ :  సానుకూల మార్పు, సామాజిక ప్రభావం,  అందరికీ మంచి భవిష్యత్తును సృష్టించడం కోసం పొలిటికోస్ ఒక ఉత్ప్రేరకం గా పనిచేయడానికి కట్టుబడి ఉంది.  సమాజం దిశను రూపొందించే విధానాలను ప్రభావితం చేయడానికి గొంతుకలను శక్తివంతం చేసే వేదిక ఇది.',value:'PARALLEL_ECOSYSTEM'},
    {lable:'పొలిటికోస్ మీ లక్ష్యాలకు అనుగుణంగా కస్టమైజ్డ్‌ భాగస్వామ్య అవకాశాలను అందిస్తుంది',value:'PARTNERWITHUS_PARA2'},
    {lable:'కంటెంట్‌ కొల్లాబరేషన్‌ :   మీ బ్రాండ్ కథను చెప్పడానికి, లక్ష్య ప్రేక్షకులతో కనెక్ట్ చేయడానికి అవసరమైన,  ఆకర్షణీయమైన,  ప్రభావవంతమైన కంటెంట్‌ను పొలిటికోస్‌  రూపొందిస్తుంది.',value:'CONTENT_COLLABORATION'},
   
    {lable:'త్వరలో వస్తోంది',value:'PARTNERWITHUS_P11'},
    {lable:'త్వరలో వస్తోంది',value:'PARTNERWITHUS_P12'},
    {lable:'త్వరలో వస్తోంది',value:'PARTNERWITHUS_P13'},
    {lable:'త్వరలో వస్తోంది',value:'PARTNERWITHUS_P14'},
    {lable:'త్వరలో వస్తోంది',value:'PARTNERWITHUS_P15'},
    {lable:'మీ సమాచారాన్ని మాకు అందించండి.మేము మిమ్మల్ని  సంప్రదిస్తాం',value:'LEAVEUS_FORM_HEADING'},
    {lable:'పేరు',value:'NAME'},
    {lable:'మొబైల్',value:'MOBILE'},
    {lable:'ఇ-మెయిల్',value:'EMAIL'},
    {lable:'ఎంక్వైరీ ఫాం',value:'ENQUIRY_FORM'},
    {lable:'వివరాలు',value:'DESCRIPTION'},
    {lable:'సమర్పించండి',value:'SUBMIT'},

    {value:'STORY_FAQ_TITLE_1',lable:"ఈ  యాప్‌కు వార్త/ క‌థ‌నం ఎలా పంపాలి ?"},
    {value:'STORY_FAQ_DESCRIPTION_1',lable:"హోం పేజిలో క్రింది భాగాన  ఉన్న + ఐకాన్ క్లిక్ చేయండి. వార్త/ క‌థ‌నం సమ‌ర్పించు  ఆప్ష‌న్ పై క్లిక్ చేసి ఎక్క‌డికి వెళ్లాలో   ఎంచుకోండి .  శీర్షిక, వార్త/ క‌థ‌నం/ ఫోటో / వీడియో అప్లోడ్ చేసి స‌బ్మిట్ చేయండి."},
 
    {value:'STORY_FAQ_TITLE_2',lable:"బాష‌ను మార్చుకోవ‌చ్చా?"},
    {value:'STORY_FAQ_DESCRIPTION_2',lable:"భాష‌ను మార్చుకోవ‌చ్చు.  ప్ర‌స్తుతం తెలుగు, ఇంగ్లీష్ బాష‌లు అందుబాటులో ఉన్నాయి."},

    {value:'STORY_FAQ_TITLE_3',lable:"నేను ఒకే సారి రెండు జిల్లాల వార్త‌లు పంపవ‌చ్చా ?"},
    {value:'STORY_FAQ_DESCRIPTION_3',lable:"మీరు వార్త/ క‌థ‌నం స‌మ‌ర్పించు ఆప్ష‌న్ లో ఎంచుకున్న జిల్లా నుంచి  మాత్ర‌మే  వార్త‌లు పంపాలి. ఇత‌ర జిల్లా వార్త‌లు పంపడానికి ప్ర‌స్తుతం అవ‌కాశం లేదు."},

    {value:'STORY_FAQ_TITLE_4',lable: "నేను ఒకే సారి రెండు మండ‌లాలు లేదా గ్రామాల నుంచి వార్త‌లు పంప‌వ‌చ్చా ?"},
    {value:'STORY_FAQ_DESCRIPTION_4',lable:"మీరు స్టోరి స‌బ్‌మిట్ ఆప్ష‌న్ లో ఎంచుకున్న గ్రామానికి, మండ‌లానికి సంబంధించిన   వార్త‌లు  మాత్ర‌మే పంపాలి.  ఇత‌ర గ్రామాల లేదా మండ‌లాల వార్త‌లు పంపడానికి ప్ర‌స్తుతం  అవ‌కాశం లేదు"},
    
    {value:'STORY_FAQ_TITLE_5',lable: "నేను యాప్‌కి ఒక‌టి కంటె ఎక్కువ వార్త‌ల‌ను   పంప‌వ‌చ్చా ?"},
    {value:'STORY_FAQ_DESCRIPTION_5',lable:"అవును, మీరు ఒక‌టి కంటె ఎక్కువ వార్త‌ల‌ను పంప‌వచ్చు. "},
   
    {value:'STORY_FAQ_TITLE_6',lable:"వార్త ప‌బ్లిష్  అవ‌డానికి ఎంత స‌మ‌యం ప‌డుతుంది ?"},
    {value:'STORY_FAQ_DESCRIPTION_6',lable: "మీరు పంపిన వార్త‌ను మా  సంపాద‌క సిబ్బంది ప‌రీశీలించి ప‌బ్లిష్ చేస్తుంది.  30 నిమిషాల నుంచి రెండు గంట‌లు ప‌ట్ట‌వ‌చ్చు. కొన్ని ప్ర‌త్యేక ప‌రిస్థితుల‌లో ఎక్కువ స‌మ‌యం కూడా ప‌ట్ట‌వ‌చ్చు. ఎడిటోరియ‌ల్ ప్ర‌మాణాలను పాటించ‌ని వార్త‌ను తిర‌స్కరించే అధికారం సంపాద‌క సిబ్బందికి ఉంటుంది."},

    {value:'STORY_FAQ_TITLE_7',lable:"నేను పంపించిన వార్త ప‌బ్లిష్ కాలేదు కాని ఇదే అంశంపై వేరే వాలంటీర్‌ రాసిన వార్త ప‌బ్లిష్ అయింది. ఎందుకు ?"},
    {value:'STORY_FAQ_DESCRIPTION_7',lable: "ఏద‌యిన ఒక టాపిక్ పై ఏ వాలంటీర్‌ ముందుగా పోస్ట్ చేస్తారో ఆ వార్త ముందు పబ్లిష్ అవుతుంది. "},

    {value:'STORY_FAQ_TITLE_8',lable: "సంపాదకీయ బృందం వార్త‌ల‌ను వెన‌క్కు పంపిస్తే ఏమి చేయాలి ?"},
    {value:'STORY_FAQ_DESCRIPTION_8',lable:"మీరు పంపిన  వార్త‌ను ఎలాంటి మార్పులు చేయవచ్చు, ఎలా మెర‌గ‌ప‌ర‌చ‌వ‌చ్చు అని సూచ‌న‌లతో సంపాద‌క బృందం  వెనక్కి పంపిస్తుంది. ఆ మార్పులు చేసి మీరు వార్త‌ను మ‌ళ్ళి పంప‌వ‌చ్చు."},

    {value:'STORY_FAQ_TITLE_9',lable:"వార్త‌ను తిర‌స్క‌రించ‌డానికి గ‌ల కార‌ణాలు ఏమిటి ?"},
    {value:'STORY_FAQ_DESCRIPTION_9',lable: "మా సంపాద‌క‌ బృందం చేసే ఫ్యాక్ట్ చెక్‌లో స‌ద‌రు వార్త  త‌ప్పు  అని తేలినా, ముందే ప‌బ్లిష్ అయిన ఇత‌రుల (కాపీరైట్ / ఐ.పి.ఆర్‌)  వార్త  అని తేలినా   తిర‌స్క‌రిస్తారు. పూర్తి వివ‌రాల‌కు  ఎడిటోరియ‌ల్  పాల‌సీ చ‌ద‌వండి."},

    {value:'STORY_FAQ_TITLE_10',lable: "వార్త‌ల‌ను సంపాదకీయ బృందం తిర‌స్క‌రిస్తే వార్త‌ల‌ను సంపాదకీయ బృందం తిర‌స్క‌రిస్తే ఏమి చేయాలి ? కార‌ణాలు తెలుసుకోవ‌చ్చా?"},
    {value:'STORY_FAQ_DESCRIPTION_10',lable:"అలా తిర‌స్క‌రించిన వార్త‌ను మ‌ళ్ళి పంపడారికి  లేదు. కార‌ణాలు తెలుసుకోవ‌చ్చు."},

    {value:'STORY_FAQ_TITLE_11',lable:"నా అకౌంట్ ని నిలిపి వేశారు. కారణం తెలుసుకోవ‌చ్చా ?"},
    {value:'STORY_FAQ_DESCRIPTION_11',lable:"త‌ర‌చుగా త‌ప్పుడు వార్తల‌ను,  వేరే వారు రాసిన వార్త‌ల‌ను,  మా ఎడిటోరియ‌ల్ ప్ర‌మాణాలకు త‌గ‌ని వార్త‌ల‌ను త‌ర‌చుగా పంప‌డం వ‌ల‌న  మీ అకౌంట్ నిషేధించ‌బ‌డుతుంది. పూర్తి వివ‌రాల‌కు ఎడిటోరియ‌ల్  పాల‌సీ చ‌ద‌వండి."},

    {value:'STORY_FAQ_TITLE_12',lable:"వార్త‌లు ప‌బ్లిష్  అయితే ఆదాయం ఉంటుందా"},
    {value:'STORY_FAQ_DESCRIPTION_12',lable: "ప‌బ్లిష్ అయిన మీ వార్త‌ల‌కు మాత్ర‌మే  ప్ర‌తిఫ‌లం ఉంటుది.  పంపిన ప్ర‌తి వార్త‌కు కాదు."},

    {value:'STORY_FAQ_TITLE_13',lable: "వాలెట్ నుంచి డ‌బ్బులు ఎలా రీడీమ్ చేసుకోవాలి ?"},
    {value:'STORY_FAQ_DESCRIPTION_13',lable:"మీది రిజిస్ట‌ర్డ్ KYC వెరిఫైడ్ వాలెట్ అయి వుంటేనే మీరు డ‌బ్బులు పొంద‌వ‌చ్చు."},

    {value:'STORY_FAQ_TITLE_14',lable:"నేను డ‌బ్బును వాలెట్ నుంచి ఎప్ప‌డు పోంద‌గ‌ల‌ను?"},
    {value:'STORY_FAQ_DESCRIPTION_14',lable: "మీ వాలెట్ లో  కనీసం  ₹ 500 జమ‌ అయిన త‌రువాత మీరు పొంద‌గ‌ల‌రు."},

    //Petitions
{lable:"ఆ దరఖాస్తుంది ఏమిటి?",value:'PETITION_FAQ_TITLE_1'},  
{lable:"దరఖాస్తు ప్రాపక సేవల వలన సర్వాలను సమర్పించబడే వారి పక్షం లేదా ఏ నిబంధన క్రియాపడలేదు. ఇది విద్య, ఆరోగ్య, విద్యుత్, మహిళల ఆరోగ్య, ఆదాయం, పోలీస్, గృహ గృహ సలనాటన, మరియు సనితాని విభిన్న విభాగాలకు సంబంధించవచ్చు.",value:'PETITION_FAQ_DESCRIPTION_1'},  

{lable:"ఏ విధంగా దరఖాస్తు చేస్తాను?",value:'PETITION_FAQ_TITLE_2'},  
{lable: "మీరు ప్రాపక సేవల గురించి ఏ విషయంపై దరఖాస్తు చేస్తారు అని చెప్పాలి.",value:'PETITION_FAQ_DESCRIPTION_2'}, 

{lable:"పాలిటికోస్ దరఖాస్తుల విశేషం నాకు ఎలా ఉపయోగపడుతుంది?",value:'PETITION_FAQ_TITLE_3'},  
{lable:"పాలిటికోస్ దరఖాస్తుల విశేషం ప్రజలు మరియు ప్రభుత్వ నడుము నడుచుటకు ఒక సేతువుగా డిజైన్ చేయబడింది. ఈ విశేషం మీరు మీ దరఖాస్తులను పంపడానికి అవగాహన పూరిత ప్రాధాన్యత పెట్టడాన్ని మార్చే దక్షత గా ఉంది. పాలిటికోస్ ఈ దరఖాస్తులను బయటకు తీసుకొని సంబంధిత అధికరణాల ప్రాధాన్యంని ఆక్రమించడంలో సహాయపడుతుంది మరియు సమస్యను పరిష్కరించడానికి పనిచేస్తుంది.",value:'PETITION_FAQ_DESCRIPTION_3'}, 

{lable:"పాలిటికోస్ నా ప్రార్థనను యొక్క మహత్వాన్ని చేపట్టడం మరియు ప్రాధికారుల ద్వారా ప్రయత్నించబడుతుందా?",value:'PETITION_FAQ_TITLE_4'},  
{lable:"మీ ప్రార్థన చేపట్టిన తర్వాత, పాలిటికోస్ అది కొంతసారిగా ప్రాధికారుల గమనకు తీసుకోను. ఇది చేయడానికి, పాలిటికోస్ వాయలను పరిపరిచిత ప్రాధికారుల ఆన్లైన్ నెట్వర్క్ను అనవచుకోవడం ద్వారా కంట్రోల్ చేసింది. ఇది ప్రజల ప్రార్థనలను చేస్తున్న సంబంధిత ప్రాధికారుల అనుభవాన్ని చూస్తుంది. అదనపు పాలిటికోస్ వలంటీర్ సహాయక నెట్వర్క్ను ఉపయోగించి ప్రార్థనను గదిలోకి తీసుకోవడం అనేకందరుని క్రితం తీసుకోవడం సహాయపడుతుంది.",value:'PETITION_FAQ_DESCRIPTION_4'}, 

{lable:"నా ప్రార్థనను ఎలా రAYయాలి?",value:'PETITION_FAQ_TITLE_5'},  
{lable:"మీ ప్రార్థన సమస్యను సంబంధించిన అన్ని సత్యాలతో స్పష్టమైనది అయినా, అవసరము లేని వివరాలను లేకుండా ఉండాలి. దుర్లంఘన లేదా ఆక్షేపణ భాష ఉండకుండా ఉండాలి. ఇలాంటి ప్రార్థనలు అవాగహనానికి ఘోషితం చేయబడతాయి.",value:'PETITION_FAQ_DESCRIPTION_5'}, 

{lable:"నా ప్రార్థనను ఎలా రAYయాలి?",value:'PETITION_FAQ_TITLE_6'},  
{lable: "ప్రార్థన తెలుగు లేక ఇంగ్లీష్ భాషలో మాత్రమే రాయాలి.",value:'PETITION_FAQ_DESCRIPTION_6'}, 

{lable: "ప్రార్థన చేపట్టడానికి పద్దతి ఏమిటి?",value:'PETITION_FAQ_TITLE_7'},  
{lable:"<ul> <li>హోమ్ పేజీని చిన్న ఐకాన్ని నొక్కండి</li> <li>'ప్రార్థన' నొక్కండి</li> <li>అదే సహాయాకరణలను అనుసరించి, మీ ప్రార్థనను చేపట్టవచ్చు.</li> </ul>",value:'PETITION_FAQ_DESCRIPTION_7'}, 

{lable:"ప్రార్థనలను ఆన్లైన్లో చేపట్టడానికి నాకు ఫారం పూరించడం లేక ఫారం పూరించడంలో సమస్య కలిగితే, పాలిటికోస్ నుండి ఎటువంటి సహాయం పొందగలిగుందా?",value:'PETITION_FAQ_TITLE_8'},  
{lable:"అవును, ఈ ఉద్దేశంతో పలు స్థలాలలో పీపల్ సెంటర్లను పాలిటికోస్ తెలిచేసింది. మీరు సహాయం కోసం సమీపిక పీపల్ సెంటర్ను సంప్రదించగలరు.",value:'PETITION_FAQ_DESCRIPTION_8'}, 

{lable:"ప్రాధికార సంబంధితంగా ముందుకే తిరస్కరించబడిన ప్రార్థనను నాకు చేపట్టడానికి సాధ్యమా?",value:'PETITION_FAQ_TITLE_9'},  
{lable:"అవును. మీరు వాచాలిగా అనిస్తే, అదనపు లేక సంబంధితంగా మరిన్ని / సంబంధిత రిజల్ట్లను సాధాం. కానీ, మీరు అదనపు చేయబడిన కారణాలను ఎందుకు నాకు అదనపు చేయబడలేదని వివరించాలి.",value:'PETITION_FAQ_DESCRIPTION_9'}, 

{lable:"ప్రార్థనను జతలు చేయాలనిది ఏ పత్రాలు అప్లోడ్ చేస్తే కావాలి?",value:'PETITION_FAQ_TITLE_10'},  
{lable:" <ul> <li>దాని యొక్క యాక్షార్థకము ఒకటిను అప్లోడ్ చేయండి</li> <li>ఆధార్</li> <li>రేషన్ కార్డు</li> <li>డ్రైవింగ్ లైసెన్స్</li> <li>పాస్పోర్ట్</li> <li>ఎంప్లాయీ ఐడి</li> <li>వోటర్ ఐడి</li> <li>మీ ప్రార్థనతో సంబంధించిన మరిన్ని ఆధారాలు మరియు ఫోటోలు అప్లోడ్ చేయాలి.</li> <li>ప్రార్థనను మరోసారి వివరించే సంబంధిత పరిపత్రాలు అప్లోడ్ చేయాలి, మీకు ఏదో ఉన్నాయితో.</li> </ul>",value:'PETITION_FAQ_DESCRIPTION_10'}, 

{lable: "ప్రార్థనతో సంబంధించిన పరిపత్రాల పరిమాణంపై ఎంతమంది సీమ?",value:'PETITION_FAQ_TITLE_11'},  
{lable:"అవును, మీరు మాత్రం అప్లోడ్ చేయవచ్చు: <ul> <li>2 MB పరిమాణం పటిశాల పరిపత్ర</li> <li>5 MB క్రమేణ ఎక్కువగా 5 చిత్రాల మహత్వమైన పరిపత్రలు</li> <li>5 MB పరిమాణంపై వీడియో</li> </ul>",value:'PETITION_FAQ_DESCRIPTION_11'}, 

{lable:"నాకు మీ ప్రార్థనను అనుమతించడానికి ఎంత మంది మంచియాలి?",value:'PETITION_FAQ_TITLE_12'},  
{lable:"మీ ప్రార్థనను కనిపించడానికి కనీసం 10 మంది మంచియాలి. ప్రార్థనను చేపట్టడానికి ప్రక్రియ ప్రక్రియలో, మీరు ఫారంలో ప్రార్థనను ఆధరించే 10 సభ్యుల పేర్లను ఇచ్చించవచ్చు (మంచియాకనివారిలో). ఈ మంచియాకునే పరిపత్రాను అనుమతించే సందేశానికి లింకును కలిగించి, అదనపు పాలిటికోస్ ద్వారా ఆప్రూవ్ చేయబడుతుంది.",value:'PETITION_FAQ_DESCRIPTION_12'}, 

{lable:"పాలిటికోస్ నా గోప్యత మరియు డేటా గోప్యతను ఎలా రక్షించగలదా?",value:'PETITION_FAQ_TITLE_13'},  
{lable:"పాలిటికోస్ వినండిసించడానికి యూజర్ గోప్యత మరియు గోప్యతా ప్రాధాన్యం పెట్టుతుంది. ప్రార్థన చేపట్టేటప్పుడు, మీ వ్యక్తిగత సమాచారాలు మరియు మీ ప్రార్థనని ప్రాధికారులు ప్రాధాన్యం పెట్టి గోప్యతను గమనిస్తుంది మరియు అధికముగా ప్రాధాన్యం ఇస్తూ అనుసరించడానికి అధిక గోప్యత చట్టాలు మరియు విధానాలను పాటించి సురక్షితంగా చేస్తుంది. పాలిటికోస్ మీ ద్వారా అందించిన సమాచారాన్ని మాత్రం మీకు సమాచారంగా ప్రకటించడానికి వాడతాడు.",value:'PETITION_FAQ_DESCRIPTION_13'},

{lable:"ఏ పరిస్థితులలో నా ప్రార్థనను పాలిటికోస్ ఆనందిస్తుంది అనిచేస్తుంది?",value:'PETITION_FAQ_TITLE_14'},  
{lable: "ప్రార్థన పాలిటికోస్ ద్వారా ఆనందించబడని కారణాలు వినడం కాదు: <ul> <li>అసత్య ప్రార్థన</li> <li>అపఠ్యము, ఆక్షేపణాత్మక మరియు ఆపక్ష్యాత్మక భాష లేదా కాంటెంట్</li> <li>ప్రార్థన పాలిటికోస్ కార్యనిర్వహణ కాలానికి లేకపోవడిని కాలమునకు చేపట్టేటివారాలకు సంబంధించిన విషయాన్ని లేకపోవడిని చర్చించడానికి సమయము చేయని స్థలాలను సేరింది</li> <li>ఉపన్యాస విషయాలు లేకపోవడిని కేసులు లేకపోవడిని</li> <li>వ్యక్తిగత మరియు కుటుంబ వివాదాలు</li> <li>ప్రభూత్వ కార్యాలలో తలపనీయండునలేని నిజమానవంలు</li> <li>ప్రజా హ్యానం లేని విషయం మరియు ఇతరులకు గోప్యతలో అనివార్య ప్రవేశం కలిగిస్తుంది</li> <li>దేశ భౌగోళిక అఖిలాంతర దృఢ గుణాకర్ణం, జాతీ",value:'PETITION_FAQ_DESCRIPTION_14'}, 


//Grievance
{lable:"ఫిర్యాదు అంటే ఏమిటి?",value:'GRIEVANCE_FAQ_TITLE_1'},  
{lable:"వివిధ శాఖలు అందించే ప్రభుత్వ పౌరసేవలలో లోపం/లోపము/అసంపూర్ణత వంటి వాటికి సంబంధించిన ఏదైనా సమస్య ఫిర్యాదు కావచ్చు లేదా వారు ఏదైనా చట్టం ప్రకారం అందించాలి. ఇది విద్య, ఆరోగ్యం, విద్యుత్, మహిళా సంక్షేమం, రెవెన్యూ, పోలీసు, హౌసింగ్ మరియు పారిశుధ్యం మొదలైన వివిధ విభాగాలకు సంబంధించినది కావచ్చు.",value:'GRIEVANCE_FAQ_DESCRIPTION_1'},  

{lable:"నేను ఎలాంటి ఫిర్యాదును సమర్పించగలను?",value:'GRIEVANCE_FAQ_TITLE_2'},  
{lable:"పైన వివరించిన పౌర సేవలకు సంబంధించిన ఏదైనా విషయంపై మీరు ఫిర్యాదులను సమర్పించవచ్చు.",value:'GRIEVANCE_FAQ_DESCRIPTION_2'},

{lable:"పొలిటికోస్ గ్రీవెన్స్ ఫీచర్ నాకు ఎలా ప్రయోజనం చేకూరుస్తుంది?",value:'GRIEVANCE_FAQ_TITLE_3'},  
{lable: "పొలిటికోస్ గ్రీవెన్స్ ఫీచర్ ప్రజలకు మరియు ప్రభుత్వానికి మధ్య వారధిగా పని చేయడానికి రూపొందించబడింది. ఈ ఫీచర్ మీ మనోవేదనలను పంచుకోవడానికి మిమ్మల్ని అనుమతిస్తుంది. రాజకీయ నాయకులు ఈ ఫిర్యాదులను సంబంధిత అధికారుల దృష్టికి తీసుకెళ్లి సమస్య పరిష్కారానికి కృషి చేస్తామన్నారు.",value:'GRIEVANCE_FAQ_DESCRIPTION_3'},

{lable:"నా ఫిర్యాదును సీరియస్‌గా తీసుకుని  అధికారులు చర్యలు తీసుకుంటారని పొలిటికోస్ ఎలా నిర్ధారిస్తుంది?",value:'GRIEVANCE_FAQ_TITLE_4'},  
{lable:"మీ ఫిర్యాదును సమర్పించిన తర్వాత, పొలిటికోస్  దానిని వెంటనే సంబంధిత అధికారుల దృష్టికి తీసుకువెళుతుంది. దీన్ని చేయడానికి, సంబంధిత ప్రభుత్వాల ఆన్‌లైన్ నెట్‌వర్క్‌ను అన్వేషించడం ద్వారా Politikos బలమైన కమ్యూనికేషన్ ఛానెల్‌లను ఏర్పాటు చేసింది. ఇది ప్రజల ఫిర్యాదులను నిర్వహించడానికి బాధ్యత వహించే సంబంధిత అధికారుల యొక్క అన్ని స్థాయిలను కవర్ చేస్తుంది. దీనితో పాటుగా పొలిటికోస్ దాని విస్తారమైన వాలంటీర్ నెట్‌వర్క్‌తో తదుపరి పనిని చేస్తుంది. పొలిటికోస్ యొక్క ఈ అదనపు ప్రయత్నం  మీ ఫిర్యాదును తీవ్రంగా పరిగణించి, విస్మరించకుండా సకాలంలో చర్య తీసుకునేలా చేయడంలో సహాయపడుతుంది.",value:'GRIEVANCE_FAQ_DESCRIPTION_4'},

{lable:"నేను నా ఫిర్యాదును ఎలా వ్రాయాలి?",value:'GRIEVANCE_FAQ_TITLE_5'},  
{lable:"సమస్యకు సంబంధించిన అన్ని వాస్తవాలు మరియు అనవసరమైన వివరాలు లేకుండా మీ ఫిర్యాదు స్పష్టంగా ఉండాలి. ఇది అభ్యంతరకరమైన లేదా దుర్వినియోగమైన భాషను కలిగి ఉండకూడదు లేకుంటే అది ఆమోదయోగ్యం కాదని ప్రకటించబడుతుంది.",value:'GRIEVANCE_FAQ_DESCRIPTION_5'},

{lable:"నా ఫిర్యాదును ఏ భాషలో రాయాలి?",value:'GRIEVANCE_FAQ_TITLE_6'},  
{lable:"ఫిర్యాదును తెలుగు లేదా ఆంగ్ల భాషలో మాత్రమే రాయాలి.",value:'GRIEVANCE_FAQ_DESCRIPTION_6'},

{lable: "ఫిర్యాదును సమర్పించే విధానం ఏమిటి?",value:'GRIEVANCE_FAQ_TITLE_7'},  
{lable:"- హోమ్ పేజీ దిగువన ఉన్న “+” చిహ్నాన్ని క్లిక్ చేయండి - 'గ్రీవెన్స్'పై క్లిక్ చేయండి. అదేవిధంగా తదుపరి సూచనలను అనుసరించడం ద్వారా మీరు ఫిర్యాదును సమర్పించవచ్చు.",value:'GRIEVANCE_FAQ_DESCRIPTION_7'},

{lable:"ఆన్‌లైన్‌లో ఫిర్యాదుల సమర్పణ కోసం ఫారమ్‌ను పూరించలేకపోతే లేదా పూరించేటప్పుడు ఏదైనా సమస్యను ఎదుర్కొంటే, నేను పొలిటికోస్ నుండి ఏదైనా సహాయం పొందవచ్చా?",value:'GRIEVANCE_FAQ_TITLE_8'},  
{lable:"అవును, పొలిటికోస్ ఈ ప్రయోజనం కోసం వివిధ ప్రదేశాలలో పీపుల్ సెంటర్లను తెరిచింది. మీరు సహాయం కోసం సమీపంలోని పీపుల్ సెంటర్‌ని  సంప్రదించవచ్చు.",value:'GRIEVANCE_FAQ_DESCRIPTION_8'},

{lable:"సంబంధిత అధికారం ద్వారా గతంలో తిరస్కరించబడిన/తొలగించబడిన ఫిర్యాదును నేను సమర్పించవచ్చా?",value:'GRIEVANCE_FAQ_TITLE_9'},  
{lable:"అవును. తొలగింపు సరిగ్గా జరగలేదని మీరు భావిస్తే, మీరు మరిన్ని / సంబంధిత వివరాలు మరియు రుజువులతో మళ్లీ ఫైల్ చేయవచ్చు. అయితే, తొలగింపు సరిగ్గా జరగలేదని మీరు ఎందుకు అనుకుంటున్నారో కారణాలను మీరు వివరించాలి.",value:'GRIEVANCE_FAQ_DESCRIPTION_9'},

{lable:"నేను ఏ పత్రాలను అప్‌లోడ్ చేయాలి?",value:'GRIEVANCE_FAQ_TITLE_10'},  
{lable:"<ul><li>క్రింది వాటిలో ఏదైనా ఒక గుర్తింపు రుజువును అప్‌లోడ్ చేయండి:<ul> <li>ఆధార్</li> <li>రేషన్ కార్డ్</li> <li>డ్రైవింగ్ లైసెన్స్</li> <li>పాస్‌పోర్ట్</ li> <li>ఉద్యోగి ID</li> <li>ఓటర్ ID</li> </ul> </li> <li>మీ ఫిర్యాదుకు సంబంధించిన సహాయక పత్రాలు మరియు ఫోటోలు.</li> <li>పత్రాలను సమర్పించండి ఏదైనా ఉంటే సంబంధిత అధికారితో ఈ ఫిర్యాదును పరిష్కరించడంలో మీ మునుపటి ప్రయత్నాలకు సంబంధించినది.</li></ul>",value:'GRIEVANCE_FAQ_DESCRIPTION_10'},

{lable:"ఫిర్యాదుతో జతచేయవలసిన పత్రాల పరిమాణంపై ఏదైనా పరిమితి ఉందా?",value:'GRIEVANCE_FAQ_TITLE_11'},  
{lable:"<ul> <li>అవును, మీరు అప్‌లోడ్ చేయగలరు: <ul> <li>PDF పత్రం పరిమాణం 2 MB వరకు;</li> <li>గరిష్టంగా 5 చిత్రాలు గరిష్టంగా 5 MBకి మించకూడదు</li> <li>గరిష్టంగా 5 MB వీడియో</li> </ul> </li></ul>",value:'GRIEVANCE_FAQ_DESCRIPTION_11'},

{lable:"నా ఫిర్యాదును ఎంత మంది ధృవీకరించాలి? ఎంత మంది నా ఫిర్యాదును ధృవీకరించాలి?",value:'GRIEVANCE_FAQ_TITLE_12'},  
{lable:"కనీసం 5 మంది వ్యక్తులు మీ ఫిర్యాదును ధృవీకరించాలి. ఫిర్యాదును సమర్పించే ప్రక్రియలో, ఫారమ్‌లో పేర్కొన్న విధంగా ఫిర్యాదుకు మద్దతు ఇచ్చే 5 మంది సభ్యుల (వాలిడేటర్లు) పేర్లను మీరు అందించవచ్చు. ఈ వ్యాలిడేటర్‌లు వారి ధృవీకరణ కోసం ఫిర్యాదుకు లింక్‌తో సహా సందేశాన్ని అందుకుంటారు. పొలిటికోస్ ద్వారా ఇది ఆమోదించబడుతుంది",value:'GRIEVANCE_FAQ_DESCRIPTION_12'},

{lable:"పొలిటికోస్ నా గోప్యత మరియు డేటా గోప్యతను ఎలా కాపాడుతుంది?",value:'GRIEVANCE_FAQ_TITLE_13'},  
{lable:"పొలిటికోస్ వినియోగదారు గోప్యత మరియు గోప్యతకు అధిక ప్రాధాన్యతనిస్తుంది. ఫిర్యాదును లేవనెత్తినప్పుడు, మీ వ్యక్తిగత సమాచారం మరియు మీ ఫిర్యాదు వివరాలు సురక్షితంగా నిర్వహించబడతాయి మరియు సంబంధిత గోప్యతా చట్టాలు మరియు నిబంధనలకు అనుగుణంగా రక్షించబడతాయి. Politikos మీరు అందించిన సమాచారాన్ని మీకు తెలియజేయడం ద్వారా ఫిర్యాదులను పరిష్కరించడానికి అవసరాన్ని బట్టి ప్రచురణ ప్రయోజనం కోసం మాత్రమే ఉపయోగిస్తుంది.",value:'GRIEVANCE_FAQ_DESCRIPTION_13'},

{lable:"ఏ పరిస్థితులలో నా మనోవేదనను  పొలిటీకోస్  విచారించరు",value:'GRIEVANCE_FAQ_TITLE_14'},  
{lable:"<div> <p>ఈ క్రింది కారణాల వల్ల రాజకీయవేత్తలు ఫిర్యాదు స్వీకరించరు:</p> <ul> <li>తప్పుడు ఫిర్యాదు</li> <li>అక్రమమైన, దుర్వినియోగమైన మరియు అభ్యంతరకరమైన భాష లేదా కంటెంట్</li > <li>పొలిటికోస్ పని చేయని నాగాలాండ్‌కు సంబంధించిన సమస్యల వంటి రాజకీయాల పరిధిలోకి రాని ప్రాంతానికి సంబంధించిన ఫిర్యాదు ఉంటే</li> <li>సబ్ జడ్జి కేసులు లేదా గతంలో ఏదైనా కోర్టు ఇచ్చిన తీర్పులకు సంబంధించిన ఏదైనా విషయం</li> <li> li> <li>వ్యక్తిగత మరియు కుటుంబ వివాదాలు</li> <li>ఏ ప్రభుత్వ పనితీరు పరిధిలోకి రాని పూర్తిగా ప్రైవేట్ విషయాలు</li> <li>ఇతరుల గోప్యతలో అనవసర జోక్యానికి దారితీసే ప్రజా ప్రయోజనం లేని విషయాలు </li> <li>దేశం యొక్క ప్రాదేశిక సమగ్రత, జాతీయ సార్వభౌమాధికారం లేదా ఇతర దేశాలతో స్నేహపూర్వక సంబంధాలపై ప్రభావం చూపే ఏదైనా</li> <li>మతపరమైన విషయాలు</li> <li>కేవలం సూచనలు</li> < /ul></div>",value:'GRIEVANCE_FAQ_DESCRIPTION_14'},
   

//profile page words
{lable:'మీ గురించి మ‌రిన్ని వివరాలు',value:'ABOUTYOURSELF'},
{lable:"మొదటి పేరు",value:'FIRSTNAME'},
{lable:"ఇంటి పేరు",value:'LASTNAME'},
{lable:"క‌నిపించ‌వ‌ల‌సిన పేరు",value:'DISPLAYNAME'},
{lable:"మొబైల్ నంబరు",value:'MOBILENUMBER'},
{lable:"ఇమెయిల్ ఐడి",value:'EMAIL_ID'},
{lable:"పుట్టిన తేది",value:'DOB'},
{lable:"వయసు",value:'AGE'},
{lable:"జండర్",value:'GENDER'},
{lable:"వైవాహిక స్థితి",value:'MARITAL_STATUS'},
{lable:"నవీకరించు",value:'UPDATE'},
{lable:"అప్డేట్స్",value:'UPDATES'},
{lable:"ఓటిపి పంపండి",value:'SEND_OTP'},
// {lable:"క‌థ‌నం ఐడి",value:'STORY_ID'},
{lable:"పిటిష‌న్‌ ఐడి",value:'PETITION_ID'},
{lable:"గ్రివెన్స్ ఐడి",value:'GRIEVANCE_ID'},


{lable:"ప్రాంతం",value:'LOCATION'},
{lable:"ఆటో ప్లే",value:'AUTO_PLAY'},
{lable:"రాష్ట్రం",value:"STATE"},
{lable: "జిల్లా",value:"DISTRICT"},
{lable:"మండలం/పట్టణం/నగరం",value:"MANDAL"},
{lable:"గ్రామం/వార్డ్/ డివిజ‌న్",value:"VILLAGE_AREA_LOCALITY"},


{lable:"ఆధార్ నం.",value:"AADHAR_NUMBER"},
{lable:"పాన్ కార్డ్ నం.",value:"PANCARD"},
{lable:"ఆధార్ అప్‌లోడ్ చేయండి.",value:"UPLOAD_AADHAR"},
{lable:"అప్‌లోడ్ పాన్ కార్డ్",value:"UPLOAD_PANCARD"},

{lable:"మీ గురించి క్లుప్తంగా",value:"ADDITIONAL_INFORMATION_CLEARLY"},
{lable:"విద్యార్హతలు",value:"EDUCATIONAL_QUALIFICATIONS"},
// {lable:"పని అనుభవం",value:"EDUCATIONAL_QUALIFICATIONS"},


{lable:"ఖాతా సంఖ్య",value:"ACCOUNT_NUMBER"},
{lable:"ఐఎఫ్‌ఎస్‌సి కోడ్",value:"IFSC_CODE"},
{lable:"బ్యాంక్ పేరు",value:"BANKNAME"},
{lable:"బ్యాంకు చిరునామా",value:"BANK_ADDRESS"},
{lable:"స్విఫ్ట్ కోడ్ (అంతర్జాతీయ చెల్లింపులు చేయడానికి)",value:"SWIFT_CODE"},

{lable:'చిరునామా',value:'ADDRESS'},
{lable:'అదనపు వివరాలు',value:'ADDITIONAL_INFORMATION'},
{lable:'కె.వై.సి',value:'KYC'},
{lable:'బ్యాంక్ వివరాలు',value:'BANK_DETAILS'},
{lable:'ఆసక్తి ఉన్న కార్యకలాపాలు',value:'INTERESTED_ACTIVITIES'},



{lable:'వాలంటీర్‌గా  చేరండి',value:'JOIN_VOULUNTEER'},
{lable:'చిరునామా లైన్ 1',value:'VOL_ADDRESS_LINE1'},
{lable:'చిరునామా లైన్ 2',value:'VOL_ADDRESS_LINE2'},
{lable:'అత్యవసర పరిస్థితుల్లో సంప్రదించాల్సిన వ్యక్తి ',value:'EMG_POINT_OF_CONTACT'},
{lable:'ఎలాంటి  సంబంధం',value:'NATURE_OF_RELATIONSHIP'},
{lable:'మీరు ఎలా పాల్గొనాలనుకుంటున్నారు',value:'HOW_WOULD_LIKE_TO_BE_INVOLVED'},



//new story words
{lable:'రాసే భాష',value:'LANGUAGE'},
{lable:'వార్త/ క‌థ‌నం తేది',value:'STORY_DATE_TIME'},
{lable:'టైటిల్‌',value:'TITLE'},
{lable:'వార్త/ క‌థ‌నం',value:'STORY_DESCRIPTION'},
{lable:'ఇమేజ్ / వీడియో',value:'PHOTES_VIDEOS_LABLE'},
{lable:'స్టోరి టైప్‌',value:'STORY_TYPE'},
{lable:'స్టోరి లొకేషన్‌',value:'STORY_LOCATION'},
{lable:'భ‌ద్ర‌ప‌ర‌చండి',value:'SAVE'},
{lable:'మునుపటి',value:'PREVIOUS'},
{lable:'అప్‌లోడ్',value:'UPLOAD'},
{lable:'కొత్త కథనం',value:'NEW_STORY'},
{lable:'తదుపరి',value:'NEXT'},
{lable:'అప్‌లోడ్ ఇమేజ్‌లు',value:'UPLOAD_IMAGES'},
{lable:'అప్‌లోడ్ వీడియో',value:'UPLOAD_VIDEO'},
{lable:'యూట్యూబ్ లింక్‌ని జోడించండి',value:'ADD_YOUTUBE_LINKE'},
{lable:'విభాగం',value:'CATEGORIES'},
{lable:'ఉప విభాగం',value:'SUB_CATEGORIES'},
{lable:'ప్ర‌ధాన‌ ప‌దాల జోడింపు',value:'PRIMARY_TAGS'},
{lable:'ఇత‌ర ప‌దాల జోడింపు',value:'OTHER_TAGS'},
{lable:'ఎనానిమస్ గా పంపండి (పేరు తెలపకుండా పంపండి)',value:'ANONYMOUSLY'},
{lable:'వ్యాఖ్యలను క్రియాశీలం చెయ్యండి',value:'COMMETS_VISIBILITY'},
{lable:'Previous',value:'PREVIOUS'},
{lable:'మునుపటి కథనాలు',value:'PREVIOUS_STORIES'},
{lable:'ఇది స్వ‌యంగా నేనే రూపొందించాను అని ధృవీకరిస్తున్నాను. ఈ సృజ‌న‌ కాపీరైట్‌కు సంబందించి ఎదురయ్యే చట్ట, న్యాయపరమైన అంశాలకు బాధ్యత నాదే',value:'STORY_COPYRIGHTS1'},
{lable:'ఈ స్టోరీని నేను ఈ యాప్ నిబంధనలు, మార్గదర్శకాలకు లోబడి సబ్మిట్ చేస్తున్నాను. నేను ఇతరుల కాపీరైట్ లేదా గోప్యత హక్కులను ఉల్లంఘించలేదు అని ధృవీకరిస్తున్నాను ఇంకా తెలుసుకొండి',value:'STORY_COPYRIGHTS2'},


{lable:'కథనం వివరాలు',value:'STORY_DETAILS'},
{lable:'ఇతర వివరాలు',value:'OTHER_DETAILS'},
{lable:'భాషను నమోదు చేయండి',value:'VALIDATION_LANGUAGE'},
{lable:'టైటిల్‌ను నమోదు చేయండి',value:'VALIDATION_TITLE'},
{lable:'వార్త/ క‌థ‌నం రాయండి',value:'VALIDATION_DESCRIPTION'},
{lable:'స్టోరి టైప్‌ను నమోదు చేయండి',value:'VALIDATION_STORYTYPE'},
{lable:'రాష్ట్రాన్ని నమోదు చేయండి',value:'VALIDATION_STATE'},
{lable:'జిల్లాను నమోదు చేయండి',value:'VALIDATION_DISTRICT'},
{lable:'నమోదు చేయండి విభాగం',value:'VALIDATION_CATEGORY'},



//setting model words
{lable:'యాప్ భాషను ఎంచుకోండి',value:'INTERFACE_lANGUAGE'},
{lable:'వార్త భాష ఎంపిక',value:'NEWS_FEED_lANGUAGE'},
{lable:'మీకు ఇష్ట‌మ‌యిన అంశాలు?',value:'WHAT_INTERESTES_YOU'},

]






export const TranslateEnglishWords = [
    {lable:'DOWNLOAD APP ON',value:'DOWNLOADAPPON'},
    {lable:'COMING SOON',value:'COMINGSOON'},
    {lable:'SIGN IN',value:'SIGNIN'},
    {lable:'SEARCH',value:'SEARCH'},
    {lable:'All',value:'ALL'},
    {lable:'More',value:'MORE'},
    {lable:'View All',value:'VIEWALL'},
    {lable:'SHARE',value:'SHARE'},
    {lable:'LATEST POLLS',value:'LATESTPOLLS'},
    {lable:'SUBMIT',value:'SUBMIT'},
    {lable:'PREVIOUS POLLS',value:'PREVIOUSPOLLS'},
    {lable:'JOIN NOW',value:'JOINNOW'},
    {lable:'LATEST NEWS FROM POLITIKOS',value:'LATESTNEWSFROMPOLITIKOS'},
    {lable:'MORE FORM THE POLITIKOS',value:'MOREFORMTHEPOLITIKOS'},
    {lable:'For You',value:'FORYOU'},
    {lable:'Home',value:'HOME'},
    {lable:'Trending',value:'TRENDING'},
    {lable:'Politics',value:'POLITICS'},
    {lable:'Entertainment',value:'ENTERTAINMENT'},
    {lable:'Profile',value:'PROFILE'},   
    {lable:'Logout',value:'LOGOUT'},
    {lable:'Settings',value:'SETTINGS'},
    {lable:"FAQ's",value:'FAQs'},
    {lable:'Sports',value:'SPORTS'},
    {lable:'Business',value:'BUSINESS'},
    {lable:'Technology',value:'TECHNOLOGY'},
    {label:"Livetv", value:"LIVETV"},
    {lable:'BREAKING NEWS',value:'BREAKINGNEWS'},
    {lable:'Un Follow',value:'UNFOLLOW'},
    {lable:'Follow',value:'FOLLOW'},
    {lable:'Posts',value:'POSTS'},
    {lable:'Followers',value:'FOLLOWERS'},
    {lable:'Following',value:'FOLLOWING'},
    {lable:'User',value:'USER'},
    {lable:'About your self',value:'ABOUT_YOUR-SELF'},
    {lable:'Submissions',value:'SUBMISSIONS'},
    {lable:'Wallet',value:'WALLET'},
    {lable:'Upload Profile‌',value:'UPLOAD_PROFILE'},
    {lable:'Upload Image',value:'UPLOAD_PHOTO'},
    {lable:'Latest',value:'LATEST'},
    {lable:'Popular',value:'POPULAR'},
    {lable:'Most Discussed',value:'MOSTDISCUSSED'},
    {lable:'Lens',value:'LENS'},
    {lable:'Story',value:'STORY'},    
    {lable:'Links',value:'LINKS'},
    {lable:'About Us',value:'ABOUTUS'},   
    {lable:'Tags',value:'TAGS'},   
    {lable:'Features',value:'FEATURES'},
    {lable:'Partner With Us',value:'PARTNERWITHUS'},
    {lable:'Call for Pilots',value:'CALLFORPILOTS'},
    {lable:'Policies',value:'POLICIES'},
    {lable:'Disclaimer',value:'DISCLAIMER'},
    {lable:'SIIMW',value:'SIIMW'},
    {lable:'Book Talent',value:'BOOKTALENT'},
    {lable:'People Center',value:'PEOPLECENTER'},
    {lable:'Politikos Volunteers',value:'POLITIKOSVOLUNTEERS'},
    {lable:'Politikos Affiliates',value:'POLITIKOSAFFILIATES'},
    {lable:'Classifieds',value:'CLASSIFIEDS'},
    {lable:'Advertise With Us',value:'ADVERTISEWITHUS'},
    {lable:'Careers',value:'CAREERS'},
    {lable:'Submit Correction',value:'SUBMITCORRECTION'},
    {lable:'Contact Us',value:'CONTACTUS'}, {lable:'Politikos is a media & technology enterprise that provides solutions for innovation, transformation, enterprise and social engagement for a sustainable future through its cutting-edge technological interventions.',value:'ABOUTUSP1'},
    {lable:"Politikos is founded on the principles of Uncompromising Integrity as the driving force of sustainable change through its initiatives in technology development, human engagement and change management across all spheres of the individual and societal life cycles with people's participation.",value:'ABOUTUSP2'},
     {lable:'Our multiple features, ranging from content generation to posting an advertisement, "online petitions" to "support the cause" and "marketplace" to "shopping integration", provides a direct and indirect benefit to all stakeholders who associate with us.',value:'FEATURE_P1'},
     {lable:'Volunteers Network',value:'VOLUNTEERS_NETWORK'},
     {lable:'Petitions',value:'PETITIONS'},
     {lable:'Grievance',value:'GRIEVANCE'},
     {lable:'Purse Friend',value:'PURSE_FRIEND'},
     {lable:'Classifieds',value:'CLASSIFIEDS'},
     {lable:'Polls',value:'POLLS'},
     {lable:'Surveys',value:'SURVEYS'},
     {lable:'Politikos Store',value:'POLITIKOS_STORE'},
     {lable:'Marketplace',value:'MARKET_PLACE'},
     {lable:'Sponsorships',value:'SPONSORSSHIPS'},
     {lable:'Coupons & Deals',value:'COUPONS_DEALS'},
     {lable:'Voters Hub/Election Land',value:'VOTERS_HUBnELECTION_LAND'},
     {lable:'Discussion Room',value:'DISCUSSION_ROOM'},
     {lable:'TV And OTT Guide ',value:'TVnOTTGUIDE'},
     {lable:'Startup Funding',value:'STARTUP_FUNDING'},
     {lable:'Support Causes',value:'SUPPORT_CAUSES'},
     {lable:'Campaigns',value:'CAMPAIGNS'},
     {lable:'Call For Pilots',value:'CALLFORPILOTS'},
     {lable:'Listen Music',value:'LISTENMUSIC'},
     {lable:'Job Market',value:'JOBMARKET'},
     {lable:'Better Half',value:'BETTERHALR'},
     {lable:'Market Cities',value:'MARKET_CITIES'},
     {lable:'E-Paper',value:'E_PAPER'},
     {lable:'E-Magazines',value:'E_MAGAZINES'},
     {lable:'Cartoon Corner',value:'CARTOON_CORNER'},
     {lable:'Reviews',value:'REVIEWS'},
     {lable:'Ratings ',value:'RATINGS'},
     {lable:'Rankings',value:'RANKINGS'},
     {lable:'Partnering Opportunities With Politikos',value:'PARTNERWITHUS'},
     {lable:"At Politikos, we are not content with the ordinary. It's an immersive experience that transcends boundaries, connecting individuals from all walks of life through a shared passion for change and progress in wholesome human development. With a numerous exciting features in the areas of disseminating Information,extending products and services,attending public issues. Politikos is set to revolutionize the way you interact with the world around you.",value:'PARTNERWITHUS_P1'},
     {lable:"We aim to be a global leader in digital media and online services, revolutionizing the way businesses connect with their target audiences. Our extensive network of Politikos affiliates and People Centers allows us to transcend traditional media boundaries and create a parallel ecosystem that empowers individuals and communities. By advocating, pursuing public issues Politikos will act as a bridging force between people and governments.",value:'PARTNERWITHUS_P2'},
     {lable:'Partnering With Politikos Offers A Multitude Of Benefits:',value:'PARTNERWITHUS_P4'},
     {lable:'Vast Network : Politikos with its unique technological platform provides opportunities for businesses to engage with the communities via holistic approach and connect in untouched areas through our online and offline network.',value:'VASTNETWORK'},
     {lable:'Innovative Solutions : Politikos cutting-edge technologies and platform to amplify brands presence in a strategic move that can yield meaningful engagement with your target audience.',value:'INNOVATIVE_SOLUTION'},
     {lable:"Empowering Communities : Collaborating with Politikos provides a business opportunity and positions your brand as a participant in a movement that prioritizes positive change and community empowerment. It's a chance to contribute to the greater good while building a positive brand image.",value:'PARTNERWITHUS_P7'},
     {lable:"Empowering Communities : Collaborating with Politikos provides a business opportunity and positions your brand as a participant in a movement that prioritizes positive change and community empowerment. It's a chance to contribute to the greater good while building a positive brand image.",value:'PARTNERWITHUS_P8'},
     {lable:"Parallel ecosystem : Politikos is committed to becoming a catalyst for positive change, social impact, and the creation of a better future for all. It's about providing a space where voices are empowered to influence policies and shape the direction of society.",value:'PARALLEL_ECOSYSTEM'},
     {lable:'Politikos Offers Partnership Opportunities Customized To Your Goals:',value:'PARTNERWITHUS_PARA2'},
     {lable:"Content Collaboration : Politikos creates engaging and impactful content essential for telling your brand's story and connecting to the target audience.",value:'CONTENT_COLLABORATION'},
     {lable:'Strategic Advertising : Harnessing the power of our vast online and offline platform is a strategic approach to ensuring your message reaches the right audience and drives conversions through our data-driven insights and strategic target ensuring that your advertising efforts are optimized for maximum impact.',value:'STRATEGIC_ADVERTISING'},
     {lable:"Community Engagement : Collaborating with Politikos People Centers engages local communities to make a lasting impact and align with a positive social impact and community-focused entity. It's an opportunity to create lasting connections and make a meaningful difference in the lives of societies.",value:'COMMUNITY_ENGAGEMENT'},
     {lable:"Unlock a Virtual Marketplace : Politikos isn't just a media platform; it's a gateway to a virtual marketplace filled with innovation and convenience. Explore curated products that enhance your life, redefine your shopping experience, and bring you closer to a world of possibilities.",value:'UNLOCK_A_VIRTUAL_MARKETPLACE'},
     {lable:"Politikos can benefit your brand by offering a multifaceted approach that extends beyond traditional media platforms. The platform's diverse features and extensive network create a unique environment for your brand to create a lasting impact and contribute to your success.",value:'PARTNERWITHUS_PARG3'},
     {lable:'Leave Us Your Info and we will get back to you',value:'LEAVEUS_FORM_HEADING'},
     {lable:'Name',value:'NAME'},
     {lable:'Mobile',value:'MOBILE'},
     {lable:'E-mail',value:'EMAIL'},
     {lable:'Enquiry form',value:'ENQUIRY_FORM'},
     {lable:'Description',value:'DESCRIPTION'},
     {lable:'Submit',value:'SUBMIT'},    
     {lable:'Related Story',value:'RELATED_STORY'},
     {lable:'Rejected',value:'REJECTED'},
     {lable:'Reason for Rejected',value:'REASON_FOR_REJECTED'},
     {lable:'Reason for Returned',value:'REASON_FOR_RETURNED'},    
     {lable:'Waiting for Approval',value:'WAITING_FOR_APPROVAL'},
     {lable:'Resolved',value:'RESOLVED'},
     {lable:'Sent to Authorities Concerned',value:'SENT_TO_AUTH_CONCERNED'},
     {lable:'Returned',value:'RETURNED'},   
     {lable:'Under Review',value:'UNDER_REVIEW'},   
     {lable:'Posted for Signature',value:'POSTED_FOR_SINGNATURE'},   
     {lable:'Published',value:'PUBLISHED'},   
     {lable:'Draft',value:'DRAFT'}, 

   //Profile page words
   {lable:'About Your Self',value:'ABOUTYOURSELF'},
   {lable:'Address',value:'ADDRESS'},
   {lable:'Additional Information',value:'ADDITIONAL_INFORMATION'},
   {lable:'KYC',value:'KYC'},
   {lable:'Bank Details',value:'BANK_DETAILS'},

     {value:'STORY_FAQ_TITLE_1',lable:"How to send content to this app?"},
     {value:'STORY_FAQ_DESCRIPTION_1',lable:"Click the + icon on the home page. Click on the Story Submit option and select a location. Write headline, content and upload photo or video and submit."},
  
     {value:'STORY_FAQ_TITLE_2',lable:'Can I change the language?'},
     {value:'STORY_FAQ_DESCRIPTION_2',lable:"You can change the language. Currently, Telugu and English languages ​​are available."},

     {value:'STORY_FAQ_TITLE_3',lable:"Can I send content from two districts at once?"},
     {value:'STORY_FAQ_DESCRIPTION_3',lable:"You should submit content only from the district selected in the story submission option. Now there is no option to send other district content."},

     {value:'STORY_FAQ_TITLE_4',lable:"Can I send content from two mandals or villages at once?"},
     {value:'STORY_FAQ_DESCRIPTION_4',lable:"You should send only the content related to the village and the mandal you have selected in the story submit option. Now there is no option to send the content of the other villages and mandals."},
     
     {value:'STORY_FAQ_TITLE_5',lable: "Can I submit multiple stories to the app? "},
     {value:'STORY_FAQ_DESCRIPTION_5',lable:"Yes, You can submit multiple stories to the news app."},
    
     {value:'STORY_FAQ_TITLE_6',lable: "How long does it take to publish the news"},
     {value:'STORY_FAQ_DESCRIPTION_6',lable:"Content submitted by you will be examined and published by our editorial staff. It can take anywhere from 30 minutes to two hours. It may take even longer on some special occasions. The organization reserves the right to reject content that does not suit to editorial standards."},

     {value:'STORY_FAQ_TITLE_7',lable:"The story sent by me was not published but the story written by another volunteer on the same topic was published. May I know the reasons?"},
     {value:'STORY_FAQ_DESCRIPTION_7',lable:"Whichever volunteer posts early on a topic gets published first."},

     {value:'STORY_FAQ_TITLE_8',lable: "What to do if the editorial team returns my story ?"},
     {value:'STORY_FAQ_DESCRIPTION_8',lable:"If the editorial team returns  your story, you may receive feedback on why it was rejected and what changes could be made to improve it. You may also have the option to resubmit the story after making those changes."},

     {value:'STORY_FAQ_TITLE_9',lable:"What are the reasons for rejecting the stories? "},
     {value:'STORY_FAQ_DESCRIPTION_9',lable: "— If the editorial team, in its fact-checking process, finds that posted content is fake or plagiarised, it will be rejected. For more details read the editorial policy."},

     {value:'STORY_FAQ_TITLE_10',lable: "What to do if the editorial team rejects my story? Can I know the reasons ?"},
     {value:'STORY_FAQ_DESCRIPTION_10',lable:"You don't have any option to resubmit the  rejected story."},

     {value:'STORY_FAQ_TITLE_11',lable: "My account is blocked. May I know the reason?"},
     {value:'STORY_FAQ_DESCRIPTION_11',lable:"Frequent posting of fake, plagiarised, content that does not suitable to editorial standards will get your account blocked. For more details, read the editorial policy. "},

     {value:'STORY_FAQ_TITLE_12',lable: "Will there be income if the news is published?"},
     {value:'STORY_FAQ_DESCRIPTION_12',lable: "You will get a reward for your published content only. (not for posted content)"},

     {value:'STORY_FAQ_TITLE_13',lable: "How can I redeem money from my wallet?"},
     {value:'STORY_FAQ_DESCRIPTION_13',lable:"You can redeem money from your wallet if it is a registered KYC verified one."},

     {value:'STORY_FAQ_TITLE_14',lable: "When can I get the money?"},
     {value:'STORY_FAQ_DESCRIPTION_14',lable:"You will get after getting a deposit of a minimum of Rs  ₹ 500 in your wallet."},


{lable:'About Your Self',value:'ABOUTYOURSELF'},
{lable:"First Name",value:'FIRSTNAME'},
{lable:"Last Name",value:'LASTNAME'},
{lable:"Display Name",value:'DISPLAYNAME'},
{lable:"Mobile No.",value:'MOBILENUMBER'},
{lable:"Email ID",value:'EMAIL_ID'},
{lable:"DOB",value:'DOB'},
{lable:"Age",value:'AGE'},
{lable:"Gender",value:'GENDER'},
{lable:"Marital Status",value:'MARITAL_STATUS'},
{lable:"Update",value:'UPDATE'},
{lable:"Updates",value:'UPDATES'},
{lable:"Send OTP",value:'SEND_OTP'},
{lable:"Story_Id",value:'STORY_ID'},
{lable:"Petition Id",value:'PETITION_ID'},
{lable:"Grievance Id",value:'GRIEVANCE_ID'},
    
//Grievance
{lable:'What is  a Grievance?',value:'GRIEVANCE_FAQ_TITLE_1'},  
{lable:"A grievance can be any matter related to deficiency/ lapse/ incompleteness  in citizen services of the government either offered by various departments  or they are supposed to provide under any statute.  It may pertain to different departments like : education, health, electricity, Women’s welfare, revenue, police, housing and sanitation etc. ",value:'GRIEVANCE_FAQ_DESCRIPTION_1'},  

{lable:"What kind of grievance can I submit?",value:'GRIEVANCE_FAQ_TITLE_2'},  
{lable:"You can submit  grievances on any matter related to citizen services explained above.",value:'GRIEVANCE_FAQ_DESCRIPTION_2'},

{lable:"How can the Politikos Grievances feature benefit me ?",value:'GRIEVANCE_FAQ_TITLE_3'},  
{lable:"The Politikos Grievances feature is designed to act as a bridge between public and government.  This feature facilitates you to share your Grievances. The Politikos will take these Grievances to the attention of the concerned authorities and work towards solving the problem.",value:'GRIEVANCE_FAQ_DESCRIPTION_3'},

{lable:"How does Politikos ensure that my grievance is taken seriously  and acted upon by authorities?",value:'GRIEVANCE_FAQ_TITLE_4'},  
{lable:"Once your grievance is submitted, Politikos  will take it to the attention of the appropriate authorities promptly. To do this, Politikos has established robust channels of communication by exploring the online network of concerned Governments. This covers all levels of the relevant authorities responsible for handling people's grievances. In addition to this Politikos will do a follow up work with its vast volunteer network.  This  additional effort of Politikos  helps ensure that your grievance is taken seriously and acted upon in a timely manner without ignoring it.",value:'GRIEVANCE_FAQ_DESCRIPTION_4'},

{lable:"How should I write my grievance?",value:'GRIEVANCE_FAQ_TITLE_5'},  
{lable:"Your grievance should be clear with  all facts relating to the issue and without unnecessary details. It should not contain offensive or abusive language otherwise it will be declared inadmissible.",value:'GRIEVANCE_FAQ_DESCRIPTION_5'},

{lable:"In which language should I write my grievance?",value:'GRIEVANCE_FAQ_TITLE_6'},  
{lable:"A grievance should be written in Telugu or English language only.",value:'GRIEVANCE_FAQ_DESCRIPTION_6'},

{lable: "What is the procedure for submitting a grievance ?",value:'GRIEVANCE_FAQ_TITLE_7'},  
{lable:"- Click the “+” icon on the bottom of home page - click on 'grievance'. Similarly by following the further instructions you can submit a grievance.",value:'GRIEVANCE_FAQ_DESCRIPTION_7'},

{lable:"Can I get  any assistance from Politikos,  if I am unable to fill a form for submission of grievances online or face any problem while filling?",value:'GRIEVANCE_FAQ_TITLE_8'},  
{lable:"Yes, Politikos has opened People centres at various places for this purpose. You may contact the nearest People Centre  for assistance.",value:'GRIEVANCE_FAQ_DESCRIPTION_8'},

{lable:"Can I submit a grievance which was earlier rejected/ dismissed by the authority concerned?",value:'GRIEVANCE_FAQ_TITLE_9'},  
{lable:"Yes. If you think the dismissal was not done properly, you can file again with more / relevant details and proofs. However, you need to explain the reasons why you think the dismissal was not done properly.",value:'GRIEVANCE_FAQ_DESCRIPTION_9'},

{lable:"What documents do I need to upload?",value:'GRIEVANCE_FAQ_TITLE_10'},  
{lable:"<ul><li>Upload any one identity proof from the following:<ul>          <li>Aadhar</li>          <li>Ration Card</li>          <li>Driving Licence</li>          <li>Passport</li>          <li>Employee ID</li>          <li>Voter ID</li>      </ul>  </li>  <li>Supporting documents and photos related to your grievance.</li>  <li>Submit the documents related to your previous attempts on redressing this grievance with the concerned authority, if any.</li></ul>",value:'GRIEVANCE_FAQ_DESCRIPTION_10'},

{lable:"Is there any limit on the size of the documents to be attached with the grievance ?",value:'GRIEVANCE_FAQ_TITLE_11'},  
{lable:"<ul>  <li>Yes, You can upload only:      <ul>          <li>PDF document size up to 2 MB;</li>          <li>Maximum of 5 Images up to not more than 5 MB collectively</li>          <li>Video of up to 5 MB</li>      </ul>  </li></ul>",value:'GRIEVANCE_FAQ_DESCRIPTION_11'},

{lable:"How many people need to validate my grievance?",value:'GRIEVANCE_FAQ_TITLE_12'},  
{lable:"At least 5 people should validate your grievance. During the process of submission of grievance you can provide  the names of 5 members (validators) who support the grievance as mentioned in the form. These validators will receive a message including a link to the grievance for their validation. Than it will be  approved by Politikos",value:'GRIEVANCE_FAQ_DESCRIPTION_12'},

{lable:"How can Politikos protect my privacy and confidentiality of data ?",value:'GRIEVANCE_FAQ_TITLE_13'},  
{lable:"Politikos places a high priority on user privacy and confidentiality. When raising a grievance, your personal information and the details of your grievance will be handled securely and protected in accordance with relevant privacy laws and regulations. Politikos will use the information provided by you, solely for the purpose of publication as per need in order to resolve grievances, by informing you.",value:'GRIEVANCE_FAQ_DESCRIPTION_13'},

{lable:"Under what circumstances my grievance will not  be entertained  by Politikos",value:'GRIEVANCE_FAQ_TITLE_14'},  
{lable:"<div> <p>A grievance will not be entertained by Politikos for the following reasons:</p>  <ul>      <li>False grievance</li>      <li>Illegible, abusive, and objectionable language or content</li>      <li>If a grievance pertains to a region which is not covered by Politikos, like issues pertaining to Nagaland where Politikos is not operational</li>      <li>Sub Judice cases or any matter previously concerning judgments given by any court</li>      <li>Personal and family disputes</li>      <li>Purely private matters which can't fall under any government functioning</li>      <li>Matters without any public interest that lead to unnecessary intervention into the privacy of others</li>      <li>Anything that impacts upon the territorial integrity of the country, national sovereignty, or friendly relations with other countries</li>      <li>Religious matters</li>      <li>Mere suggestions</li>  </ul></div>",value:'GRIEVANCE_FAQ_DESCRIPTION_14'},
   


//Petitions
{lable:"What is a petition?",value:'PETITION_FAQ_TITLE_1'},  
{lable:"A petition can be any matter related to deficiency lapse incompleteness in citizen services of the government either offered by various departments or they are supposed to provide under any statute. It may pertain to different departments like : education, health, electricity, Women's welfare, revenue, police, housing and sanitation etc.",value:'PETITION_FAQ_DESCRIPTION_1'},  

{lable:" What kind of petition can I submit?",value:'PETITION_FAQ_TITLE_2'},  
{lable:"You can submit petitions on any matter related to citizen services explained above.",value:'PETITION_FAQ_DESCRIPTION_2'}, 

{lable:"How can the Politikos petitions feature benefit me ?",value:'PETITION_FAQ_TITLE_3'},  
{lable:"The Politikos petitions feature is designed to act as a bridge between public and government.This feature facilitates you to share your petitions. The Politikos will take these petitions to the attention of the concerned authorities and work towards solving the problem.",value:'PETITION_FAQ_DESCRIPTION_3'}, 

{lable:"How does Politikos ensure that my petition is taken seriously and acted upon by authorities?",value:'PETITION_FAQ_TITLE_4'},  
{lable:"Once your petition is submitted, Politikos will take it to the attention of the appropriate authorities promptly. To do this, Politikos has established robust channels of communication by exploring the online network of concerned Governments. This covers all levels of the relevant authorities responsible for handling people's petitions. In addition to this Politikos will do a follow up work with its vast volunteer network. This additional effort of Politikos helps ensure that your petition is taken seriously and acted upon in a timely manner without ignoring it.",value:'PETITION_FAQ_DESCRIPTION_4'}, 

{lable:"How should I write my petition?",value:'PETITION_FAQ_TITLE_5'},  
{lable:"Your petition should be clear with all facts relating to the issue and without unnecessary details. It should not contain offensive or abusive language otherwise it will be declared inadmissible.",value:'PETITION_FAQ_DESCRIPTION_5'}, 

{lable:"In which language should I write my petition?",value:'PETITION_FAQ_TITLE_6'},  
{lable: "A petition should be written in Telugu or English language only.",value:'PETITION_FAQ_DESCRIPTION_6'}, 

{lable: "What is the procedure for submitting a petition ?",value:'PETITION_FAQ_TITLE_7'},  
{lable:"<ul> <li>Click the icon on the bottom of the home page</li> <li>Click on 'petition'</li> <li>Similarly, by following the further instructions, you can submit a petition.</li> </ul>",value:'PETITION_FAQ_DESCRIPTION_7'}, 

{lable:"Can I get any assistance from Politikos, if I am unable to fill a form for submission of petitions online or face any problem while filling?",value:'PETITION_FAQ_TITLE_8'},  
{lable:"Yes, Politikos has opened People centers at various places for this purpose. You may contact the nearest People Center for assistance.",value:'PETITION_FAQ_DESCRIPTION_8'}, 

{lable:"Can I submit a petition which was earlier rejected/ dismissed by the authority concerned?",value:'PETITION_FAQ_TITLE_9'},  
{lable:"Yes. If you think the dismissal was not done properly, you can file again with more / relevant details and proofs. However, you need to explain the reasons why you think the dismissal was not done properly.",value:'PETITION_FAQ_DESCRIPTION_9'}, 

{lable:"What documents do I need to upload?",value:'PETITION_FAQ_TITLE_10'},  
{lable:" <ul> <li> Upload any one identity proof from following</li> <li>Aadhar</li> <li>Ration Card</li> <li>Driving Licence</li> <li>Passport</li>  <li>Employee ID</li>  <li>Voter ID</li> <li>Supporting documents and photos related to your petition.</li> <li>Submit the documents related to your previous attempts on redressing this petition with the concerned authority, if any.</li> </ul>",value:'PETITION_FAQ_DESCRIPTION_10'}, 

{lable: " Is there any limit on the size of the documents to be attached with the petition ?",value:'PETITION_FAQ_TITLE_11'},  
{lable:"Yes, You can upload only: <ul> <li>PDF document size up to 2 MB</li> <li>Maximum of 5 Images up to not more than 5 MB collectively</li> <li>Video of up to 5 MB</li> </ul>",value:'PETITION_FAQ_DESCRIPTION_11'}, 

{lable:"How many people need to validate my petition?",value:'PETITION_FAQ_TITLE_12'},  
{lable:"At least 10 people should validate your petition. During the process of submission of petition you can provide the names of 10 members (validators) who support the petition as mentioned in the form. These validators will receive a message including a link to the petition for their validation. Than it will be approved by Politikos",value:'PETITION_FAQ_DESCRIPTION_12'}, 

{lable:" How can Politikos protect my privacy and confidentiality of data",value:'PETITION_FAQ_TITLE_13'},  
{lable:"Politikos places a high priority on user privacy and confidentiality. When raising a petition, your personal information and the details of your petition will be handled securely and protected in accordance with relevant privacy laws and regulations. Politikos will use the information provided by you, solely for the purpose of publication as per need in order to resolve petitions, by informing you.",value:'PETITION_FAQ_DESCRIPTION_13'},

{lable:" Under what circumstances my petition will not be entertained by Politikos",value:'PETITION_FAQ_TITLE_14'},  
{lable: "A petition will not be entertained by Politikos for following reasons: <ul> <li>False petition</li> <li>Illegible, abusive and objectionable language or content</li> <li>If a petition pertains to a region which is not covered by IMW like issue pertaining to Nagaland where Politikos not operational</li> <li>Sub Judice cases or any matter previously concerning judgment given by any court</li> <li>Personal and family disputes</li> <li>Purely private matters which can't fall in any government functioning</li> <li>Matter without any public interest and leads to unnecessary intervention into the privacy of others</li> <li>Anything that impacts upon territorial integrity of the country, national sovereignty or friendly relations with other countries</li> <li>Religious matters</li> <li>Mere suggestions</li> </ul>",value:'PETITION_FAQ_DESCRIPTION_14'}, 


{lable:"Location",value:'LOCATION'},
{lable:"Auto Play",value:'AUTO_PLAY'},
{lable:"State",value:"STATE"},
{lable: "District",value:"DISTRICT"},
{lable:"Mandal/Town/City",value:"MANDAL"},
{lable:"Village/Area/Locality",value:"VILLAGE_AREA_LOCALITY"},


{lable:"AAdhar Number",value:"AADHAR_NUMBER"},
{lable:"Pancard Number",value:"PANCARD"},
{lable:"Upload AAdhar",value:"UPLOAD_AADHAR"},
{lable:"Upload Pancard",value:"UPLOAD_PANCARD"},

{lable:"Additional Information",value:"ADDITIONAL_INFORMATION_CLEARLY"},
{lable:"Educational Qualifications",value:"EDUCATIONAL_QUALIFICATIONS"},
{lable:"Religion",value:"RELIGION"},
{lable:"Caste",value:"CASTE"},
{lable:"Languages Know",value:"LANGUAGES_KNOW"},
{lable:"Area of Expertise",value:"AREA_OF_EXPERTISE"},
// {lable:"పని అనుభవం",value:"EDUCATIONAL_QUALIFICATIONS"},


{lable:"Account Number",value:"ACCOUNT_NUMBER"},
{lable:"IFSC Code",value:"IFSC_CODE"},
{lable:"Bank Name",value:"BANKNAME"},
{lable:"Bank Address",value:"BANK_ADDRESS"},
{lable:"Swift Code (To make international payments)",value:"SWIFT_CODE"},


//submission tab words
{lable:"Stories",value:"STORIES"},

{lable:"Petitions",value:"PETITION"},
{lable:"Grievances",value:"GRIEVANCES"},
{lable:"Surveys",value:"SURVEYS"},
{lable:"Polls",value:"POLLS"},

{lable:'Join Volunteer',value:'JOIN_VOULUNTEER'},
{lable:'Address Line 1',value:'VOL_ADDRESS_LINE1'},
{lable:'Address Line 2',value:'VOL_ADDRESS_LINE2'},
{lable:'Emergency Point of Contact',value:'EMG_POINT_OF_CONTACT'},
{lable:'Nature of Relationship',value:'NATURE_OF_RELATIONSHIP'},
{lable:'How would like to be involved',value:'HOW_WOULD_LIKE_TO_BE_INVOLVED'},
{lable:'Interested Activities',value:'INTERESTED_ACTIVITIES'},
{lable:'Details of Self',value:'DETAILS_OF_SELF'},

//new story words
{lable:'Language',value:'LANGUAGE'},
{lable:'Story Date & Time',value:'STORY_DATE_TIME'},
{lable:'Title',value:'TITLE'},
{lable:'Description',value:'STORY_DESCRIPTION'},
{lable:'Photos / Videos',value:'PHOTES_VIDEOS_LABLE'},
{lable:'Story Type',value:'STORY_TYPE'},
{lable:'Story Location',value:'STORY_LOCATION'},
{lable:'Save',value:'SAVE'},
{lable:'Previous',value:'PREVIOUS'},
{lable:'Upload',value:'UPLOAD'},
{lable:'New Story',value:'NEW_STORY'},
{lable:'Next',value:'NEXT'},
{lable:'Upload Images',value:'UPLOAD_IMAGES'},
{lable:'Upload Video',value:'UPLOAD_VIDEO'},
{lable:'Add Youtube Link',value:'ADD_YOUTUBE_LINKE'},
{lable:'Categories',value:'CATEGORIES'},
{lable:'Sub categories',value:'SUB_CATEGORIES'},
{lable:'Primary Tags',value:'PRIMARY_TAGS'},
{lable:'Other Tags',value:'OTHER_TAGS'},
{lable:'Anonymously',value:'ANONYMOUSLY'},
{lable:'Comments visibility',value:'COMMETS_VISIBILITY'},
{lable:'Previous',value:'PREVIOUS'},
{lable:"I agree this is my original content and i'm sharing this through this app. I take full responsibility for plagarism, copyright, defemation and any other legal issuess",value:'STORY_COPYRIGHTS1'},
{lable:"By submitting your story, you acknowledge that you agree to this app Terms of Service and Community Guidelines. Please make sure that you do not violate others' copyright or privacy rights. Learn more",value:'STORY_COPYRIGHTS2'},


//setting model words
{lable:'Interface Language',value:'INTERFACE_lANGUAGE'},
{lable:'News Feed Language',value:'NEWS_FEED_lANGUAGE'},
{lable:'What Interests you?',value:'WHAT_INTERESTES_YOU'},
]









export const teluguFontStyle = { fontFamily: 'Mallanna',fontSize:'19px',fontWeight:'400'}
export const teluguFontStyleHeading = { fontFamily: 'Mallanna',fontSize:'25px',fontWeight:'700'}
export const largeTextStyle = { fontFamily: 'Mallanna',fontSize:'35px',fontWeight:'700'}
 