import React, { useEffect, useState } from "react";
import { Tabs, Menu, Dropdown, Button, Select, Skeleton, Grid } from "antd";

// import { useGetAllActiveCategoryQuery } from "../../Services/CategoryAPI";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import TranslateWord from "../../sharedComponents/TranslateWord";
// import TechnologySection from "./TechnologySection";
import LivetvSection from "./LivetvSection";
// import { getAllTechnologyData, selectedTechnologyInterfaceAction } from "../../../Store/TechnologySlice";
import { getAllLivetvData, selectedLivetvInterfaceAction } from "../../../Store/LivetvSlice";
import { Color } from "antd/es/color-picker";

const { useBreakpoint } = Grid;
const LivetvSectionDB = ({ sectionType }) => {
  const dispatch = useDispatch();
  const { data: allCategoriesList } = useSelector((state) => state.categoriesList);
  const { data: interfaceLanguagesData, selectedInterfaceLanguage } = useSelector(
    (state) => state.interfaceLanguageList
  );
  const { data: feedLanguageData } = useSelector((state) => state.feedLanguageList);
  const screens = useBreakpoint();
  // const {
  //   data: allTechnologyData,
  //   status,
  //   error,
  //   technologySelectedLang,
  // } = useSelector((state) => state.technologyList);
  // console.log("technologySelectedLang", technologySelectedLang);
  const { data: allLivetvData, status, error, livetvSelectedLang } = useSelector((state) => state.livetvList);
  const { politicsSelectedLang } = useSelector((state) => state.politicsList);
  const LivetvTitle = livetvSelectedLang?.name === "తెలుగు" ? "లైవ్ టివి" : "Live TV";
  const ALLWORD = livetvSelectedLang?.name === "తెలుగు" ? "అన్ని" : "All";
  const trendingLives = livetvSelectedLang?.name === "తెలుగు" ? "ట్రెండింగ్ లైవ్స్" : "Trending Lives";
  const politikosLives = livetvSelectedLang?.name === "తెలుగు" ? "పాలిటికోస్  లైవ్" : "Politikos Live";
  const { TabPane } = Tabs;
  const [activeKey, setActiveKey] = useState(ALLWORD);
  const [isShowMore, setisShowMore] = useState(false);
  const [allSubCategoriesList, setAllSubCategoriesList] = useState([]);
  const [selectedLang, setSelectedLang] = useState(livetvSelectedLang?.interface_lang_id);
  const [selectedCategory, setSelectedCategory] = useState(ALLWORD);
  const [rfsh, setRfsh] = useState(false);
  const liveTvCategories = allCategoriesList.filter(
    (allCat) => allCat?.interface_language?.name === livetvSelectedLang?.name
  );
  liveTvCategories.splice(
    0,
    0,
    { category_name: ALLWORD },
    { category_name: trendingLives },
    { category_name: politikosLives }
  );
  const { Option } = Select;
  useEffect(() => {
    // setActiveKey(ALLWORD, trendingLives, politikosLives);     //c
    const categoriesForLive = allCategoriesList;
    const selectedCategoriesList = allCategoriesList?.find((item) => item.category_name === selectedCategory);
    const subCategories = selectedCategoriesList?.str_sub_categories_names;
    if (subCategories?.length) setAllSubCategoriesList([ALLWORD, trendingLives, politikosLives, ...subCategories]);
  }, [allCategoriesList, selectedCategory]);
  //  if(politicsLoading === 'loading'){
  //   return <div> <Skeleton
  //   active
  //   title={false}
  //   paragraph={{ rows: 2, width: [1000, 1000] }}
  // /></div>
  // }
  const onChange = (key) => {
    setisShowMore(false);
    setActiveKey(key);
    setSelectedCategory(key);
  };
  //   const handleChange = (value) => {
  //     setisShowMore(true);
  //     setActiveKey(secondArray[value]);
  //   };
  //   let children = [];
  //   for (let i = 0; i < secondArray.length; i++) {
  //     children.push(<Select.Option key={i}>{secondArray[i]}</Select.Option>);
  //   }
  const handleChange = (value) => {
    console.log("value22", value);
    setSelectedLang(value);
    const selectedIngerfaceLang = interfaceLanguagesData?.filter((lang) => {
      if (lang?.interface_lang_id == value) {
        return lang;
      }
    });

    const userSelectedFeedLanguages = feedLanguageData?.filter((feed) => {
      return feed?.name === selectedIngerfaceLang?.[0]?.name;
    });
    console.log("feedLa", userSelectedFeedLanguages);
    const feedLangPayload = userSelectedFeedLanguages?.map((feedL) => {
      return feedL?.newsfeed_lang_id;
    });
    console.log("feedLangPayload", feedLangPayload);

    dispatch(selectedLivetvInterfaceAction(selectedIngerfaceLang?.[0]));
  };
  console.log("ALLWORD", ALLWORD);
  console.log("ALLWORD", activeKey);
  useEffect(() => {
    handleChange(selectedInterfaceLanguage?.interface_lang_id);
  }, [selectedInterfaceLanguage]);

  useEffect(() => {
    setActiveKey(ALLWORD);
    setSelectedCategory(ALLWORD);
  }, [livetvSelectedLang]);

  return (
    <>
      <div
        style={{
          display: "flex",
          width: "85%",
          justifyContent: "space-between",
          marginTop: "30px",
        }}>
        <div
          className="heading headig_technology politics_heading"
          style={{ width: "200px", marginTop: livetvSelectedLang?.name === "English" ? "-20px" : "-8px" }}>
          <h1>{LivetvTitle}</h1>
        </div>
        {/* <div style={{ position: "relative", left: "22vw" }>
          <Select
            style={{ width: "9vw" }}
            getPopupContainer={(node) => node.parentNode}
            onChange={handleChange}
            value="More"
          >
            {children}
          </Select>
        </div> */}
        <div className="more-info-link-light viewall-language">
          {/* <span className="dropdown lang_selection">
            <a
              className="dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              English
            </a>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <span className="dropdown-item">English</span>
              <span className="dropdown-item">Telugu</span>
              <span className="dropdown-item">Hindi</span>
            </div>
          </span> */}
          {livetvSelectedLang && (
            <Select
              size="small"
              className="hide-on-small"
              // defaultValue={
              //   livetvSelectedLang &&
              //   livetvSelectedLang?.interface_lang_id
              // }
              value={selectedLang}
              style={{
                width: 95,
                position: "relative",
                top: "-1px",
              }}
              onChange={handleChange}>
              {interfaceLanguagesData?.map((lang) => {
                return (
                  <>
                    <Select.Option value={lang?.interface_lang_id}>{lang?.name}</Select.Option>
                  </>
                );
              })}
            </Select>
          )}

          <span style={{ position: "relative", left: "5px" }}>
            <Link to={`Livetv`}>
              {/* <Link to={`${selectedCategory === "All" ? "Livetv" : selectedCategory}`}> */}
              {<TranslateWord key={"VIEWALL"} text={"VIEWALL"} headingStyle={false} lang={livetvSelectedLang} />}
            </Link>
          </span>
        </div>
      </div>
      <Tabs
        activeKey={activeKey}
        onChange={onChange}
        moreIcon={
          <span style={{ display: "flex", left: "55px" }}>
            <TranslateWord key={"MORE"} text={"MORE"} headingStyle={false} lang={livetvSelectedLang} />
            &#11206;
          </span>
        }
        // style={{ maxHeight: "85vh" }}
        // className="livetv-tab-names"
        className={screens.xs ? "custom-tabs-container" : "custom-tabs-container-livetv"}>
        {/* {allSubCategoriesList?.map((tabrecord, index) => { */}
        {liveTvCategories?.map((tabrecord, index) => {
          // const tabKey = tabrecord;
          const tabKey = tabrecord.category_name;
          return (
            <>
              <TabPane
                tab={
                  <span
                    className={livetvSelectedLang?.name === "తెలుగు" ? "telugu-text" : "english-text"}
                    style={{ marginLeft: "10px" }}>
                    {/* {tabrecord} */}
                    {tabKey}
                  </span>
                }
                key={tabKey}
                className="custom-width-tabpane"
                style={{ position: "relative", top: "-10vh", backgroundColor: "black" }}>
                <LivetvSection
                  key={index + tabKey}
                  subCategoryName={activeKey}
                  categoryName={selectedCategory}
                  sectionInterfaceLang={livetvSelectedLang}
                />
              </TabPane>
            </>
          );
        })}
      </Tabs>
    </>
  );
};

export default LivetvSectionDB;
