import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Avatar, Badge, Button, Card, Col, Divider, Empty, Pagination, Progress, Row, Skeleton, Tooltip } from "antd";

import MorEellipsisIcon from "../Sections/Common/MorEellipsisIcon";
import { useLocation } from "react-router-dom";
import { Tabs } from "antd";
import noImg from "../../imgaes/noImg.svg";
import noImgJPG from "../../imgaes/noImgJPG.jpg";
import smileGif from "../../imgaes/smileGif.gif";
import cryIcon from "../../imgaes/cryIcon.gif";
import heartIcon from "../../imgaes/heartIcon.gif";
import angryIcon from "../../imgaes/angryIcon.gif";
import likeIcon from "../../imgaes/likeIcon.gif";
import wowIcon from "../../imgaes/wowIcon.gif";
import headphoneIcon from "../../imgaes/headphoneIcon.svg";
import videoPlayIcon from "../../imgaes/videoPlayIcon.svg";
import messageIcon from "../../imgaes/messageIcon.svg";
import whatsappIcon from "../../imgaes/whatsappIcon.svg";
import profile_img from "../../imgaes/profile_img.svg";
import playBtn from "../../imgaes/playBtn.svg";
import Modal from "../Modal";
import Emojis from "../sharedComponents/Emojis";
import CommentsModal from "../sharedComponents/CommentsModal";
import { WhatsappShareButton } from "react-share";
import { shareStoryUrl } from "../../utils/config";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllGotoSourceUserDetails,
  getAllTabWiseDataForGotoSourcePage,
  getAllTabWiseLensDataForGotoSourcePage,
  getAllTabWisePetitionDataForGotoSourcePage,
  updateUserFollowUP,
} from "../../Store/GotoSourceSlice";
import TranslateWord from "../sharedComponents/TranslateWord";
let tabData = ["Latest", "Popular", "Most Discussed"];
function GotoSource() {
  const location = useLocation();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);
  const { TabPane } = Tabs;
  const source_user_id = queryParams.get("param1");
  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 8;
  const navigate = useNavigate();
  const [showCommentsDrawer, setShowCommentsDrawer] = useState(false);
  const [storyRecord, setStoryRecord] = useState(null);
  const [type, setType] = useState("Story");
  let selectedStory = null;
  const imageStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40px",
    height: "40px",
    zIndex: 666,
  };
  const {
    data: gotoSourceUserStories,
    tabsData: currentTabData,
    petitionTabData: currentPetitionTabData,
    lensTabData: currentLensTabData,
    status: gotoSourceStatus,
    gotoSourceUserstatus,
  } = useSelector((state) => state.gotoSourceList);
  const { selectedInterfaceLanguage } = useSelector((state) => state.interfaceLanguageList);
  const [activeKey, setActiveKey] = useState("Latest");
  useEffect(() => {
    tabData =
      selectedInterfaceLanguage?.name === "తెలుగు"
        ? ["తాజావి", "ప్రజాదరణ పొందినవి", "ఎక్కువగా చర్చించినవి"]
        : ["Latest", "Popular", "Most Discussed"];
  }, []);
  useEffect(() => {
    getAllGotoSourceUserDetailsFunc();
  }, [source_user_id]);
  const gettingDataBasedOnSelectedTab = (pageNo) => {
    let user = JSON.parse(localStorage.getItem("LoggedInUserDetails"));

    if(type === 'Petitions'){
      const payload = {
        login_user_id: +user?.user_id,
        source_user_id: +source_user_id ?? 0,
        page: pageNo ?? 0,
        pageSize: 8,       
        content_type: type,
      };
     
        dispatch(getAllTabWisePetitionDataForGotoSourcePage(payload));
    }
    else{
      if (activeKey === "Latest" || activeKey === "తాజావి") {
        const payload = {
          login_user_id: +user?.user_id,
          source_user_id: +source_user_id ?? 0,
          page: pageNo ?? 0,
          pageSize: 8,
          tab_type: "Latest",
          content_type: type,
        };
       
         if(type === 'Story') dispatch(getAllTabWiseDataForGotoSourcePage(payload));
         else dispatch(getAllTabWiseLensDataForGotoSourcePage(payload));
       
      } else if (activeKey === "Popular" || activeKey === "ప్రజాదరణ పొందినవి") {
        const payload = {
          login_user_id: +user?.user_id,
          source_user_id: +source_user_id ?? 0,
          page: pageNo ?? 0,
          pageSize: 8,
          tab_type: "Popular",
          content_type: type,
        };
        if(type === 'Story') dispatch(getAllTabWiseDataForGotoSourcePage(payload));
        else dispatch(getAllTabWiseLensDataForGotoSourcePage(payload));
      } else if (activeKey === "Most Discussed" || activeKey === "ఎక్కువగా చర్చించినవి") {
        const payload = {
          login_user_id: +user?.user_id,
          source_user_id: +source_user_id ?? 0,
          page: pageNo ?? 0,
          pageSize: 8,
          tab_type: "Most Discussed",
          content_type: type,
        };
        if(type === 'Story') dispatch(getAllTabWiseDataForGotoSourcePage(payload));
        else dispatch(getAllTabWiseLensDataForGotoSourcePage(payload));
      }
    }
   
  };
  useEffect(() => {
    gettingDataBasedOnSelectedTab(0);
  }, [activeKey, type]);
  const handlePageChange = (page, pageSize) => {
    gettingDataBasedOnSelectedTab(page - 1);
    setCurrentPage(page);
  };
  const handleTabs = (key) => {
    setActiveKey(key);
    setCurrentPage(1);
  };
  const getLatestData = () => {
    gettingDataBasedOnSelectedTab(currentPage - 1);
  };
  const hangleCommentsDrawer = (rec) => {
    setShowCommentsDrawer(true);
    setStoryRecord(rec);
  };
  const handleCloseDrawer = () => {
    setShowCommentsDrawer(false);
    setStoryRecord(null);
  };
  const storeStory = (story) => {
    selectedStory = story;
  };
  const getAllGotoSourceUserDetailsFunc = () => {
    const LOGIN_USER_ID = localStorage.getItem("loginuserId");
    const payload = {
      userId: +LOGIN_USER_ID,
      sourceUserId: source_user_id ?? 0,
    };
    dispatch(getAllGotoSourceUserDetails(payload));
  };
  const hangleFollowUp = async () => {
    let user = JSON.parse(localStorage.getItem("LoggedInUserDetails"));
    const payload = {
      follow_user: !gotoSourceUserStories?.is_loginuser_follwing_sourceuser,
      follow_user_id: gotoSourceUserStories?.source_login_user_id,
      login_user_id: user?.user_id,
    };
    dispatch(updateUserFollowUP(payload)).then((action) => {
      if (updateUserFollowUP.fulfilled.match(action)) {
        getAllGotoSourceUserDetailsFunc();
      }
    });
  };
  if (gotoSourceStatus === "loading") {
    return (
      <div>
        {" "}
        <Skeleton active title={false} paragraph={{ rows: 6, width: [800, 800] }} />
      </div>
    );
  }
  const goToSourceWitAuthorId = (input_user_id) => {
    navigate(`/GotoSource?param1=${input_user_id}&activeTab=${"Latest"}`);
  };

  const handleType = (type) => {
    setType(type);
    gettingDataBasedOnSelectedTab(0);
    setCurrentPage(1);
  };
  return (
    <>
      {gotoSourceUserstatus === "loading" ? (
        <Skeleton
          active
          title={false}
          paragraph={{ rows: 6, width: [1000, 1000] }}
          style={{ position: "relative", left: "15vw" }}
        />
      ) : (
        <>
          <Row>
            <Col span={2} offset={1}>
              <img src={profile_img} />
            </Col>
            <Col span={15}>
              <div className="userName_styles"> {gotoSourceUserStories?.firstname}</div>
              <div className="userId_styles"> {`User (ID#${gotoSourceUserStories?.source_login_user_id})`}</div>
              <div className="user_breif"> {gotoSourceUserStories?.breif_about_self}</div>
              <div className="userId_styles row_flex">
                <div>
                  <strong>{gotoSourceUserStories?.post_count}</strong> &nbsp;
                  <TranslateWord key={"POSTS"} text={"POSTS"} headingStyle={true} lang={selectedInterfaceLanguage} />
                </div>
                <div>
                  <strong> {gotoSourceUserStories?.followers_count}</strong>
                  &nbsp;
                  <TranslateWord
                    key={"FOLLOWERS"}
                    text={"FOLLOWERS"}
                    headingStyle={true}
                    lang={selectedInterfaceLanguage}
                  />
                </div>
                <div>
                  <strong>{gotoSourceUserStories?.following_count}</strong>
                  &nbsp;
                  <TranslateWord
                    key={"FOLLOWING"}
                    text={"FOLLOWING"}
                    headingStyle={true}
                    lang={selectedInterfaceLanguage}
                  />
                </div>
              </div>
            </Col>
            <Col span={4}>
              <Button className="follow_goto" onClick={hangleFollowUp}>
                {gotoSourceUserStories?.is_loginuser_follwing_sourceuser ? (
                  <TranslateWord
                    key={"UNFOLLOW"}
                    text={"UNFOLLOW"}
                    headingStyle={false}
                    lang={selectedInterfaceLanguage}
                  />
                ) : (
                  <TranslateWord key={"FOLLOW"} text={"FOLLOW"} headingStyle={false} lang={selectedInterfaceLanguage} />
                )}
              </Button>
            </Col>
          </Row>
          <Divider />
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",             
            }}>
            <Col
              lg={{
                span: 24,
              }}
              xs={{
                span: 24,
              }}
              style={{
                display: "flex",
                justifyContent: "center",
              }}>
              <span style={{ marginRight: "5px" }}>
                <Button
                  onClick={() => handleType("Story")}
                  style={{
                    cursor: "pointer",
                    color: type === "Story" ? "white" : "#616161",
                    backgroundColor: type === "Story" ? "#01579B" : "white",
                    border: type === "Story" ? "1px solid white" : "white",
                  }}
                  ghost>
                  {selectedInterfaceLanguage && (
                    <TranslateWord key={"STORY"} text={"STORY"} lang={selectedInterfaceLanguage} />
                  )}
                </Button>
              </span>
              <span style={{ marginRight: "5px" }}>
                <Button
                  onClick={() => handleType("Lens")}
                  style={{
                    cursor: "pointer",
                    color: type === "Lens" ? "white" : "#616161",
                    backgroundColor: type === "Lens" ? "#01579B" : "white",
                    border: type === "Lens" ? "1px solid white" : "white",
                  }}
                  ghost>
                  {selectedInterfaceLanguage && (
                    <TranslateWord key={"LENS"} text={"LENS"} lang={selectedInterfaceLanguage} />
                  )}
                </Button>
              </span>
              <span style={{ marginRight: "5px" }}>
                <Button
                  onClick={() => handleType("Petitions")}
                  style={{
                    cursor: "pointer",
                    color: type === "Petitions" ? "white" : "#616161",
                    backgroundColor: type === "Petitions" ? "#01579B" : "white",
                    border: type === "Petitions" ? "1px solid white" : "white",
                  }}
                  ghost>
                  {selectedInterfaceLanguage && (
                    <TranslateWord key={"PETITION"} text={"PETITION"} lang={selectedInterfaceLanguage} />
                  )}
                </Button>
              </span>
            </Col>
          </Row>
          <Divider />

          {type !== "Petitions" && (
            <>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "start",
                  marginBottom:'10px'
                }}>
                <Col
                  lg={{
                    span: 24,
                  }}
                  xs={{
                    span: 24,
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "start",
                  }}>
                  <span style={{ marginRight: "5px" }}>
                    <Button
                      onClick={() => handleTabs("Latest")}
                      style={{
                        cursor: "pointer",
                        color: activeKey === "Latest" ? "white" : "#616161",
                        backgroundColor: activeKey === "Latest" ? "#CC0001" : "white",
                        border: activeKey === "Latest" ? "1px solid white" : "white",
                      }}
                      ghost>
                      {selectedInterfaceLanguage && (
                        <TranslateWord key={"LATEST"} text={"LATEST"} lang={selectedInterfaceLanguage} />
                      )}
                    </Button>
                  </span>
                  <span style={{ marginRight: "5px" }}>
                    <Button
                      onClick={() => handleTabs("Popular")}
                      style={{
                        cursor: "pointer",
                        color: activeKey === "Popular" ? "white" : "#616161",
                        backgroundColor: activeKey === "Popular" ? "#CC0001" : "white",
                        border: activeKey === "Popular" ? "1px solid white" : "white",
                      }}
                      ghost>
                      {selectedInterfaceLanguage && (
                        <TranslateWord key={"POPULAR"} text={"POPULAR"} lang={selectedInterfaceLanguage} />
                      )}
                    </Button>
                  </span>
                  <span style={{ marginRight: "5px" }}>
                    <Button
                      onClick={() => handleTabs("Most Discussed")}
                      style={{
                        cursor: "pointer",
                        color: activeKey === "Most Discussed" ? "white" : "#616161",
                        backgroundColor: activeKey === "Most Discussed" ? "#CC0001" : "white",
                        border: activeKey === "Most Discussed" ? "1px solid white" : "white",
                      }}
                      ghost>
                      {selectedInterfaceLanguage && (
                        <TranslateWord key={"MOSTDISCUSSED"} text={"MOSTDISCUSSED"} lang={selectedInterfaceLanguage} />
                      )}
                    </Button>
                  </span>
                </Col>
              </Row>
            </>
          )}

          {type === "Story" && (
            <>
              <Row gutter={[10, 16]}>
                {currentTabData?.data?.length > 0
                  ? currentTabData?.data?.map((record) => {
                      const originalDate = new Date(record?.pubDate);
                      const currentDate = new Date();
                      const timeDifference = currentDate - originalDate;
                      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
                      const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

                      let displayTime;
                      if (timeDifference < 24 * 60 * 60 * 1000) {
                        // Less than 24 hours
                        displayTime =
                          hours > 0 ? `${hours} hrs ago` : minutes > 0 ? `${minutes} min ago` : "Less than a min ago";
                      } else {
                        // More than 24 hours
                        displayTime = originalDate.toLocaleDateString("en-GB"); // Format the date as DD-MM-YYYY
                      }
                      return (
                        <>
                          <Col span={24} xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
                            <Card
                              cover={
                                <Link to={`/about?param1=${record?.id}`} class="img-opacity-hover">
                                  {record?.image_urls?.[0] ? (
                                    <img
                                      src={record?.image_urls?.[0]}
                                      alt="news"
                                      style={{
                                        display: "block",
                                        margin: "0 auto",
                                        maxWidth: "100%",
                                        maxHeight: "30vh",
                                        objectFit: "contain",
                                      }}
                                    />
                                  ) : record?.video_url ? (
                                    <>
                                      <span>
                                        <img style={imageStyle} src={videoPlayIcon} />
                                      </span>{" "}
                                      <img
                                        src={record?.video_thumbnail_url}
                                        alt="news"
                                        style={{
                                          display: "block",
                                          margin: "0 auto",
                                          maxWidth: "100%",
                                          maxHeight: "29vh",
                                          objectFit: "contain",
                                          backgroundColor: "#000000",
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <img src={noImgJPG} alt="No-Image" />
                                    </>
                                  )}
                                </Link>
                              }>
                              <Link to={`/about?param1=${record?.id}`} class="title-medium-dark size-lg mb-none">
                                {record?.title
                                  ?.replace(/<[^>]*>/g, "")
                                  ?.replace(/&nbsp;|&emsp;/g, "")
                                  ?.trim()?.length > 35
                                  ? record?.title
                                      ?.replace(/<[^>]*>/g, "")
                                      ?.replace(/&nbsp;|&emsp;/g, "")
                                      ?.trim()
                                      ?.substring(0, 35) + "..."
                                  : record?.title
                                      ?.replace(/<[^>]*>/g, "")
                                      ?.replace(/&nbsp;|&emsp;/g, "")
                                      ?.trim()}
                              </Link>
                              <Row style={{ margin: "2vh 0vh" }}>
                                <Col span={18}>
                                  <span>
                                    <i className="fa fa-user" aria-hidden="true"></i>
                                  </span>
                                  &nbsp;
                                  <span
                                    onClick={() => {
                                      goToSourceWitAuthorId(record?.user_id);
                                    }}>
                                    {record?.author}
                                  </span>
                                </Col>
                                <Col span={6}>
                                  <strong
                                    className="duration_slider"
                                    style={{
                                      marginTop: "3px",
                                    }}>
                                    {displayTime}
                                  </strong>
                                </Col>
                              </Row>
                              <Row style={{ margin: "2vh 0vh" }}>
                                <Col span={12}>
                                  {record?.emoji_comments?.map((emj) => {
                                    return (
                                      <>
                                        <Badge count={emj?.count} size="small" placement="start">
                                          {/* <Avatar size="small" src={<img style={{width:'25px',height:'25px',cursor:'pointer'}} src={angryIcon}/>} /> */}
                                          {emj?.emoji_name === "angry" && (
                                            <Avatar
                                              size="small"
                                              src={
                                                <img
                                                  style={{
                                                    width: "18px",
                                                    height: "18px",
                                                    cursor: "pointer",
                                                  }}
                                                  src={angryIcon}
                                                />
                                              }
                                            />
                                          )}
                                          {emj?.emoji_name === "love" && (
                                            <Avatar
                                              size="small"
                                              src={
                                                <img
                                                  style={{
                                                    width: "18px",
                                                    height: "18px",
                                                    cursor: "pointer",
                                                  }}
                                                  src={heartIcon}
                                                />
                                              }
                                            />
                                          )}
                                          {emj?.emoji_name === "like" && (
                                            <Avatar
                                              size="small"
                                              src={
                                                <img
                                                  style={{
                                                    width: "18px",
                                                    height: "18px",
                                                    cursor: "pointer",
                                                  }}
                                                  src={likeIcon}
                                                />
                                              }
                                            />
                                          )}
                                          {emj?.emoji_name === "laugh" && (
                                            <Avatar
                                              size="small"
                                              src={
                                                <img
                                                  style={{
                                                    width: "18px",
                                                    height: "18px",
                                                    cursor: "pointer",
                                                  }}
                                                  src={smileGif}
                                                />
                                              }
                                            />
                                          )}
                                          {emj?.emoji_name === "sad" && (
                                            <Avatar
                                              size="small"
                                              src={
                                                <img
                                                  style={{
                                                    width: "18px",
                                                    height: "18px",
                                                    cursor: "pointer",
                                                  }}
                                                  src={cryIcon}
                                                />
                                              }
                                            />
                                          )}
                                          {emj?.emoji_name === "wow" && (
                                            <Avatar
                                              size="small"
                                              src={
                                                <img
                                                  style={{
                                                    width: "18px",
                                                    height: "18px",
                                                    cursor: "pointer",
                                                  }}
                                                  src={wowIcon}
                                                />
                                              }
                                            />
                                          )}
                                        </Badge>
                                      </>
                                    );
                                  })}
                                </Col>
                                <Col span={12}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-around",
                                      alignItems: "center",
                                    }}>
                                    <div style={{ marginTop: "2px" }}>
                                      <Emojis record={record} sendLatestData={getLatestData} />
                                    </div>
                                    <div>
                                      <Tooltip title="Coming soon" color="gray">
                                        <img src={headphoneIcon} className="icons_img" />
                                      </Tooltip>
                                    </div>
                                    <div style={{ marginTop: "2px" }}>
                                      <Tooltip title="Coming soon" color="gray">
                                        <img
                                          src={messageIcon}
                                          alt="icon"
                                          className="icons_img"

                                          // onClick={() => {
                                          //   hangleCommentsDrawer(
                                          //     record
                                          //   );
                                          // }}
                                        />
                                      </Tooltip>
                                    </div>
                                    <div>
                                      <WhatsappShareButton url={`${shareStoryUrl}/about?param1=${record?.id}`}>
                                        <img src={whatsappIcon} alt="icon" />
                                      </WhatsappShareButton>
                                    </div>
                                    <div style={{ marginTop: "2px" }}>
                                      <MorEellipsisIcon
                                        story={record}
                                        sendLatestData={getLatestData}
                                        bookMarkStoryId={record?.id}
                                        page="CategoryNewsPage"
                                      />
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        </>
                      );
                    })
                  : type === "Story" && (
                      <div
                        style={{
                          marginLeft: "auto",
                          marginRight: "auto",
                          textAlign: "center",
                          marginTop: "100px",
                        }}>
                        <Empty />
                      </div>
                    )}
              </Row>
            </>
          )}

          {type === "Petitions" && (
            <>
              <Row gutter={[10, 16]}>
                {currentPetitionTabData?.data?.length > 0
                  ? currentPetitionTabData?.data?.map((record) => {
                      const originalDate = new Date(record?.published_date_time);
                      const currentDate = new Date();
                      const timeDifference = currentDate - originalDate;
                      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
                      const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

                      let displayTime;
                      if (timeDifference < 24 * 60 * 60 * 1000) {
                        // Less than 24 hours
                        displayTime =
                          hours > 0 ? `${hours} hrs ago` : minutes > 0 ? `${minutes} min ago` : "Less than a min ago";
                      } else {
                        // More than 24 hours
                        displayTime = originalDate.toLocaleDateString("en-GB"); // Format the date as DD-MM-YYYY
                      }
                      return (
                        <>
                          <Col span={24} xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
                            <Card
                              cover={
                                <Link
                                  //  to={`/about?param1=${record?.id}`}
                                  class="img-opacity-hover">
                                  {record?.images?.[0]?.image_url ? (
                                    <img
                                      src={record?.images?.[0]?.image_url}
                                      alt="news"
                                      style={{
                                        display: "block",
                                        margin: "0 auto",
                                        maxWidth: "100%",
                                        maxHeight: "30vh",
                                        objectFit: "contain",
                                      }}
                                    />
                                  ) : record?.video?.video_thumbnail_url ? (
                                    <>
                                      <span>
                                        <img style={imageStyle} src={videoPlayIcon} />
                                      </span>{" "}
                                      <img
                                        src={record?.video?.video_thumbnail_url}
                                        alt="news"
                                        style={{
                                          display: "block",
                                          margin: "0 auto",
                                          maxWidth: "100%",
                                          maxHeight: "29vh",
                                          objectFit: "contain",
                                          backgroundColor: "#000000",
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <img src={noImgJPG} alt="No-Image" />
                                    </>
                                  )}
                                </Link>
                              }>
                              <Link
                                // to={`/about?param1=${record?.id}`}
                                class="title-medium-dark size-lg mb-none">
                                {record?.title
                                  ?.replace(/<[^>]*>/g, "")
                                  ?.replace(/&nbsp;|&emsp;/g, "")
                                  ?.trim()?.length > 35
                                  ? record?.title
                                      ?.replace(/<[^>]*>/g, "")
                                      ?.replace(/&nbsp;|&emsp;/g, "")
                                      ?.trim()
                                      ?.substring(0, 35) + "..."
                                  : record?.title
                                      ?.replace(/<[^>]*>/g, "")
                                      ?.replace(/&nbsp;|&emsp;/g, "")
                                      ?.trim()}
                              </Link>
                              <Row style={{ margin: "2vh 0vh" }}>
                               
                                <Col span={24}>
                                  <strong
                                    className="duration_slider"
                                    style={{
                                      marginTop: "3px",
                                    }}>
                                    {displayTime}
                                  </strong>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={24}>
                                <Progress percent={30} size="small" strokeColor='red'/>
                                </Col>
                              </Row>
                              <Row style={{ margin: "2vh 0vh" }}>                              
                                 <Col span={14}>
                                  <span>
                                    <i className="fa fa-user" aria-hidden="true"></i>
                                  </span>
                                  &nbsp;                                
                                    {record?.author}                                 
                                </Col>
                                <Col span={10}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-around",
                                      alignItems: "center",
                                    }}>
                                    {/* <div style={{ marginTop: "2px" }}>
                                      <Emojis record={record} sendLatestData={getLatestData} />
                                    </div> */}
                                    <div>
                                      <Tooltip title="Coming soon" color="gray">
                                        <img src={headphoneIcon} className="icons_img" />
                                      </Tooltip>
                                    </div>
                                    <div style={{ marginTop: "2px" }}>
                                      <Tooltip title="Coming soon" color="gray">
                                        <img
                                          src={messageIcon}
                                          alt="icon"
                                          className="icons_img"

                                          // onClick={() => {
                                          //   hangleCommentsDrawer(
                                          //     record
                                          //   );
                                          // }}
                                        />
                                      </Tooltip>
                                    </div>
                                    <div>
                                      <WhatsappShareButton url={`${shareStoryUrl}/about?param1=${record?.id}`}>
                                        <img src={whatsappIcon} alt="icon" />
                                      </WhatsappShareButton>
                                    </div>
                                    <div style={{ marginTop: "2px" }}>
                                      <MorEellipsisIcon
                                        story={record}
                                        sendLatestData={getLatestData}
                                        bookMarkStoryId={record?.id}
                                        page="CategoryNewsPage"
                                      />
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        </>
                      );
                    })
                  : type === "Petitions" && (
                      <div
                        style={{
                          marginLeft: "auto",
                          marginRight: "auto",
                          textAlign: "center",
                          marginTop: "100px",
                        }}>
                        <Empty />
                      </div>
                    )}
              </Row>
            </>
          )}

{type === "Lens" && (
            <>
              <Row gutter={[14,10]} style={{margin:'0px'}}>
                {currentLensTabData?.data?.length > 0
                  ? currentLensTabData?.data?.map((record) => {                     
                      return (
                        <>
                          <Col  xs={12} sm={8} md={6} lg={4} xl={4} xxl={4} style={{display:'flex',alignContent:'center',justifyContent:'center'}}>
                            
                              <div className="lensCard">  
                                {record?.lens_type === 'Video' && <div className="image-container">  <img style={{borderRadius:'8px'}} src={record?.imageVideoDetails?.video_thumbnail_url}/></div>
                               }                                      
                                {record?.lens_type === 'Images' &&  <img style={{borderRadius:'8px'}} src={record?.imageVideoDetails?.url}/>}                                      
                                {/* {record?.lens_type === 'Images' &&  <img style={{borderRadius:'8px'}} src='https://cdn-dev.politikos.cloud/lens/thumbnailimages/17274331091501989556727911466308.jpg'/>}                                       */}
                                      
                              </div>
                            {record?.lens_type === 'Video' &&  <div style={{backgroundColor:'red'}}>
                             <img src={playBtn} className="play-icon"/>  
                             </div>} 
                            
                          </Col>
                        </>
                      );
                    })
                  : type === "Petitions" && (
                      <div
                        style={{
                          marginLeft: "auto",
                          marginRight: "auto",
                          textAlign: "center",
                          marginTop: "100px",
                        }}>
                        <Empty />
                      </div>
                    )}
              </Row>
            </>
          )}

          <Row justify="end">
            <Col>
             {type === 'Story' && <>
              <Pagination
                className="pull-right"
                showSizeChanger={false}
                current={currentPage}
                pageSize={cardsPerPage}
                total={currentTabData?.totalElements}
                onChange={handlePageChange}
              />
             </>}

             {type === 'Petitions' && <>
              <Pagination
                className="pull-right"
                showSizeChanger={false}
                current={currentPage}
                pageSize={cardsPerPage}
                total={currentPetitionTabData?.totalElements}
                onChange={handlePageChange}
              />
             </>}
             {type === 'Lens' && <>
              <Pagination
                className="pull-right"
                showSizeChanger={false}
                current={currentPage}
                pageSize={cardsPerPage}
                total={currentLensTabData?.totalElements}
                onChange={handlePageChange}
              />
             </>}
            </Col>
          </Row>
          {showCommentsDrawer && (
            <CommentsModal showDrawer={showCommentsDrawer} closeDrawer={handleCloseDrawer} story={storyRecord} />
          )}
        </>
      )}
    </>
  );
}
export default GotoSource;
