import React, { Suspense, lazy, useEffect, useState } from "react";
import DateDisplay from "../Components/Sections/HeaderSection/DateDisplay";

import Navbar from "../Components/Sections/HeaderSection/Navbar";
import { Modal, Button, Form, Input, Row, Col, Tooltip, Divider, Select, Skeleton, Dropdown, Grid } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logoIcon from "../imgaes/logoIcon.svg";
import appleIcon from "../imgaes/appleIcon.svg";
import palystoreIcon from "../imgaes/palystoreIcon.svg";
import podcastIcon from "../imgaes/podcastIcon.svg";
import livetvIcon from "../imgaes/livetvIcon.svg";

import LoginModal from "../../src/Components/Sections/HeaderSection/LoginModal";
import LoginPage from "./Login/LoginPage/LoginPage";
import LoginDetails from "./Login/LoginDetails/LoginDetails";
import OTP from "./Login/OTP/OTP";
import { useAuth0 } from "@auth0/auth0-react";
import TranslateWord from "./sharedComponents/TranslateWord";
import { useDispatch, useSelector } from "react-redux";
import { selectedInterfaceAction } from "../Store/interfaceLanguageSlice";
import { use } from "i18next";
import { selectedfeedAction } from "../Store/feedLanguageSlice";
import { getAllBreakingNewsData } from "../Store/breakingNewsSlice";
import { getAllTrendingStories } from "../Store/trendingSlice";
import { selectedPoliticsInterfaceAction } from "../Store/PoliticsSlice";
import { selectedEntertainmentInterfaceAction } from "../Store/EntertainmentSlice";
import { selectedSportsInterfaceAction } from "../Store/SportsSlice";
import { selectedBusinessInterfaceAction } from "../Store/BusinessSlice"; 
import { selectedTechnologyInterfaceAction } from "../Store/TechnologySlice";
import { userLogout } from "../Store/LoginSlice";
// import StaffVolunteerLogin from "./Login/StaffVolunteerLogin/StaffVolunteerLogin";
import axios from "axios";
import { result } from "lodash";
import BaseUrl from "../utils/config";

// import { useGetAllTrendingMutation } from "../Services/TrendingAPI";
// import { useGetAllActiveCategoryQuery } from "../Services/CategoryAPI";

const LazyWeatherReport = lazy(() => import("../Components/Sections/HeaderSection/WeatherReport"));
const LazyLocation = lazy(() => import("../Components/Sections/HeaderSection/Location"));

let categoryData = [];
const { useBreakpoint } = Grid;
function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let recordId;
  recordId = queryParams.get("param1");
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isLoginDetailsModalOpen, setIsLoginDetailsModalOpen] = useState(false);
  const [isOTPModalOpen, setIsOTPModalOpen] = useState(false);
  // const [staffVolunteerLoginModal, setStaffVolunteerLoginModal] = useState(false); //12
  const [allCategoryData, setAllCategoryData] = useState([]);
  const [selectedInterface, setSelectedInterface] = useState([]);
  const [interfaceLangsList, setInterfaceLangsList] = useState([]);
  const {
    data: interfaceLanguagesData,
    state,
    selectedInterfaceLanguage,
  } = useSelector((state) => state.interfaceLanguageList);
  const { data: feedLanguageData, status, selectedfeedLanguage } = useSelector((state) => state.feedLanguageList);
  const { userSelectedNewsFeedData, userProfileData } = useSelector((state) => state.profileSettingsList);
  const userProfileLanguage = userProfileData?.selected_interface_language
  console.log("userProfileLanguage", userProfileLanguage, selectedInterfaceLanguage)
  let loggedInUserData = JSON.parse(localStorage.getItem("LoggedInUserDetails"));

  useEffect(() => {
    if (selectedInterfaceLanguage?.interface_lang_id) {
      handleChange(selectedInterfaceLanguage?.interface_lang_id);
    }
  }, [selectedInterfaceLanguage?.interface_lang_id]);
  useEffect(() => {
    if (selectedInterfaceLanguage?.interface_lang_id) {
      handleChange(selectedInterfaceLanguage?.interface_lang_id);
    }
  }, []);
  console.log("selectedInterfaceLanguage", selectedInterfaceLanguage);

  // const [saveTrendingAPI] = useGetAllTrendingMutation();
  const { isLoading, isAuthenticated, error, user, loginWithRedirect, logout } = useAuth0();
  const handleLogout = () => {
    dispatch(userLogout(loggedInUserData?.user_id)).then((action) => {
      if (userLogout?.fulfilled?.match(action)) {
        localStorage.removeItem("LoggedInUserDetails");
        localStorage.removeItem("userDetails");
        localStorage.removeItem("googleLoggedInuserDetails");
        localStorage.removeItem("LanguageId");
        localStorage.removeItem("Language");
        navigate(`/`);
      }
    });
  };
  const items = [
    {
      key: "1",
      label: (
        <a>
          {selectedInterfaceLanguage && (
            <TranslateWord key={"PROFILE"} text={"PROFILE"} lang={selectedInterfaceLanguage} />
          )}
        </a>
      ),
      onClick: () => {
        navigate(`/profile`);
      },
    },
    {
      key: "2",
      label: (
        <a>
          {selectedInterfaceLanguage && (
            <TranslateWord key={"LOGOUT"} text={"LOGOUT"} lang={selectedInterfaceLanguage} />
          )}
        </a>
      ),
      // disabled: true,
      onClick: handleLogout,
    },
  ];

  console.log("langList", interfaceLangsList);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleLogin = () => {
    setIsLoginModalOpen(true);
  };

  const handleCloseLoginModel = (data) => {
    setIsLoginModalOpen(data);
  };
  const hangleLoginDetialModelPopup = (data) => {
    setIsLoginDetailsModalOpen(data);
  };
  const handleOpeningLoginDetailsModel = (data) => {
    setIsLoginDetailsModalOpen(true);
  };
  const handleOpeningOTPModel = () => {
    setIsOTPModalOpen(true);
  };

  // const handleStaffVolunteerLoginModel = () => {
  //   setStaffVolunteerLoginModal(true)
  // }

  const updateInterface = (interfaceL) => {
    setSelectedInterface(interfaceL);
  };
  // let selectedIngerfaceLang = [];
  const handleChange = (value) => {
    changeInterfaceLang(value)
    localStorage.setItem("LanguageId", value);
    const selectedIngerfaceLang = interfaceLanguagesData?.filter((lang) => {
      if (lang?.interface_lang_id == value) {
        return lang;
      }
    });

    let feedData = [];
    if (feedLanguageData?.length === 0) feedData = JSON.parse(localStorage.getItem("feedDataInLocalStroage"));
    else feedData = [...feedLanguageData];
    const userSelectedFeedLanguages = feedData?.filter((feed) => {
      return feed?.name === selectedIngerfaceLang?.[0]?.name;
    });
    console.log("feedLa", userSelectedFeedLanguages);

    let feedLangPayload = [];
    if (loggedInUserData?.user_id) {
      feedLangPayload = userSelectedNewsFeedData?.filter((item) => item.selected)?.map((item) => item.newsfeed_lang_id);
    } else {
      feedLangPayload = userSelectedFeedLanguages?.map((feedL) => {
        return feedL?.newsfeed_lang_id;
      });
    }

    console.log("feedLangPayload", feedLangPayload);
    const breakingNewsPayload = {
      news_feed_lang_ids: feedLangPayload,
      page: 0,
      pageSize: 50,
      login_user_id: loggedInUserData?.user_id,
    };
    const trendingpayload = {
      news_feed_lang_ids: feedLangPayload,
      page: 0,
      pageSize: 50,
      login_user_id: loggedInUserData?.user_id,
    };
    // const politicsPayload = {
    //   category_name: "Politics",
    //   news_feed_lang_ids: feedLangPayload,
    //   page: 0,
    //   pageSize: 9,

    // };
    if (feedLangPayload?.length) {
      dispatch(selectedfeedAction(userSelectedFeedLanguages));
      dispatch(selectedInterfaceAction(selectedIngerfaceLang?.[0]));
      dispatch(selectedPoliticsInterfaceAction(selectedIngerfaceLang?.[0]));
      dispatch(selectedEntertainmentInterfaceAction(selectedIngerfaceLang?.[0]));
      dispatch(selectedSportsInterfaceAction(selectedIngerfaceLang?.[0]));
      dispatch(selectedBusinessInterfaceAction(selectedIngerfaceLang?.[0]));
      dispatch(selectedTechnologyInterfaceAction(selectedIngerfaceLang?.[0]));
      dispatch(getAllTrendingStories(trendingpayload));
      dispatch(getAllBreakingNewsData(breakingNewsPayload));
    }

    // setSelectedInterface(selectedIngerfaceLang);
    // updateInterface(selectedIngerfaceLang);
    // getTrendingDataFunc()

    if(!recordId){
      navigate("/loading");
      setTimeout(() => {
        navigate("/");
      }, 300);
    }
   
  };

  const handlePlayStore = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=cloud.politikos.mob_app_prod.twa&pcampaignid=web_share",
      "_blank"
    );
  };

  const handleIOSplaystore = () => {
    window.open("https://m.politikos.in/", "_blank");
  };

  const handlePodcastIcon = () => {
    window.open("https://www.youtube.com/@PolitikosMedia", "_blank");
  };
  const handleLiveTvIcon = () => {
    window.open("https://www.youtube.com/@PolitikosMedia", "_blank");
  };

  // useEffect(()=>{
  //   setSelectedInterface(selectedIngerfaceLang)
  //   localStorage.setItem("interfaceLangObj", selectedIngerfaceLang)},[localStorage.getItem("LanguageId")])


  const changeInterfaceLang = (value) => {
    console.log("valueww", value)
    const payload={
      
      code: selectedInterfaceLanguage?.code ,
      interface_caption: selectedInterfaceLanguage?.interface_caption,
      interface_lang_id: selectedInterfaceLanguage?.interface_lang_id,
      name: selectedInterfaceLanguage?.name,
      selected: true,
      user_id: loggedInUserData?.user_id  
      
      
      // "name":"English","code":"en","interface_caption":"welcome","interface_lang_id":10,"selected":true,"user_id":236
      // "name":"తెలుగు","code":"te","interface_caption":"welcome","interface_lang_id":20,"selected":true,"user_id":236
    }
    // console.log("value--", value, selectedInterfaceLanguage)

    axios.post(`${BaseUrl}/user/inteface/lan/select`, payload).then((response) => {
      // console.log("res123", response)
      return response.data
    }).catch((error) => {
      console.log(error)
    })
  }
  return (
    <>
      <Row>
     {(screens.md || screens.lg ||screens.xl || screens.xxl) && <>
      <Col span={24}>
          <div className="header_bg">
            <Row>
              <Col
                className="header-height-sm"
                xs={7}
                sm={7}
                md={4}
                lg={4}
                xl={4}
                xxl={4}
                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Link to="/">
                  {" "}
                  <img src={logoIcon} alt="logo" style={{ textAlign: "center" }} />
                </Link>
              </Col>
              <Col className="header-height-sm" xs={16} sm={16} md={20} lg={20} xl={20} xxl={20}>
                <Row>
                  <Col offset={1} span={23} style={{ height: "10vh" }}>
                    <Row className="hide-on-small">
                      <Col span={15}>
                        {" "}
                        <DateDisplay /> |{" "}
                        <Suspense fallback={"Loading..."}>
                          <LazyLocation />
                        </Suspense>{" "}
                        |
                        {/* <li>
                      <Suspense fallback={"Loading..."}>
                        <LazyWeatherReport />
                      </Suspense>
                    </li> */}
                      </Col>
                      <Col span={9} style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                        <span className="downloadAppIcon">
                          {selectedInterfaceLanguage && (
                            <TranslateWord
                              key={"DOWNLOADAPPON"}
                              text={"DOWNLOADAPPON"}
                              lang={selectedInterfaceLanguage}
                            />
                          )}
                        </span>
                        <span>
                          {" "}
                          <img
                            src={palystoreIcon}
                            alt="playstore"
                            style={{ cursor: "pointer" }}
                            onClick={handlePlayStore}
                          />
                        </span>
                        <span>
                          <Tooltip
                            // title={
                            //   selectedInterfaceLanguage && (
                            //     <TranslateWord key={"appleIcon"} text={"COMINGSOON"} lang={selectedInterfaceLanguage} />
                            //   )
                            // }
                            color="gray">
                            <img
                              src={appleIcon}
                              alt="applestore"
                              style={{ cursor: "pointer" }}
                              onClick={handleIOSplaystore}
                            />
                            {/* <Divider type="vertical" /> */}
                          </Tooltip>
                        </span>
                        <span>&nbsp;|&nbsp;</span>
                        <span>
                          <Tooltip
                            // title={
                            //   selectedInterfaceLanguage && (
                            //     <TranslateWord
                            //       key={"podcastIcon"}
                            //       text={"COMINGSOON"}
                            //       lang={selectedInterfaceLanguage}
                            //     />
                            //   )
                            // }
                            color="gray">
                            <img
                              src={podcastIcon}
                              alt="podcast"
                              onClick={handlePodcastIcon}
                              style={{
                                cursor: "pointer",
                                marginTop: "2px",
                              }}
                            />
                          </Tooltip>
                        </span>
                        <span>
                          <Tooltip
                            // title={
                            //   selectedInterfaceLanguage && (
                            //     <TranslateWord
                            //       key={"livetvIcon"}
                            //       text={"COMINGSOON"}
                            //       lang={selectedInterfaceLanguage}
                            //     />
                            //   )
                            // }
                            color="gray">
                            <img src={livetvIcon} onClick={handleLiveTvIcon} alt="Live" style={{ cursor: "pointer" }} />
                          </Tooltip>
                        </span>
                        <span>
                          {selectedInterfaceLanguage && (
                            <Select
                              size="small"
                              defaultValue={selectedInterfaceLanguage?.interface_lang_id}
                              style={{
                                width: 95,
                                position: "relative",
                                top: "2px",
                              }}
                              onChange={handleChange}>
                              {interfaceLanguagesData?.map((lang) => {
                                return (
                                  <>
                                    <Select.Option value={lang?.interface_lang_id} >{lang?.name}</Select.Option>
                                  </>
                                );
                              })}
                            </Select>
                          )}
                        </span>
                      </Col>
                    </Row>
                  </Col>

                  <Col span={24} style={{ height: "10vh" }} className="header_position_sm">
                    <Row>
                      <Col span={18}>
                        <div className="ne-main-menu">
                          <Navbar
                            key={selectedInterface?.[0]?.name}
                            selectedInterfaceLang={selectedInterface}
                            categoryDataResponse={[]}
                          />
                        </div>
                      </Col>
                      <Col span={2}>
                        <span className="searchIconHide hide-on-small iconPosition">
                          <span className="search-button">
                            <i className="fa fa-search" aria-hidden="true"></i>
                          </span>
                        </span>
                        &emsp;
                        <span className="hide-on-small iconPosition">
                          <Tooltip
                            title={<TranslateWord text={"COMINGSOON"} lang={selectedInterfaceLanguage} />}
                            color="gray">
                            <a>
                              <i className="fa fa-bars" aria-hidden="true"></i>
                            </a>
                          </Tooltip>
                        </span>
                      </Col>
                      <Col
                        span={4}
                        style={{
                          position: "relative",
                          top: JSON.parse(localStorage.getItem("userDetails"))?.first_name ? "-22px" : "-6px",
                        }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                          <span className="sign_fun" style={{ cursor: "pointer" }}>
                            <span className="hide-on-small">| &nbsp;</span>
                            <i className="fa fa-user hide-on-small" aria-hidden="true"></i>
                            &nbsp;
                            {/* {loggedInUserData?.user_id ? (
                            <>
                              <Dropdown
                                menu={{
                                  items,
                                }}
                                placement="bottomLeft"
                                arrow>
                                <Button type="text" style={{ padding: "0px" }}>
                                  {" "}
                                  {JSON.parse(localStorage.getItem("userDetails"))?.first_name}
                                </Button>
                              </Dropdown>
                            </>
                          ) : (
                            selectedInterfaceLanguage && (
                              <span onClick={handleLogin}>
                                <TranslateWord text={"SIGNIN"} key={"SIGNIN"} lang={selectedInterfaceLanguage} />
                              </span>
                            )
                          )} */}
                            {loggedInUserData?.user_id ? (
                              <>
                                <Dropdown
                                  menu={{
                                    items,
                                  }}
                                  placement="bottomLeft"
                                  arrow>
                                  <span className="first-name-display">
                                    <Button type="text" style={{ padding: "0px", marginTop: "5px" }}>
                                      {" "}
                                      {JSON.parse(localStorage.getItem("userDetails"))?.first_name}
                                    </Button>
                                  </span>
                                </Dropdown>
                              </>
                            ) : (
                              selectedInterfaceLanguage && (
                                <Tooltip
                                  title={
                                    <TranslateWord text={"SIGNIN"} key={"SIGNIN"} lang={selectedInterfaceLanguage} />
                                  }
                                  color="gray">
                                  <span onClick={handleLogin} className="sign_in_style" title="">
                                    <TranslateWord text={"SIGNIN"} key={"SIGNIN"} lang={selectedInterfaceLanguage} />
                                  </span>
                                </Tooltip>
                              )
                            )}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
     </>}   
    
       {screens.xs && <>
        <Col span={24}>
          <div className="header_bg_sm">
            <Row>
              <Col span={12} style={{marginTop:'2vh'}}>               
                <Link to="/">
                  <img src={logoIcon} alt="logo" style={{ textAlign: "center" }} />
                </Link>
              </Col>
              <Col span={12} style={{display:'flex',justifyContent:'flex-end',marginTop:'2.5vh'}}>
              <div>
               <span>
                          {selectedInterfaceLanguage && (
                            <Select
                              size="small"                              
                              defaultValue={selectedInterfaceLanguage?.interface_lang_id}
                              style={{
                                width: 95,
                                position: "relative",
                                top: "-12px",
                                left:'70%'
                              }}
                              onChange={handleChange}>
                              {interfaceLanguagesData?.map((lang) => {
                                return (
                                  <>
                                    <Select.Option value={lang?.interface_lang_id} >{lang?.name}</Select.Option>
                                  </>
                                );
                              })}
                            </Select>
                          )}
                        </span>
              </div>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <span className="sign_fun" style={{ cursor: "pointer" }}>                  
                    <i className="fa fa-user" aria-hidden="true"></i>
                    &nbsp;
                    {loggedInUserData?.user_id ? (
                      <>
                        <Dropdown
                          menu={{
                            items,
                          }}
                          placement="bottomLeft"
                          arrow>
                          <span >
                            <Button type="text" style={{ padding: "0px", marginTop: "15px" }}>                              
                              {JSON.parse(localStorage.getItem("userDetails"))?.first_name}                            
                  
                            </Button>
                          </span>
                        </Dropdown>
                      </>
                    ) : (
                      selectedInterfaceLanguage && (
                        <Tooltip
                          title={<TranslateWord text={"SIGNIN"} key={"SIGNIN"} lang={selectedInterfaceLanguage} />}
                          color="gray">
                          <span onClick={handleLogin} className="sign_in_style" title="">
                            <TranslateWord text={"SIGNIN"} key={"SIGNIN"} lang={selectedInterfaceLanguage} />
                          </span>
                        </Tooltip>
                      )
                    )}
                    &emsp;
                  </span>
                </div>
              </Col>
            </Row>
            <Row style={{marginTop:'1.5vh'}}>
              <Col span={24}>              
                <div className="ne-main-menu">
                  <Navbar
                    key={selectedInterface?.[0]?.name}
                    selectedInterfaceLang={selectedInterface}
                    categoryDataResponse={[]}
                  />
                </div>
              </Col>{" "}
            </Row>
          </div>
        </Col>
       </>} 
      
      </Row>
      {isLoginModalOpen && (
        <LoginPage
          openFlag={isLoginModalOpen}
          closeLoginModel={handleCloseLoginModel}
          openingLoginDetailsModel={handleOpeningLoginDetailsModel}
        />
      )}
      {isLoginDetailsModalOpen && (
        <LoginDetails
          openFlag={isLoginDetailsModalOpen}
          closeLoginDetailsModel={hangleLoginDetialModelPopup}
          openingOTPlsModel={handleOpeningOTPModel}
        />
      )}
      {isOTPModalOpen && <OTP openFlag={isOTPModalOpen} closeLoginModel={handleCloseLoginModel} sendingFrom="signIn" />}


      {/* {staffVolunteerLoginModal && (
        <StaffVolunteerLogin
        openFlag={staffVolunteerLoginModal}
        closeStaffVolntrModel={handleCloseLoginModel}
        openingStaffVolunteerLoginModel = {handleStaffVolunteerLoginModel}
         />
      )} */}
    </>
  );
}

export default Header;
