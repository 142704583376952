import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import BaseUrl from "../utils/config";
const initialState = { 
  status: "idle",
  error:'', 
  autoPlayStatus:false
};

const AutoPlaySlice = createSlice({
  name: "Autoplay",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateAutoPlayData.pending, (state,action) => {       
          state.status = "loading";
      });
    builder.addCase(updateAutoPlayData.fulfilled, (state, action) => {    
      state.status = "idle";
    });
    builder.addCase(getAutoPlayData.pending, (state,action) => {       
      state.status = "loading";
  });
builder.addCase(getAutoPlayData.fulfilled, (state, action) => {    
  state.autoPlayStatus = action?.payload?.data;
  state.status = "idle";
});
  },
});

export const updateAutoPlayData = createAsyncThunk("updateAutoPlayData/POST", async (payload) => {
  const data = await fetch(`${BaseUrl}/user/scrollable`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  const result = await data.json();
  return result;
});

export const getAutoPlayData = createAsyncThunk(
  "getAutoPlayData/GET",
  async (user_id) => {
    const data = await fetch(`${BaseUrl}/user/getscrollable/${user_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await data.json();
    return result;
  }
);

export default AutoPlaySlice.reducer;
