import React, { useEffect, useState, useRef } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip, Empty, Badge, Row, Col, Skeleton, Carousel, Grid, Card, Button } from "antd";
import { Menu, Typography, Dropdown, Space } from "antd";
import { DownOutlined, GlobalOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import smileGif from "../../../imgaes/smileGif.gif";
import cryIcon from "../../../imgaes/cryIcon.gif";
import heartIcon from "../../../imgaes/heartIcon.gif";
import angryIcon from "../../../imgaes/angryIcon.gif";
import likeIcon from "../../../imgaes/likeIcon.gif";
import wowIcon from "../../../imgaes/wowIcon.gif";
import noImg from "../../../imgaes/noImg.svg";
import noImgJPG from "../../../imgaes/noImgJPG.jpg";
import smileIcon from "../../../imgaes/smileIcon.svg";
import messageIcon from "../../../imgaes/messageIcon.svg";
import whatsappIcon from "../../../imgaes/whatsappIcon.svg";
import headphoneIcon from "../../../imgaes/headphoneIcon.svg";
import videoPlayIcon from "../../../imgaes/videoPlayIcon.svg";
import ReactPlayer from "react-player/lazy";

import MorEellipsisIcon from "../../Sections/Common/MorEellipsisIcon";
import Emojis from "../../sharedComponents/Emojis";
import { WhatsappShareButton } from "react-share";
import { shareStoryUrl } from "../../../utils/config";
import CommentsModal from "../../sharedComponents/CommentsModal";
import { useDispatch, useSelector } from "react-redux";
import { getAllVideosData } from "../../../Store/VideosSlice";
import LoadingSpinner from "../../sharedComponents/LoadingSpinner";
const { Title } = Typography;
const { Meta } = Card;
const { useBreakpoint } = Grid;

const VideosSection = ({ categoryName, subCategoryName, sectionInterfaceLang }) => {
  console.log("sectionInterfaceLang", sectionInterfaceLang);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const carouselRef = useRef(null);
  const screens = useBreakpoint();
  const [splideAutoScroll, setSplideAutoScroll] = useState(true);
  const [showCommentsDrawer, setShowCommentsDrawer] = useState(false);
  const [storyRecord, setStoryRecord] = useState(null);
  const [current, setCurrent] = useState("all");
  const [currentIndex, setCurrentIndex] = useState(0);
  let loggedInUserData = JSON.parse(localStorage.getItem("LoggedInUserDetails"));
  const { userSelectedNewsFeedData } = useSelector((state) => state.profileSettingsList);

  const { data: allVideosData, status, error, videosSelectedLang } = useSelector((state) => state.videosList);
  const [selectedRecord, setSelectedRecord] = useState(
    allVideosData && allVideosData?.data && allVideosData?.data?.length ? allVideosData.data[0] : {}
  );

  const { data: feedLanguageData, selectedfeedLanguage } = useSelector((state) => state.feedLanguageList);
  const { autoPlayStatus } = useSelector((state) => state.autoPlayList);
  console.log("allVideosData", allVideosData);
  console.log("allTechnologyData", status);
  const imageStyle = {
    position: "absolute",
    top: "33%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40px",
    height: "40px",
    zIndex: 666,
  };

  const gettingData = () => {
    const userSelectedFeedLanguages = feedLanguageData?.filter((feed) => {
      return feed?.name === videosSelectedLang?.name;
    });
    console.log("feedLa", userSelectedFeedLanguages);

    let feedLangPayload = [];
    if (loggedInUserData?.user_id) {
      feedLangPayload = userSelectedNewsFeedData?.filter((item) => item.selected)?.map((item) => item.newsfeed_lang_id);
    } else {
      feedLangPayload = userSelectedFeedLanguages?.map((feedL) => {
        return feedL?.newsfeed_lang_id;
      });
    }

    const payload = {
      login_user_id: loggedInUserData?.user_id, //loggedInUserData?.user_id
      news_feed_lang_ids: feedLangPayload,
      category_name: categoryName,
      page: 0,
      pageSize: 9,
      scetion_name: null,
    };
    if (
      categoryName === "Trending" ||
      categoryName === "All" ||
      categoryName === "ట్రెండింగ్" ||
      categoryName === "అన్ని"
    ) {
      delete payload.category_name;
      payload.scetion_name = categoryName;
      if (categoryName === "Trending" || categoryName === "ట్రెండింగ్") {
        payload.scetion_name = "Trending";
      }
    }

    if (!loggedInUserData?.user_id) {
      // const payload = {
      //   login_user_id: 236,
      //   newsfeed_language_ids: [20, 60, 70],
      //   page: 0,
      //   pageSize: 9,
      //   scetion_name: "All",
      // };
      delete payload.login_user_id;
    }
    // if (subCategoryName === "All" || subCategoryName === "అన్ని") {
    //   delete payload.sub_category_name;
    // }
    // if (payload?.news_feed_lang_ids?.length)
    dispatch(getAllVideosData(payload));
  };
  useEffect(() => {
    gettingData();
  }, [dispatch, videosSelectedLang, subCategoryName, selectedfeedLanguage]);

  if (status === "loading") {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  // const goToSourceWithId = (id) => {
  //   navigate(`/about?param1=${id}&category=${categoryName}&subcategory=${subCategoryName}`);
  //   window.scrollTo(0, 0);
  // };
  const goToSourceWithId = (id) => {
    navigate(`/videodetails?param1=${id}&category=${categoryName}&subcategory=${subCategoryName}`);
    window.scrollTo(0, 0);
  };

  const goToSourceWitAuthorId = (input_user_id) => {
    window.scrollTo(0, 0);
    navigate(`/GotoSource?param1=${input_user_id}&activeTab=${"Latest"}`);
  };
  const handleEmojiSelect = () => {
    setSplideAutoScroll(false);
  };
  const getLatestData = (data) => {
    gettingData();
  };
  const navigateTo = (rec) => {
    navigate(`/about?param1=${rec.id}&category=${categoryName}&subcategory=${subCategoryName}`);
  };

  const hangleCommentsDrawer = (rec) => {
    setShowCommentsDrawer(true);
    setStoryRecord(rec);
  };

  const handleCloseDrawer = () => {
    setShowCommentsDrawer(false);
    setStoryRecord(null);
  };

  const languageMenu = (
    <Menu>
      <Menu.Item key="1">English</Menu.Item>
      <Menu.Item key="2">Hindi</Menu.Item>
      <Menu.Item key="3">Tamil</Menu.Item>
    </Menu>
  );
  // const handleScroll = (direction) => {
  //   const container = document.getElementById("news-container");
  //   if (container) {
  //     const scrollAmount = 400;
  //     const scrollPosition =
  //       direction === "left" ? container.scrollLeft - scrollAmount : container.scrollLeft + scrollAmount;
  //     container.scrollTo({
  //       left: scrollPosition,
  //       behavior: "smooth",
  //     });
  //   }
  // };

  const handleScroll = (direction) => {
    const container = document.getElementById("news-container");
    if (container) {
      const scrollAmount = 400;
      const scrollPosition =
        direction === "left" ? container.scrollLeft - scrollAmount : container.scrollLeft + scrollAmount;
      container.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }

    if (direction === "left") {
      setCurrentIndex((prev) => Math.max(0, prev - 1));
    } else {
      setCurrentIndex((prev) => Math.min(allVideosData?.data?.length - 1, prev + 1));
    }
  };

  return (
    <>
      {status === "idle" && allVideosData?.data?.length === 0 ? (
        <div className="item" style={{ paddingTop: "20px", paddingBottom: "10px" }}>
          <div className="img-overlay-69 img-scale-animate main_slider_tabs  main_slider_all_politics">
            <div
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                textAlign: "center",
                marginTop: "100px",
                marginBottom: "70px",
              }}>
              <Empty />
              <h4 style={{ color: "white" }}>No data </h4>
            </div>
          </div>
        </div>
      ) : (
        <div className="news-cards-wrapper" style={{ marginTop: screens.xs ? "0px" : "-30px" }}>
          {screens.xs ? (
            ""
          ) : (
            <>
              {currentIndex > 0 && (
                <Button
                  style={{ marginLeft: "10px" }}
                  className="scroll-button left"
                  icon={<LeftOutlined />}
                  onClick={() => handleScroll("left")}
                />
              )}
              {currentIndex < allVideosData?.data?.length - 4 && ( //-4 instead of -1
                <Button
                  style={{ marginRight: "10px" }}
                  className="scroll-button right"
                  icon={<RightOutlined />}
                  onClick={() => handleScroll("right")}
                />
              )}
            </>
          )}
          {/* <Button className="scroll-button left" icon={<LeftOutlined />} onClick={() => handleScroll("left")} />
          <Button className="scroll-button right" icon={<RightOutlined />} onClick={() => handleScroll("right")} /> */}
          <div id="news-container" className="news-cards-container">
            {allVideosData?.data?.map((item) => (
              <Card
                key={item?.id}
                hoverable
                onClick={() => {
                  goToSourceWithId(item?.id);
                }}
                cover={
                  <>
                    <span>
                      <img style={imageStyle} src={videoPlayIcon} />
                    </span>{" "}
                    <img alt={item?.video_title} src={item?.video_thumbnail_url} />
                  </>
                }
                className="news-card">
                <Card.Meta className="news-card-title" title={item?.video_title} />
              </Card>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default VideosSection;
