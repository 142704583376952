// import { configureStore } from '@reduxjs/toolkit';
// // Or from '@reduxjs/toolkit/query/react'
// import { setupListeners } from '@reduxjs/toolkit/query';
// //import { postLoginApi } from '../services/post';
// import { TrendingAPI } from '../Services/TrendingAPI';
// import { CategoryAPI } from '../Services/CategoryAPI';
// import { OtpAPI } from '../Services/OtpAPI';
// import { GmailLoginAPI } from '../Services/GmailLoginAPI';
// import { LanguageAPI } from '../Services/LanguageAPI';
// import { ChattingAPI } from '../Services/ChattingAPI';

// export const store = configureStore({
//   reducer: {
//        [TrendingAPI.reducerPath]: TrendingAPI.reducer,
//        [CategoryAPI.reducerPath]: CategoryAPI.reducer,
//        [OtpAPI.reducerPath]: OtpAPI.reducer,
//        [GmailLoginAPI.reducerPath]: GmailLoginAPI.reducer,
//        [LanguageAPI.reducerPath]: LanguageAPI.reducer,
//        [ChattingAPI.reducerPath]: ChattingAPI.reducer,
//   },

//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({ serializableCheck: false, immutableCheck: false })
//       .concat(TrendingAPI.middleware)
//       .concat(CategoryAPI.middleware)
//       .concat(OtpAPI.middleware)
//       .concat(GmailLoginAPI.middleware)
//       .concat(LanguageAPI.middleware)
//       .concat(ChattingAPI.middleware)
// });

// setupListeners(store.dispatch)

import { configureStore } from "@reduxjs/toolkit";
import forYouSlice from "./forYouSlice";
import trendingSlice from "./trendingSlice";
import interfaceLanguageSlice from "./interfaceLanguageSlice";
import categoryWiseSlice from "./categoryWiseSlice";
import breakingNewsSlice from "./breakingNewsSlice";
import feedLanguageSlice from "./feedLanguageSlice";
import categoriesSlice from "./categoriesSlice";
import PoliticsSlice from "./PoliticsSlice";
import EntertainmentSlice from "./EntertainmentSlice";
import SportsSlice from "./SportsSlice";
import LivetvSlice from "./LivetvSlice";
import VideosSlice from "./VideosSlice";
import BusinessSlice from "./BusinessSlice";
import GotoSourceSlice from "./GotoSourceSlice";
import TechnologySlice from "./TechnologySlice";
import EmojiSlice from "./EmojiSlice";
import StoryDetailsSlice from "./StoryDetailsSlice";
import LivetvdetailsSlice from "./LivetvdetailsSlice";
import BookmarkedStorySlice from "./BookmarkedStorySlice";
import BookmarkedLivetvSlice from "./BookmarkedLivetvSlice";
import LoginSlice from "./LoginSlice";
import ProfileSettingsSlice from "./ProfileSettingsSlice";
import GenderSlice from "./GenderSlice";
import MaritalStatusSlice from "./MaritalStatusSlice";
import SubmissionStoriesSlice from "./SubmissionStoriesSlice";
import SubmissionPetitionsSlice from "./SubmissionPetitionsSlice";
import SubmissionGrievanceSlice from "./SubmissionGrievanceSlice";
import JoinVolunteerSlice from "./JoinVolunteerSlice";
import AutoPlaySlice from "./AutoPlaySlice";

const store = configureStore({
  reducer: {
    forYouList: forYouSlice,
    trendingList: trendingSlice,
    interfaceLanguageList: interfaceLanguageSlice,
    categoryWiseList: categoryWiseSlice,
    breakingList: breakingNewsSlice,
    feedLanguageList: feedLanguageSlice,
    categoriesList: categoriesSlice,
    politicsList: PoliticsSlice,
    entertainmentList: EntertainmentSlice,
    sportsList: SportsSlice,
    livetvList: LivetvSlice,
    videosList: VideosSlice,
    businessList: BusinessSlice,
    technologyList: TechnologySlice,
    gotoSourceList: GotoSourceSlice,
    emojisList: EmojiSlice,
    storyDetailsPageList: StoryDetailsSlice,
    livetvDetailsPageList: LivetvdetailsSlice,
    bookmarkStoryList: BookmarkedStorySlice,
    bookmarkLivetvList: BookmarkedLivetvSlice,
    LoginList: LoginSlice,
    profileSettingsList: ProfileSettingsSlice,
    genderList: GenderSlice,
    maritalStatusList: MaritalStatusSlice,
    submissionStoriesList: SubmissionStoriesSlice,
    submissionPetitionsList: SubmissionPetitionsSlice,
    submissionGrievanceList: SubmissionGrievanceSlice,
    joinVolunteerList: JoinVolunteerSlice,
    autoPlayList: AutoPlaySlice,
  },
});

export default store;
