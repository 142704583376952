import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import {
//     useGetAllActiveCategoryQuery    
// } from '../../../../src/Services/CategoryAPI';
import Dropdown from "./Dropdown";
import { Tooltip } from 'antd';
import MoreOptions from './MoreOptions';
import TranslateWord from '../../sharedComponents/TranslateWord';
import { teluguFontStyle } from '../../../Translation/Words';
import { useSelector } from 'react-redux';



let feedLangData = []
let selectedIngerface = []
const Navbar = ({selectedInterfaceLang,categoryDataResponse}) =>{
  const {data:categoryData} = useSelector(state => state.categoriesList);
  const [visibleItems, setVisibleItems] = useState(7);
  const {
    data: interfaceLanguagesData,
    state,
    selectedInterfaceLanguage,
  } = useSelector((state) => state.interfaceLanguageList);
  console.log('categoryData',categoryData)
  const [navBarData, setNavBarData] = useState([]);
  const [dropdown, setDropdown] = useState(false);
  const [selected, setSelected] = useState("");

  // const { data: categoryDataResponse, isLoading: isLoading } =
  //   useGetAllActiveCategoryQuery();

  // const categoryData = categoryDataResponse?.data ?? []
  // localStorage.setItem('allCategoriesList',JSON.stringify(categoryDataResponse?.data ))
  const updateVisibleItems = () => {
    if (window.innerWidth >= 1250) {
      setVisibleItems(7); // Large screen
    } else if (window.innerWidth >= 800) {
      setVisibleItems(5); // Medium screen
    } else if (window.innerWidth >= 700) {
      setVisibleItems(3); // Medium screen
    }
    else {
      setVisibleItems(3); // Small screen
    }
  };
  useEffect(() => {
    updateVisibleItems(); // Initial call
    window.addEventListener("resize", updateVisibleItems);

    return () => {
      window.removeEventListener("resize", updateVisibleItems);
    };
  }, []);
 
  const gettingNavData = () =>{
    // feedLangData =
    // JSON.parse(localStorage.getItem("feedLanguagesData")) ?? [];
  // selectedIngerface = feedLangData?.filter((cat) => {
  //   if (cat?.newsfeed_lang_id == JSON.parse(localStorage.getItem("LanguageId"))) {
  //     return cat;
  //   }
  // });
    const navbarData = categoryData
    ?.map((data, i) => {
      let obj = {
        id: i + 1,
        title: data.category_name,
        path: `/${data.category_name}`,
        cName: "nav-item",
        Language: data.interface_language?.name,
        subCategoryDropdown: [],
      };

      if (data?.str_sub_categories_names?.length > 0) {
        const subCatData = data?.str_sub_categories_names?.map((sub, i) => {
          let subObj = {
            id: i + 1,
            title: sub,
            path: `/${data.category_name}/${sub}`,
            cName: "submenu-item",
          };
          return subObj;
        });
        obj.subCategoryDropdown = subCatData;
      }
      return obj;
    })
    .filter((val) => {
      return val.Language === selectedInterfaceLanguage?.name;
    }).filter((val) => {
      return val.title !== 'Breaking News';
    });
     const forUobj = {
      Language : selectedInterfaceLanguage?.name,
      cName : "nav-item",
      id : 'forU',
      path : "/foryou",
      subCategoryDropdown :  []   , 
      title : <TranslateWord key={'foryou'} text={"FORYOU"} lang={selectedInterfaceLanguage} />
    }
    navbarData?.unshift(forUobj)
  console.log({ navbarData });
  setNavBarData(navbarData)
  }

  useEffect(()=>{
    gettingNavData()
  },[categoryData])
  useEffect(()=>{
    gettingNavData()
  },[selectedInterfaceLanguage])
 




 

  // const newsbData = categoryDataResponse?.data?.filter(
  //   (item) => item.category_name === "Business"
  // );
  // console.log("newsbData ddd", newsbData);

 

  // const newsData = categoryData?.data?.filter(record =>
  //     record.categories.some(category => category.name === 'Politics')
  // );

  return (
    <>
      <nav className="header-navbar">
        <ul className="nav-items">
          {navBarData?.slice(0, visibleItems)?.map((item) => {
            if (item?.subCategoryDropdown?.length > 0) {
              return (
                <li
                  key={item.id}
                  className={item?.cName}
                  onMouseEnter={() =>{setDropdown(true);setSelected(item?.title)}             
                  }
                   onMouseLeave={() => {setDropdown(false);setSelected("")}}
                   style={{height:'7vh'}}
                >
                 
                 <Link className='HeaderLinks' to={item.path}><span style={selectedInterfaceLanguage?.name === 'తెలుగు' ? teluguFontStyle :{}}>{item.title}</span></Link>
                  {dropdown && item?.title === selected && (
                    <Dropdown interfaceLang={selectedInterfaceLanguage?.name} servicesData={item?.subCategoryDropdown} />
                  )}
                </li>
              );
            }
            return (
              <li key={item?.id} className={item?.cName}>
                <Link to={item?.path}><span style={selectedInterfaceLanguage?.name === 'తెలుగు' ? teluguFontStyle :{}}>{item.title}</span></Link>
              </li>
            );
          })}
          <li>        
          {navBarData?.length > visibleItems && <MoreOptions moreOptionsData = {navBarData?.slice(visibleItems, navBarData?.length)}/>}
          </li>
          {/* className="d-none d-sm-block" */}
          {/* <li style={{position:'relative',left:'30px'}} className='searchIconHide'>
            <form id="top-search-form" className="header-search-dark">
              <input
                type="text"
                className="search-input"
                placeholder="Search...."
                required=""
                style={{ display: "none;",zIndex:'777' }}
              />
              <button className="search-button">
                <i className="fa fa-search" aria-hidden="true"></i>
              </button>
            </form>
          </li> */}
        </ul>
        {/* <ul>
            <li>
             
              <span> For You </span>
            </li>
            <li>
              <a href="/">TS</a>
            </li>
            <li>
              <a href="/">AP</a>
            </li>
            <li>
              <a href="/">India</a>
            </li>
            <li>
              <a href="/">World</a>
            </li>
            <li>
              <Link to="/Politics"> Politics </Link>
              <ul className="ne-dropdown-menu">
                {newsData &&
                  newsData[0]?.str_sub_categories_names?.map((record) => {
                    return (
                      <>
                        <li key={record}>
                          <Link to={`/${record}`}>{record}</Link>
                        </li>
                      </>
                    );
                  })}
              </ul>
            </li>
            <li>
              <Link to="">Business</Link>
              <ul className="ne-dropdown-menu">
                {newsbData &&
                  newsbData[0]?.str_sub_categories_names?.map((record) => {
                    return (
                      <>
                        <li key={record}>
                          <Link to={`/${record}`}>{record}</Link>
                        </li>
                      </>
                    );
                  })}
              </ul>
            </li>
            <li>
              <Link to=""> Education </Link>
              <ul className="ne-dropdown-menu">
                {newseData &&
                  newseData[0]?.str_sub_categories_names?.map((record) => {
                    return (
                      <>
                        <li key={record}>
                          <Link to={`/${record}`}>{record}</Link>
                        </li>
                      </>
                    );
                  })}
              </ul>
            </li>
            <li>
              <a href="/">Opinion</a>
            </li>
            <li>
              <a href="/">Life & Style</a>
            </li>
            <li>
              <a href="">
                <i className="fa fa-th" aria-hidden="true"></i>
              </a>
            </li>
            <li>
              <form id="top-search-form" className="header-search-dark">
                <input
                  type="text"
                  className="search-input"
                  placeholder="Search...."
                  required=""
                  style={{ display: "none;" }}
                />
                <button className="search-button">
                  <i className="fa fa-search" aria-hidden="true"></i>
                </button>
              </form>
            </li>
          </ul> */}
      </nav>
    </>
  );
}

export default Navbar
